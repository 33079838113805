@charset "utf-8";

@import "common/variables";
@import "common/mixins";
@import "common/extends";


main div.with_esthetic_list,
main div.with_present_list {
  .content {
    padding-top: $subheader-height;
    min-height: 100vh;
    ul.content_list{
      padding: 26px 12px;
      list-style: none;
      li{
        border: 1px solid #E5E5E5;
        border-radius: 7px;
        margin-bottom: 26px;
        img{
          display: block;
          width: 100%;
          border-radius: 7px 7px 0 0;
        }
        p{
          margin: 13px;
          &.pr{
            font-weight: 500;
          }
        }
        a{
          margin: 26px auto;
        }
        .present_description{
          padding: 13px;
          display: flex;
          align-items: center;
          p{
            margin: 0 13px 0 0;
            width: 50%;
          }
          a{
            width: 50%;
            margin: 0 auto 0;
          }
        }
      }
    }
  }
}
