body.partner_order_report {
  -webkit-print-color-adjust: exact; }
  @media print {
    body.partner_order_report .print-btn, body.partner_order_report .sidebar {
      display: none !important; } }
  body.partner_order_report h1 {
    font-size: 19px; }
  body.partner_order_report .content {
    position: relative; }
  body.partner_order_report .print-btn {
    position: absolute;
    top: 16px;
    right: 16px; }
  body.partner_order_report .search-box {
    display: inline-block;
    margin-top: 16px;
    padding: 8px;
    border: 1px solid #e5e5e5;
    border-radius: 4px; }
    body.partner_order_report .search-box .btn {
      white-space: nowrap; }
  body.partner_order_report .order-dt-wrapper {
    display: flex;
    align-items: center; }
    body.partner_order_report .order-dt-wrapper * {
      margin: 0 !important; }
      body.partner_order_report .order-dt-wrapper * + * {
        margin-left: 8px !important; }
    body.partner_order_report .order-dt-wrapper .btn {
      padding: 10px 16px; }
  body.partner_order_report .report-area {
    margin-top: 16px; }
  body.partner_order_report .report-table {
    font-size: 11px; }
    body.partner_order_report .report-table th, body.partner_order_report .report-table td {
      padding: 8px 2px !important; }
    body.partner_order_report .report-table .col-shop {
      min-width: 120px; }
    body.partner_order_report .report-table .col-name {
      min-width: 96px;
      max-width: 192px; }
    body.partner_order_report .report-table .col-date {
      width: 96px;
      white-space: nowrap; }
    body.partner_order_report .report-table .col-item {
      width: 168px; }
    body.partner_order_report .report-table .col-count {
      width: 48px; }
    body.partner_order_report .report-table .col-price {
      width: 120px; }
