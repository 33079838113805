@charset "utf-8";

// --------------------
// 変数のスタイル定義
//
// ※ 変数以外のスタイルは書かないこと！
// --------------------

// Colors
$primary-color: #ff6c52;          // メインカラー（ピンクオレンジ）
$sub-color: #ff9e97;              // サブカラー（薄いピンクオレンジ）
$accent-color: #002556;           // アクセントカラー（青）
$emphasis-color: #e62520;         // 強調色（赤）
$background-color: #f7f7f7;       // 背景色（薄いグレー）
$sub-background-color: #f7e4e3;   // サブ背景色（薄いピンク）
$common-text-color: #4c4c4c;      // テキスト色（黒）
$border-color: #e5e5e5;           // ボーダー色
$title-background-color: #fff;    // タイトルの色（白）
$disabled-color: #ccc;            // 無効化の色（グレー）
$color-gray: #999;                // グレー色
$color-transparent-white: rgba(255,255,255,0.75); // 透過した白
$color-error: #e56473 !default;   // エラー表示の色

// Font Size
$font-xl: 19px;
$font-ll: 17px;
$font-l: 15px;
$font-m: 13px;
$font-s: 11px;

// Height & Width
$header-height: 50px;
$subheader-height: 50px;
$area-max-width: 760px;
$form-max-width: 480px;

// Common Space
$spacer: 16px;
$global-space: 8px;

// --------------------
// Z-index master
// --------------------
$zindex-dropdown:       1000 !default;
$zindex-sticky:         1020 !default;
$zindex-fixed:          1030 !default;
$zindex-modal-backdrop: 1040 !default;
$zindex-modal:          1050 !default;
$zindex-popover:        1060 !default;
$zindex-tooltip:        1070 !default;
