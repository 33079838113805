@charset "utf-8";

@import "common/variables";
@import "common/mixins";
@import "common/extends";

.manage_invitation__finish {

  main {
    padding-top: $subheader-height;

    .invitation_finish{
      padding:1px 0 30px;
    }

    img.create_finish {
      display: block;
      margin: 30px auto;
      width: 120px;
      animation : create_finish_animation 1s linear 1 normal;
    }
    @-webkit-keyframes create_finish_animation{
      0%   {-webkit-transform: translateY(20px);   opacity:0}
      100% {-webkit-transform: translateY(0);   opacity:1}
    }
    p.attention{
      margin-top: 5px;
      text-align: center;
    }
    a{
      font-size: 14px;
    }

    a.to_manage{
      text-align: center;
      margin: 30px auto;
      background: none;
      color: #002556;
      font-size: 16px;
      font-weight: bold;
      i{
        margin-left: 10px;
      }
    }
  }
}