@charset "utf-8";

@import "common/variables";
@import "common/mixins";
@import "common/extends";

.account__except {
  main{
    padding-top: $subheader_height;
  }
  .manage_header a.view_invitation {
    display:none;
  }
  .btn.inactive {
    background: #ccc;
    color: #fff !important;
    * {
      color: #fff !important;
    }
    pointer-events: none;
  }
}

.account_expired {
  #container{
    padding-top: $subheader_height;
  }
}

.account__mail, .account__password {
  main {
    padding-top: $subheader-height;

    form {
      margin-top: 0.5em;
    }
  }
}
