body.partner_information .main_content {
  background: #F5F5F5; }
  body.partner_information .main_content .date {
    font-size: 12px;
    color: #A8A8A8; }
  body.partner_information .main_content .information_list {
    padding: 60px;
    max-width: 840px;
    margin: 50px auto; }
    body.partner_information .main_content .information_list h2 {
      font-size: 20px;
      text-align: center;
      margin-bottom: 50px; }
    body.partner_information .main_content .information_list .info {
      border-bottom: #C9C9C9 solid 1px;
      padding: 10px;
      transition: all 0.3s;
      position: relative; }
      body.partner_information .main_content .information_list .info:hover {
        background: #fff; }
      body.partner_information .main_content .information_list .info:before {
        content: '';
        width: 10px;
        height: 10px;
        border: 0px;
        border-top: solid 2px #B1B1B1;
        border-right: solid 2px #B1B1B1;
        -ms-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        position: absolute;
        top: 50%;
        right: 10px;
        margin-top: -4px; }
      body.partner_information .main_content .information_list .info h3 {
        font-weight: bold;
        font-size: 15px; }
  body.partner_information .main_content .information_detail {
    background: #fff;
    padding: 60px;
    max-width: 840px;
    margin: 50px auto; }
    body.partner_information .main_content .information_detail h1 {
      font-size: 18px;
      padding: 0 0 10px;
      border-bottom: #C9C9C9 solid 1px;
      margin: 10px 0; }
    body.partner_information .main_content .information_detail .information_content {
      margin: 20px 0; }
    body.partner_information .main_content .information_detail .back_list_wrap {
      margin: 50px auto 20px;
      text-align: center; }
    body.partner_information .main_content .information_detail a.back_list {
      text-align: center;
      position: relative; }
      body.partner_information .main_content .information_detail a.back_list:before {
        content: '';
        width: 8px;
        height: 8px;
        border: 0px;
        border-top: solid 2px #B1B1B1;
        border-right: solid 2px #B1B1B1;
        -ms-transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
        transform: rotate(-135deg);
        position: absolute;
        top: 50%;
        left: -20px;
        margin-top: -4px; }
