@charset "utf-8";

@import "common/variables";
@import "common/mixins";
@import "common/extends";


.user {
  #content{
    padding-top: 0;
  }
  #container{
    width: 100%;
    max-width: none;
  }
  .manage_header {
    display: none;
  }
  .btn{
    width: 100%;
  }

  #manage_container.is_partner_app {
    .manage_button {
      a {
        background: linear-gradient(90deg, #B4D2C9, #82B2B1);
        width: 100%;
      }
    }
  }
  #manage_container {
    padding: 0 0;
    background-image: url("/images/login_background_pc.jpg");
    background-position: center center;
    background-size: cover;
    box-shadow: none;
    max-width: none;
    @media (max-width: 680px) {
      background-image: url("/images/login_background_sp.jpg");
    }
    #login_content_wrapper{
      min-height: 100vh;
      padding: 16px 1em;
      width: 100%;
      z-index: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      #login_content {
        margin: 0 auto;
        padding: 72px 0;
        max-width: 540px;
        border-radius: 20px;
        background:rgba(255, 255, 255, 0.6);
        backdrop-filter: blur(10px);
      }
    }
  }
  .manage_footer {
    display: none;
  }

  h1.logo {
    text-align: center;

    img {
      height: auto;
      width: 120px;
    }
    img.partner_logo{
      width: 160px;
    }
  }
  h2.account_title {
    color: #333;
    border: none !important;
    font-size: $font-l !important;
    height: auto !important;
    line-height: 200% !important;
    margin: 32px 0 0;
  }
  #login_form,#create_form{
    input{
      font-weight: normal;
      color: #333;
      font-size: $font-m !important;
      margin-top: 16px;
      background: transparent;
      max-width: 480px;
      border: 1px solid #ccc;
      &::placeholder {
        color: #ccc;
      }
      &:focus {
        outline: 0;
      }
      &:-webkit-autofill{
        -webkit-box-shadow:0 0 0 1000px white inset;
        background-color: white;
      }
    }
  }
  .manage_button {
    padding: 0 0 1em;
    a {
      margin-top: 15px;
      border-radius: 5px;
      font-weight: bold;
      background: #FF7568;
    }
  }
  .line_login {
    margin: 26px 0;
    .bubble{
      padding: 5px;
      max-width: 120px;
      margin: 0 auto;
      border-radius: 20px;
      background: #FFED4D;
      position: relative;
      font-size: 13px;
      font-weight: 700;
      &::after{
        content: '';
        position: absolute;
        width: 12px;
        height: 12px;
        left: 0;
        right: 0;
        margin: 0 auto;
        bottom: -6px;
        background: #FFED4D;
        transform: rotate(135deg);
      }
    }
    a {
      margin-top: 0px;
      border-radius: 5px;
      font-weight: bold;
      background: #06C755;
      p {
        margin-right: 50px;
      }
    }
    img {
      height: 50px;
      float: left;
      margin-left: 10px;
    }
  }
  .errors{
    background: transparent;
    border: none;
    margin: 0 auto;
    .error_list{
      padding: 0;
    }
  }
  .message {
    background:rgba(255, 255, 255, 0.7);
    margin: 1em auto;
    padding: 1em;
    border: 1px solid #e56473;
    border-radius: 6px;
    color: #e56473;
    a {
      color: #e56473;
      font-weight: bold;
      text-decoration: underline;
    }
  }
  .other_page {
    a {
      display: inline-block;
      padding: 0.5em 0 1.5em;
      text-align: center;
      color: #333;
      text-decoration: underline;
    }
  }

  // ログイン画面
  .manage_user_wrapper {
    border-radius: 5px;
    margin: 0 auto;
    padding: 0 2em;
    text-align: center;
  }
  .manage_user_footer {
    margin: 0 auto;
    padding: 0 2em;
    text-align: center;
  }

  // アカウント作成画面
  div.signup_alert {
    font-size: $font-s;
    margin: 0 0 2em;
    text-align: center;
    color: #333;
    max-width: 330px;

    a {
      color: #333;
      background: transparent;
      text-decoration: underline;
    }
  }

  // ログインはこちら
  .login_link,.signup_link {
    font-size: 12px;
    color: #FF6C52;
    border-radius: 50px;
    background: transparent !important;
    text-decoration: none;
    border: 1px solid #FF6C52;
    display: block;
    width: 100%;
    max-width: 480px;
    margin: 2em auto 0;
    padding: 1em 0;
  }

  // アカウント作成正常完了後のメッセージ
  .signup_message {
    padding: 3em 0;
    text-align: center;
  }
  
  .login_footer{
    width: 80%;
    border-top: 1px solid #333333;
    margin: 10px auto 0;
    text-align: center;
    padding: 20px 0 30px;
    max-width: 680px;
    ul{
      list-style: none;

      @media (min-width: 680px) {
        display: flex;
        justify-content: space-evenly;
      }
      li{
        padding: 2px 0;
        a{
          color: #333;
          display: block;
          padding: 8px 10px;
        }
      }
    }
    .copyright{
      font-size: 11px;
      margin-top: 20px;
      color: #333;
    }
  }
}