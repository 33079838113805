@keyframes slidein {
  0% {
    left: -20%; }
  100% {
    left: 0; } }

@keyframes slideout {
  0% {
    left: 0; }
  100% {
    left: -20%; } }

body.partner .sidebar {
  width: 20%;
  min-width: 250px;
  max-width: 280px;
  background: linear-gradient(#75AAAA, #CCCEBE);
  padding-bottom: 80px;
  Z-index: 2;
  top: 0;
  left: 0;
  animation: slidein .3s linear; }
  body.partner .sidebar i {
    font-size: 42px;
    color: #fff;
    padding: 20px;
    cursor: pointer; }
  body.partner .sidebar .sidebar_container {
    display: flex;
    flex-direction: column;
    padding: 20px 20px 0; }
    body.partner .sidebar .sidebar_container .sidebar_item {
      list-style: none; }
      body.partner .sidebar .sidebar_container .sidebar_item a {
        transition: all 0.3s;
        color: #fff;
        display: flex;
        align-items: center;
        height: 40px;
        padding: 0 1em;
        font-size: 13px; }
        body.partner .sidebar .sidebar_container .sidebar_item a:hover {
          background-color: rgba(0, 0, 0, 0.2); }
        body.partner .sidebar .sidebar_container .sidebar_item a span {
          color: #fff;
          background: #0F3986;
          border-radius: 3px;
          padding: 0 6px;
          font-size: 10px;
          margin-left: 4px; }

body.partner .top-page {
  position: fixed; }

body.partner .is-close {
  animation: slideout .5s linear; }
