body.partner .content_header {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 40px; }
  body.partner .content_header .header_right_content {
    position: absolute;
    top: 0;
    right: 0;
    height: 40px;
    line-height: 40px; }
  body.partner .content_header img.header_logo {
    height: auto;
    width: 135px; }
