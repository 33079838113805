@charset "utf-8";

// --------------------
// アプリケーション全体で使うスタイル定義
//
// ※ 共通して使えるもののみ指定すること
// --------------------
@import "common/variables";
@import "common/mixins";
@import "common/extends";

// --------------------
// Reset
// 初期設定値
// --------------------
// sanitize打消し
:root {
  background-color: transparent;
}
// google fonts読み込み
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500&display=swap');
html, body {
  background-color: $background-color;
  color: $common-text-color;
  font-family: 'Noto Sans JP','ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, 'ＭＳ Ｐゴシック', sans-serif;
  font-size: $font-m;
  height: 100%;
  line-height: 150%;
}

a {
  color: $common-text-color;
  transition: all 0.2s linear 0s;
  text-decoration: none;
}

.disabled {
  color:$disabled-color !important;
  cursor: not-allowed !important;
  * {
    color:$disabled-color !important;
  }
}
.btn.disabled {
  background: #ccc;
  color: #fff !important;
  * {
    color: #fff !important;
  }
}

img {
  height: auto;
  max-width: 100%;
}

// --------------------
// Form共通
// --------------------
/* placeholder */
::-webkit-input-placeholder{
  color: #ccc;
}
::-moz-placeholder{
  color: #ccc;
}
:-moz-placeholder{
  color: #ccc;
}
:-ms-input-placeholder{
  color: #ccc;
}

input[type=text],
input[type=password],
input[type=number],
input[type=tel],
select{
  background-color: #fff;
  border-bottom: 1px solid $border-color;
  margin: 1px auto;
  padding: 0.5em;
  max-width: $form-max-width;
  width: 100%;
}
input[type=date]::-webkit-date-and-time-value,
input[type=time]::-webkit-date-and-time-value{
  text-align: left;
}
input[type=date]{
  background-color: #fff;
  border: 1px solid $border-color;
  border-radius: 7px;
  margin: 1px auto;
  padding: 0.5em;
  max-width: 160px;
  width: 100%;
  text-align: left;
  min-height: 36px;
  appearance: none;
  -webkit-appearance: none;
}
input[type=time]{
  background-color: #fff;
  border: 1px solid $border-color;
  border-radius: 7px;
  margin: 1px auto;
  padding: 0.5em;
  max-width: 100px;
  width: 100%;
  text-align: left;
  min-height: 36px;
  appearance: none;
  -webkit-appearance: none;
}
input[type=time]{
  position: relative;
}
input[type=time]::-webkit-calendar-picker-indicator {
  position: absolute;
  opacity: 0;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  padding: 0;
  background: transparent;
  color: transparent;
  cursor: pointer;
  height: 100%;
  width: 100%;
}

input[type=text]:disabled,
input[type=number]:disabled,
input[type=tel]:disabled,
textarea:disabled
 {
  background-color: #ccc;
}

input[type=text]:focus,
input[type=password]:focus,
input[type=date]:focus,
input[type=time]:focus,
input[type=number]:focus,
input[type=tel]:focus,
select:focus,
textarea:focus{
  outline: 1px solid $primary-color;
}

input[type=radio] {
  display: none;
  -webkit-border-radius: 10px;
  margin-right: 2px;
  vertical-align: middle;
  & + :before {
    font-family: "Ionicons";
    margin-right: 0.2em;
    content: "\f3a6";
    font-size: 1.5em;
    vertical-align: middle;
  }
  &:checked + :before {
    content: "\f3a7";
  }
}

input:checked {
  background-color: #000000;
}

/* switch checkbox */
.switch {
  position: relative;
  display: inline-block;
  width: 120px;
  height: 28px;

  input {display:none;}

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    border-radius: 20px;
    color: white;
    .text:before {
      position: absolute;
      line-height: 28px;
      height: 28px;
      left: 34px;
      content: "OFF";
    }
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    border-radius: 50%;
  }

  input:checked + .slider {
    background-color: #2196F3;
    .text:before {
      content: "ON";
    }
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(92px);
    -ms-transform: translateX(92px);
    transform: translateX(92px);
  }
}

textarea {
  border: 1px solid $border-color;
  border-radius: 7px;
  margin: 1px 0;
  max-width: $form-max-width;
  padding: 1em;
  width: 100%;
}

span.select {
  line-height: 150%;
  background-color: #fff;
  border-radius: 7px;
  display: inline-block;
  position: relative;
}

span.select:before {
  content: "";
  position: absolute;
  top: 50%;
  right: 10px;
  display: block;
  width: 0;
  height: 0;
  margin: -2px 0 0 0;
  border: 5px solid transparent;
  border-top: 7px solid #999;
}

span.select select {
  cursor: pointer;
  position: relative;
  -webkit-appearance: none;
  appearance: none;
  display: block;
  padding: 0.5em 2em 0.5em 1em;
  color: $common-text-color;
  background: transparent;
}

span.select select:disabled {
  background-color: #ccc;
  cursor: default;
}

::-ms-expand {
  display: none;
}

input, textarea {
  &::-moz-selection {
    color: #333;
  }
  &::selection {
    color: #333;
  }
}

select:focus {
  outline: solid 3px #E6BCA2;
}

// ボタンのスタイル
.btn {
  @extend .btn_base;
  display: block;
  height: 50px;
  line-height: 50px;
  max-width: 480px;
  margin: 0 auto;
  width: 90%;

  &.btn_2line {
    line-height: 25px;
  }

  .icon-btn_premium{
    position: static !important;
    font-size: 20px;
  }
}

.btn_lr {
  @extend .btn_base;
  display: block;
  float: left;
  height: 50px;
  line-height: 50px;
  margin: 0 0.5%;
  width: 49%;
}

.btn_back {
  background-color: #B5B5B5;
}

.btn_skip {
  display: inline-block;
  padding-top: 2em;
  padding-bottom: 1em;
  text-decoration: underline;
}
// .btnと重複する箇所もあるが、今後はできるだけこの.buttonのスタイルを使用してスタイルを移行していくこと。
// .btnのクラスを使わなくなったら.btnクラスは削除すること。
.button {
  display: inline-block;
  height: 50px;
  font-weight: 400;
  color: $common-text-color;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0 0.75rem;
  font-size: $font-l;
  line-height: 50px;
  border-radius: 7px;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:hover {
    text-decoration: none;
  }
  &:focus, &.focus {
    outline: 0;
  }
  &.disabled, &:disabled {
    opacity: 0.65;
  }
}
a.button.disabled, fieldset:disabled a.button {
  pointer-events: none;
}

.btn-round {
  border-radius: 100rem;
}

.btn-primary {
  color: #fff;
  background-color: $primary-color;
  border-color: $primary-color;
}
.btn-secondary {
  color: $common-text-color;
  background-color: #fff;
  border-color: $common-text-color;
}
.btn-s {
  height: 40px;
  line-height: 40px;
}
.btn-container--v {
  display: flex;
  flex-direction: column;
  align-items: center;

  // 連続する.btnにmargin付与
  .button + .button {
    margin-top: 12px;
  }
}

// 左右Paddingありのエリア
.area {
  padding: 0 1em;
}

// テキストの寄せ
.text_center {
  text-align: center;
}

.text_right {
  text-align: right;
}

// margin
.mb-0 {
  margin-bottom: 0 !important;
}
.mt-2 {
  margin-top: 8px !important;
}
.mt-3 {
  margin-top: 16px;
}
.mt-4 {
  margin-top: 24px !important;
}
.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

// フォントサイズ
.font_s {
  font-size: $font-s;
}

// clearfix
.clear {
  clear: both;
}

// エラー表記
.errors {
  margin: 1em auto;
  padding: 1em;
  border: 1px solid #e56473;
  border-radius: 6px;
  color: #e56473;

  .error_list, .validation_error_list {
    padding-left: 1em;
    list-style: none;
  }
}
.alert-wrapper {
  margin-bottom: 1em;
}
.sum + .alert {
  padding: 1em 1em 1em 1em !important;
}
.alert {
  padding: 0.5em;
  background: #fff;
  border: 2px solid $color-error;
  border-radius: 0.25em;
  color: $color-error;

  ul {
    margin-left: 1.5em;
  }
  
  ul + h4 {
    margin-top: 1rem;
  }

  .calendar {
    margin-top: 1.1em;
  }
}

// プレミアムプランにアップグレード
.lead_premium {
  background-color: #fee;
  border: solid 1px #fcc;
  font-size: 11px;
  margin: 1em 0;
  padding: 1em;
}

.lead_premium a {
  color: #f00;
  text-decoration: underline;
}

// 管理画面トップのたすき
.tasuki {
  display: block;
  position: absolute;
  padding: 3px 0 6px;
  top: 0;
  width: 100%;
  text-align: center;
  font-size: 10px;
  line-height: 1;
  color: #fff;
  background-image: url("/images/speech_balloon.svg");
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-position: center center;
}

// ローディングスピナーのスタイル
.spinner {
  margin: 100px auto 0;
  width: 70px;
  text-align: center;

  > div {
    width: 18px;
    height: 18px;
    background-color: #ff6c52;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0)
  }
  40% {
    -webkit-transform: scale(1.0)
  }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}

// プログレスバーのスタイル
.progress_bar_wrapper {
  $progress-bar-height: 30px;
  height: $progress-bar-height;
  margin: 0 16px;
  border-radius: $progress-bar-height / 2;
  background: #f0f0f0;

  .progress_bar {
    line-height: $progress-bar-height;
    border-radius: $progress-bar-height / 2;
    background: $sub-color;
    color: #fff;
    transition: width 0.2s;
  }
}

// --------------------
// Override
// サードパーティライブラリの上書きスタイル
// --------------------
// モーダルウィンドウ
.remodal {
  box-shadow: 0 0 20px #000;
  border-radius: 7px;
  padding: 2em;
}
.remodal-overlay {
  z-index: $zindex-modal-backdrop;
}
.remodal-wrapper {
  z-index: $zindex-modal;
}
html.remodal-is-locked {
  overflow:visible !important;
  body {
    overflow-y: hidden !important;
  }
  .remodal-wrapper.remodal-is-opening {
    overflow-y: hidden !important; // remodalオープン中にスクロールバーが一瞬表示されるバグの改修
  }
}

// For Samrtphone
@media screen and (max-width: 700px) {
  // blockUI
  .blockMsg {
    width: 96% !important;
    left: 2% !important;
  }
  // vex
  .vex-content {
    width: 90% !important;
    font-size: 1em !important;
  }
}

// blockUI
.block_msg {
  color: $primary-color;
}
// vex
.vex {
  z-index: $zindex-popover;
}
.vex-theme-os {
  .vex-dialog-message {
    span.error_msg {
      color: #e56473;
    }
    a {
      text-decoration: underline;
      color: #e56473;
    }
  }
}
body.vex-open {
  overflow: visible !important;
}

// Vue
[v-cloak] {
  display: none;
}

// --------------------
// State
// 状態スタイル
// --------------------
.hidden {
  display: none !important;
}
.sp {
  display: none !important;
  @media screen and (max-width: 800px) {
    display: block !important;
  }
}

// -----
// Sweet Alert
// スタイルを独自に大きく変更
// SweetAlertのトースト
.swal-toast-container {
  width: 760px !important;
  padding: 0 !important;
  font-family: 'Noto Sans JP', sans-serif;

  &.swal2-backdrop-show {
    background: none !important;
  }
}
.swal2-html-container {
  display: inline-block !important;
  margin: 0.5em auto !important;
  padding-left: 28px !important;
  position: relative;
  min-height: 24px;
  overflow: hidden;
  &:before {
    font-family: 'Font Awesome 6 Pro'; // FontAwesomeをbeforeで使う場合に必要
    margin-right: 8px;
    font-size: 20px;
    position: absolute;
    left: 0;
    line-height: 24px;
  }
}
.swal2-toast {
  width: 760px !important;
  min-height: 60px;
  margin: 0 !important;
  border-radius: 0 !important;

  * {
    color: #fff;
  }

  .swal2-icon {
    display: none !important;
  }

  &.swal2-icon-success {
    background: rgba(#4c5870, 0.8) !important;

    .swal2-html-container:before {
      content: "\f058"; // FontAwesomeのチェックマーク
      font-weight: 300; // FontAwesomeをbeforeで使う場合に必要（アイコンによってweightが違うので注意）
    }
  }
  &.swal2-icon-warning {
    background: rgba(#fbc02d, 0.8) !important;

    .swal2-html-container:before {
      content: "\f071"; // FontAwesomeの!マーク
      font-weight: 300; // FontAwesomeをbeforeで使う場合に必要（アイコンによってweightが違うので注意）
    }
  }
  &.swal2-icon-error {
    background: rgba(#c74121, 0.8) !important;

    .swal2-html-container:before {
      content: "\f06a"; // FontAwesomeの!マーク
      font-weight: 300; // FontAwesomeをbeforeで使う場合に必要（アイコンによってweightが違うので注意）
    }
  }
}

// フェードイン
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadein {
  animation: fadein 0.5s ease forwards;
}

// フェードアウト
@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.fadeout {
  animation: fadeout 0.5s ease forwards;
}
