@charset "utf-8";

@import "common/variables";
@import "common/mixins";
@import "common/extends";

@font-face {
  font-family: 'Jomolhari';
  src: url('/fonts/Jomolhari-alpha3c-0605331.ttf') format('truetype');
}

@font-face {
  font-family: 'SreeKrushnadevaraya';
  src: url('/fonts/SreeKrushnadevaraya-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'JosefinSans';
  src: url('/fonts/JosefinSans-SemiBold.ttf') format('truetype');
}

$form-input-check-radio-height: 32px !default;
$form-check-radio-input-size: 32px !default;
// チェックボックスを描画する際の調整値
$form-check-input-margin-y: 2px;
$form-check-input-margin-x: 6px;
$form-check-input-border-width: 2px;

// 結婚式まであと○○日の画面
.days_left {
  main {
    padding-top: 50px;
  }
  #content{
    padding-bottom: 100px;
  }
  .fix_banner{
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    position: fixed;
    padding-bottom: 15%;
    height: 0;
    overflow: hidden;
    iframe {
      position: absolute;
      margin: 0 auto;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }
  @media screen and (min-width: (500px)) {
    .fix_banner {
      padding-bottom: 75px;
    }
  }

  .description {
    max-width: 480px;
    margin: 0 auto;
    padding: 1em 0 0.5em 0;
  }

  // 結婚式まであと○○日画像のラッパー
  .image-wrapper {
    position: relative;
    max-width: 480px;
    margin: 0 auto;

    .image {
      width: 100%;
      aspect-ratio: 1/1;
      object-fit: cover;
    }

    // 画像マスキング
    .image__overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(0,0,0,0.25);
    }

    .dl-image {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    // 枠線
    .image__frame {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
    .text-wrapper-v1{
      // 画像のテキスト共通
      .image__text {
        position: absolute;
        width: 100%;
        line-height: 100%;
        text-align: center;
        color: #fff;
        font-family: 'Muli', sans-serif; // Webフォント
        letter-spacing: 1.25px;
        white-space: nowrap;
      }

      // 日本語が含まれる場合はフォントを変更
      .image__text--jp {
        font-family: 'Noto Sans JP', sans-serif; // Webフォント
        font-weight: 100;
      }

      // Today isのテキスト
      .image__text--today-label {
        top: 26.5%;
        font-size: 34px;
        letter-spacing: 3px;
      }

      // WEDDING DAYのテキスト
      .image__text--weddingday {
        top: 35.75%;
        font-size: 51px;
        line-height: 115%;
        letter-spacing: 4.75px;
      }

      // 結婚式まであとのテキスト
      .image__text--label {
        top: 25%;
        font-size: 20px;
      }

      // 365等のテキスト
      .image__text--days {
        top: 30.5%;
        font-size: 97px;
      }

      // DAYSのテキスト
      .image__text--days-text {
        top: 50%;
        font-size: 52px;
      }

      // 日付のテキスト
      .image__text--date {
        top: 65.25%;
        font-size: 14px;
        letter-spacing: 2.25px;
      }

      // 名前部分のテキスト
      .image__text--name {
        top: 71.25%;
        font-size: 23px;
        letter-spacing: 3.5px;
      }

      // weddingdayロゴ
      .logo {
        position: absolute;
        right: 3%;
        bottom: 3%;
        width: 25%;
      }

      // Pixelサイズ
      @media screen and (max-width: 420px) {
        .image__text--today-label {
          font-size: 27px;
          letter-spacing: 2.5px;
        }
        .image__text--weddingday {
          font-size: 41px;
          letter-spacing: 3.75px;
        }
        .image__text--label {
          font-size: 16px;
        }
        .image__text--days {
          font-size: 78px;
        }
        .image__text--days-text {
          font-size: 41.5px;
          letter-spacing: 5.5px;
        }
        .image__text--date {
          font-size: 11px;
          letter-spacing: 1.75px
        }
        .image__text--name {
          font-size: 18.5px;
          letter-spacing: 3.25px;
        }
      }

      // iPhoneサイズ
      @media screen and (max-width: 380px) {
        .image__text--today-label {
          font-size: 25px;
          letter-spacing: 2.25px;
        }
        .image__text--weddingday {
          font-size: 37px;
          letter-spacing: 3.5px;
        }
        .image__text--label {
          font-size: 15px;
        }
        .image__text--days {
          font-size: 71px;
        }
        .image__text--days-text {
          font-size: 38px;
        }
        .image__text--date {
          font-size: 11px;
          letter-spacing: 1.5px;
        }
        .image__text--name {
          font-size: 16.75px;
          letter-spacing: 3px;
        }
      }
    }

    .text-wrapper-v2{
      // 画像のテキスト共通
      .image__text {
        position: absolute;
        width: 100%;
        line-height: 100%;
        text-align: center;
        color: #fff;
        font-family: 'Jomolhari', serif;
        white-space: nowrap;
      }

      // Today isのテキスト
      .image__text--today-label {
        top: 22.2%;
        font-size: 23px;
        letter-spacing: 2.5px;
      }

      // WEDDING DAYのテキスト
      .image__text--weddingday {
        top: 31.5%;
        font-size: 54px;
        line-height: 120%;
        letter-spacing: 3.75px;
      }

      // Lastのテキスト
      .image__text--label {
        top: 21.5%;
        font-size: 26px;
        letter-spacing: 2px;
      }

      // 365等のテキスト
      .image__text--days {
        top: 30.5%;
        font-size: 78px;
        letter-spacing: 2px;
      }

      // DAYSのテキスト
      .image__text--days-text {
        top: 49%;
        font-size: 48px;
      }

      // 日付のテキスト
      .image__text--date {
        top: 66.5%;
        font-size: 18px;
        letter-spacing: 1.75px;
      }

      // 名前部分のテキスト
      .image__text--name {
        top: 74.5%;
        font-size: 19px;
        letter-spacing: 2.15px;
      }

      // 日本語が含まれる場合はフォントを変更
      .image__text--jp {
        font-family: 'Noto Sans JP', sans-serif; // Webフォント
        font-weight: 100;
      }

      // weddingdayロゴ
      .logo {
        position: absolute;
        bottom: 1%;
        width: 25%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      // Pixelサイズ
      @media screen and (max-width: 420px) {
        .image__text {
          letter-spacing: 1px;
        }
        // Today is
        .image__text--today-label {
          font-size: 18px;
          letter-spacing: 2px;
        }
        // WEDDING DAY
        .image__text--weddingday {
          font-size: 41px;
          letter-spacing: 3px;
        }
        .image__text--label {
          font-size: 19px;
        }
        .image__text--days {
          font-size: 59px ;
        }
        .image__text--days-text {
          font-size: 37px;
        }
        .image__text--date {
          font-size: 13px;
          letter-spacing: 1.5px;
        }
        .image__text--name {
          font-size: 14px;
          letter-spacing: 2px;
        }
      }

      // iPhoneサイズ
      @media screen and (max-width: 380px) {
        .image__text {
          letter-spacing: 1px;
        }
        // Today is 
        .image__text--today-label {
          font-size: 16px;
          letter-spacing: 2px;
        }
        .image__text--weddingday {
          font-size: 38px;
          letter-spacing: 2px;
        }
        .image__text--label {
          font-size: 20px;
        }
        .image__text--days {
          font-size: 56px;
        }
        .image__text--days-text {
          font-size: 35px;
        }
        .image__text--date {
          font-size: 12.5px;
          letter-spacing: 1.5px;
        }
        .image__text--name {
          font-size: 13px;
          letter-spacing: 1.5px;
        }
      }
    }

    .text-wrapper-v3{
      // 画像のテキスト共通
      .image__text {
        position: absolute;
        width: 100%;
        line-height: 100%;
        text-align: center;
        color: #fff;
        font-family: 'SreeKrushnadevaraya', serif;
        white-space: nowrap;
      }

      // Today isのテキスト
      .image__text--today-label {
        top: 20.5%;
        font-size: 30.5px;
        letter-spacing: 2.75px;
      }

      // WEDDING DAYのテキスト
      .image__text--weddingday {
        top: 29.5%;
        font-size: 70px;
        letter-spacing: 3.75px;
      }

      // Lastのテキスト
      .image__text--label {
        top: 20%;
        font-size: 32px;
        letter-spacing: 2px;
      }

      // 365等のテキスト
      .image__text--days {
        top: 25%;
        font-size: 103px;
        letter-spacing: 5px;
      }

      // DAYSのテキスト
      .image__text--days-text {
        top: 47%;
        font-size: 59px;
        letter-spacing: 3px;
      }

      // 日付のテキスト
      .image__text--date {
        top: 66%;
        font-size: 20px;
        letter-spacing: 2px;
      }

      // 名前部分のテキスト
      .image__text--name {
        top: 74%;
        font-size: 21.5px;
        letter-spacing: 3px;
      }

      // 日本語が含まれる場合はフォントを変更
      .image__text--jp {
        font-family: 'Noto Sans JP', sans-serif; // Webフォント
        font-weight: 100;
      }

      // weddingdayロゴ
      .logo {
        position: absolute;
        right: 3%;
        bottom: 3%;
        width: 25%;
      }

      // Pixelサイズ
      @media screen and (max-width: 420px) {
        .image__text {
          letter-spacing: 1px;
        }
        // Today is
        .image__text--today-label {
          font-size: 18px;
          letter-spacing: 2px;
        }
        // WEDDING DAY
        .image__text--weddingday {
          font-size: 41px;
          letter-spacing: 3px;
        }
        .image__text--label {
          font-size: 25px;
        }
        .image__text--days {
          font-size: 79px ;
        }
        .image__text--days-text {
          font-size: 46px;
        }
        .image__text--date {
          font-size: 16.5px;
          letter-spacing: 1.5px;
        }
        .image__text--name {
          font-size: 16px;
          letter-spacing: 2.5px;
        }
      }

      // iPhoneサイズ
      @media screen and (max-width: 380px) {
        .image__text {
          letter-spacing: 1px;
        }
        // Today is 
        .image__text--today-label {
          font-size: 20.5px;
          letter-spacing: 2px;
        }
        .image__text--weddingday {
          font-size: 48px;
          letter-spacing: 2px;
        }
        .image__text--label {
          font-size: 24px;
        }
        .image__text--days {
          font-size: 76px;
        }
        .image__text--days-text {
          font-size: 44px;
        }
        .image__text--date {
          font-size: 13.5px;
          letter-spacing: 1.5px;
        }
        .image__text--name {
          font-size: 15px;
          letter-spacing: 2px;
        }
      }
    }

    .text-wrapper-v4{
      // 画像のテキスト共通
      .image__text {
        position: absolute;
        left: 3%;
        width: auto;
        line-height: 100%;
        color: #fff;
        font-family: 'JosefinSans';
        white-space: nowrap;
      }

      // Today isのテキスト
      .image__text--today-label {
        top: 40.5%;
        font-size: 24.5px;
        letter-spacing: 2.5px;
      }

      // WEDDING DAYのテキスト
      .image__text--weddingday {
        top: 49.5%;
        font-size: 49px;
        line-height: 120%;
        letter-spacing: 3.75px
      }

      // Lastのテキスト
      .image__text--label {
        top: 40.5%;
        font-size: 25px;
        letter-spacing: 2px;
      }

      // 365等のテキスト
      .image__text--days {
        top: 49%;
        font-size: 75px;
        letter-spacing: 5px;
      }

      // DAYSのテキスト
      .image__text--days-text {
        top: 64.8%;
        font-size: 48px;
        letter-spacing: 3px;
      }

      // 日付のテキスト
      .image__text--date {
        top: 77%;
        font-size: 21px;
        letter-spacing: 2.6px;
      }

      // 名前部分のテキスト
      .image__text--name {
        top: 82.5%;
        font-size: 26.5px;
        letter-spacing: 1px;
      }

      // 日本語が含まれる場合はフォントを変更
      .image__text--jp {
        font-family: 'Noto Sans JP', sans-serif; // Webフォント
        font-weight: 100;
      }

      // weddingdayロゴ
      .logo {
        position: absolute;
        left: 3%;
        bottom: 3%;
        width: 25%;
      }

      // Pixelサイズ
      @media screen and (max-width: 420px) {
        .image__text {
          letter-spacing: 1px;
        }
        // Today is
        .image__text--today-label {
          font-size: 18.5px;
          letter-spacing: 2px;
        }
        // WEDDING DAY
        .image__text--weddingday {
          font-size: 37px;
          letter-spacing: 3px;
        }
        .image__text--label {
          font-size: 19px;
        }
        .image__text--days {
          font-size: 59px ;
        }
        .image__text--days-text {
          font-size: 37px;
        }
        .image__text--date {
          font-size: 17px;
          letter-spacing: 1.5px;
        }
        .image__text--name {
          font-size: 20.5px;
          letter-spacing: 0.4px;
        }
      }

      // iPhoneサイズ
      @media screen and (max-width: 380px) {
        .image__text {
          letter-spacing: 1px;
        }
        // Today is 
        .image__text--today-label {
          font-size: 17px;
          letter-spacing: 1.8px;
        }
        .image__text--weddingday {
          font-size: 35px;
          letter-spacing: 2px;
        }
        .image__text--label {
          font-size: 19px;
        }
        .image__text--days {
          font-size: 58px;
        }
        .image__text--days-text {
          font-size: 36px;
        }
        .image__text--date {
          font-size: 13px;
          letter-spacing: 1px;
        }
        .image__text--name {
          font-size: 15px;
          letter-spacing: 1px;
        }
      }
    }

    // 写真アップロードリンクボタン
    .upload-btn {
      position: absolute;
      left: .5em;
      bottom: .5em;
      padding: .5em 1em;
      border: none;
      border-radius: 7px;
      color: #fff;
      background: rgba(51, 51, 51, 0.7);
    }
  }

  .input-photo {
    display: none;
  }

  .input-block {
    display: block;
    margin: 0 auto;
  }

  // 画像の編集モーダル
  .edit_image_modal {
    .remodal-body {
      padding: 1em;

      .remodal-body_inner {
        max-width: 480px;
        margin: 0 auto;
        text-align: left;

        label {
          display: block;
          margin-top: 1em;
        }

        .btn {
          display: inline-block;
          max-width: 150px;
          width: 100%;
          height: 50px;
          line-height: 50px;
          margi-left: 0.5em;
          margin: 0;
        }

        .sup {
          background-color: #f7f7f7;
          font-size: 11px;
          margin-top: 1em;
          margin-bottom: 2em;
          padding: 1em;
          a {
            text-decoration: underline;
            color: #f00;
            cursor: pointer;
          }
        }

        .image-container {
          display: flex;
          flex-wrap: wrap;
          margin-left: -0.25rem;
          margin-right: -0.25rem;

          .thumnail-image-wrapper {
            width: 50%;
            padding: 0.25rem;
            &.--top {
              width: 100%;
              max-width: 480px;
            }
            .image-surface {
              position: relative;
              background: #fff;
              &:after {
                content: "";
                display: block;
                padding-bottom: 100%;
              }
              &.add-area {
                border: 2px dashed #ff6c52;
              }
            }
            input[type=radio] {
              & + :before {
                display: none;
              }
              &:checked + :before {
                display: none;
              }
              display: none;
            }

            img {
              position: absolute;
              width: 100%;
              height: 100%;
              background-color: #000;
              background-repeat: no-repeat;
              background-position: center;
              background-size: cover;
            }
            &.--top img {
              background-size: contain;
            }
          }

          .form-input-radio {
            + span {
              display: block;
              height: $form-input-check-radio-height;
              line-height: $form-input-check-radio-height;

              &:before, &:after {
                content: '';
                position: absolute;
                top: ($form-input-check-radio-height - $form-check-radio-input-size) / 2;
                left: 0;
                display: block;
              }
            }
            // 文字ではなくエリア選択型のラジオボタン（枠に色がつく形式）
            + div {
              display: block;
              border-color: #fff;
            }

            &:checked {
              + div {
                border-color: #ff6c52;
              }
            }
          }
          .form-input-radio:checked {
            + span {
              &:before {
                border-color: #fff;
              }
              &:after {
                background-color: #ff6c52;
              }
            }
          }
          .form-input-checkbox:disabled, .form-input-radio:disabled {
            + span {
              opacity: 0.5;
            }
          }
          // ラジオボタン制御用
          .thumnail-image-wrapper .form-input-radio {
            &:checked {
              + div {
                border-width: 2px;
              }
            }
          }
        }
      }
    }
  }
}
