body.partner .messages {
  position: relative;
  padding: 8px;
  border: 1px solid transparent;
  border-radius: 4px; }
  body.partner .messages ul {
    margin-left: 16px; }

body.partner .error_messages {
  color: #850014;
  background-color: #ffebee;
  border-color: #ffd2d8; }
