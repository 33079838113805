@charset "utf-8";

@import "common/variables";
@import "common/mixins";
@import "common/extends";

.manage_draw {
  main {
    padding-top: $subheader-height;
    // 決済がない場合input入力不可
    .anchor_disabled {
      pointer-events: none;
      background-color: #ccc;
    }
    // 未申請リスト 振込申請履歴
    ul.history_container, ul.undone_container {
      margin: 1em auto;

      li {
        border-bottom: 1px solid #ccc;
        list-style: none;
        display: block;
        position: relative;
        font-size: $font-m;
        div {
          display: inline-block;
        }
      }
    }

    // 未申請リスト
    ul.undone_container {
      overflow: auto;
      height: 250px;
      border: solid 1px $border-color;
      padding: 1em;

      li {
        div {
          padding: 0.5em 0;
        }
        div.created_at {
          width: 32%;
        }
        div.entry_name {
          width: 36%;
        }
        div.entry_sum {
          width: 28%;
          text-align: right;
        }
      }
    }

    // 振込申請履歴
    ul.history_container {
      li {
        a {
          display: block;
          padding: 0.5em 0;

          div.created_at {
            width:30%;
          }
          div.sum {
            width:30%;
            text-align: right;
          }
          div.draw_status {
            width:15%;
            text-align: center;
            color: $accent-color;
          }
          div.show_draw_detail {
            width:20%;
            text-align: center;
          }
          @media screen and (max-width:340px) { 
          div.sum {
            width:25%;
          }
          div.draw_status {
            width:20%;
          }
          }
        }
        &.draw_done {
          background-color: $background-color;
          div.draw_status {
            color: #4F5B95;
          }
        }
        &.denied {
          div.draw_status {
            color: #e56473;
          }
        }

        // 詳細
        .detail {
          display: none;
          padding:0.5em 0;
          table {
            border-spacing: 1px;
            border-collapse: initial;
            width: 100%;
            td {
              width: 25%;
              background-color: $background-color;
              padding: 0.5em;
              font-size: $font-s;
              text-align: right;
            }
          }
        }
      }
    }

    // --------------------
    // 説明事項
    // --------------------
    .notice {
      background-color: $background-color;
      font-size: $font-s;
      margin: 1em 0;
      padding: 1em;

      ul {
        margin-left: 2em;
      }

      a {
        color: $primary-color;
        text-decoration: underline;
      }

      .detail {
        display: none;
      }
    }

    // --------------------
    // 注意事項
    // --------------------
    .alert {
      font-size: $font-s;
      padding: 1em 1em 1em 3em;
      background-color: $sub-background-color;

      .alert_title {
        font-weight: bold;
      }

      a {
        color: $accent-color;
        text-decoration: underline;
      }
    }
    .draw_alert{
      margin: 20px auto;
    }

    // 本人確認情報 & 振込先
    #personal,
    #bank_account
    {
      dl {
        dt {
          color: $accent-color;
          display: block;
          margin-top: 1em;
        }
        dd {
          display: block;

          .upload_personal {
            padding: 0.5em 0;
          }

          .notice ~ .upload_personal {
            display:none;
          }
        }
        div.bank_and_branch_name {
          padding: 5px 0;
          display: inline-block;
        }
      }
      .file_name {
        height: 35px;
        line-height: 35px;
        span.icon {
          color: #8d8d8d;
        }
      }
    }

    .last-draw-request {
      dt {
        margin: 1em 0 .5em;
      }
      dd {
        margin-left: 1em;
      }
    }

    // 振込内容
    .sum {
      margin: 1em auto;
      dl {
        @include clearfix;
        width: 100%;
        display: block;
        dt,dd {
          border-bottom:1px solid #999999;
          padding: 1em 0.5em;
        }
        dt {
          clear: both;
          display: block;
          float: left;
          width: 50%;
        }
        dd {
          display: block;
          float: right;
          text-align: right;
          width: 50%;
        }
      }
    }

    .manage_button {
      margin-right:auto;
      margin-left:auto;
      span.payment_btn_disable{
        background-color: #ccc;
      }
    }
  }
  a.yucho, a.toggle_link {
    color: $primary-color;
    text-decoration: underline;
    font-size: $font-s;
  }
  a.toggle_link {
    display: block;
    margin: 8px 0;
  }

  // 本人確認証選択ボタン 銀行検索リモーダル起動ボタン
  .btn_photo_change,
  .btn_bank_search {
    @extend .btn_base;
    font-size: $font-m;
    background-color: $primary-color;
    height: 30px;
    line-height: 32px;
    width: 100%;
    max-width: 150px;
    &.selected{
      background-color: #ff9e97;
    }
  }

  .remodal {
    max-width:500px;
  }

  // 銀行と支店検索リモーダル
  .search_bank_and_branch {
    position: relative;

    a.btn_close {
      font-size: $font-l;
      color: #8d8d8d;
      height: 40px;
      width: 40px;
      line-height: 40px;
      position: absolute;
      top: -33px;
      right: -35px;
      span.icon {
        font-size: $font-ll;
      }
    }

    div.description {
      @include clearfix;
      margin: -15px;
      margin-top: 0px;
      padding: 8px;
      background-color: #f5f5f5;
      border-radius: 7px;

      div.description_detail {
        padding: 5px;
        padding-top: 0px;
        text-align: left;
        font-size: $font-s;
        color: #a9a9a9;
        span.title {
          display: block;
          font-weight: bold;
          font-size: $font-l;
          color: #54AE44;

        }
        &:not(:first-of-type) span.title {
          margin-top: 8px;
        }
      }
      div.selected {
        padding: 5px;
        text-align: left;
        font-size: $font-m;
      }
      div.input {
        position: relative;
        input {
          background-color: white;
          max-width: 100%;
        }
        a.btn_search {
          @extend .btn_base;
          font-size: $font-m;
          background-color: #54AE44;
          height: 95%;
          width: 60px;
          border-top-left-radius: 0px;
          border-bottom-left-radius: 0px;
          position: absolute;
          top: 1px;
          right: 0px;
          line-height: 35px;
        }
      }
    }
    ul {
      margin: 1em 0;
      overflow: auto;
      li {
        text-align: left;
        border-bottom: 1px solid #ccc;
        list-style: none;
        a {
          display: block;
          padding: 0.8em 0;
          position: relative;
          font-size: $font-l;
          div.name {
            display:inline-block;
            width: 70%;
          }
          div.code {
            display:inline-block;
            float: right;
          }
        }
      }
    }

    .button_bottom {
      @include clearfix;
      margin-top: 2em;
      a.wide {
        width: 100%;
      }
      a[data-action=confirm] {
        background-color: #54AE44;
      }
    }

    .btn_manual_submit {
      float: right;
      width: 80px;
      height: 40px;
      line-height: 40px;
      margin-top: 8px;
      background-color: #54AE44;
    }

    // 支店検索リモーダル
    .search_branch,
    .search_confirm {
      display:none;
    }
  }

  [data-show-if-bank-edit] {
    display: none;
  }
}
.verification_modal{
  text-align: left;
  color: #333333;
  i{
    position: absolute;
    padding: 10px;
    right: 14px;
    top: 10px;
  }
  .modal_title{
    text-align: center;
    font-size: $font-l;
    font-weight: bold;
    margin-bottom: 20px;
  }
  .verification_title{
    margin:32px 0 8px ;
    font-size: $font-l;
    font-weight: bold;
  }
  p{
    font-size: $font-m;
    &.attention{
      font-size: $font-s;
    }
  }
  img{
    display: block;
    margin: 8px 0;
  }
  hr{
    margin-top: 20px;
    border-top: 1px solid #E5E5E5;
  }
  button{
    display: block;
    width: 80%;
    margin: 24px auto 0;
    max-width: 260px;
    border: 1px solid $primary-color;
    border-radius: 5px;
    padding: 14px;
    color: $primary-color;
  }
}