@charset "utf-8";

@import "common/variables";
@import "common/mixins";
@import "common/extends";

.manage_share, .thanks_letter_share {
  padding-top: 1px;
  main {
    padding-top: $subheader-height;
  }
  .content.share_menu{
    h3{
      text-align: center;
      font-size: 14px;
      margin: 30px 10px;
      font-weight: normal;
      color: #555;
    }
    ul{
      text-align: center;
      flex-wrap: wrap;
      display: flex;
    }
    ul>li{
      text-decoration: none;
      width: 32%;
      display: inline-block;
      margin-bottom: 30px;
      img{
        display: block;
        width: 70%;
        max-width: 100px;
        margin: 0 auto;
      }
    }
    p.attention{
      font-size: 9px;
      text-align: center;
      width: 100%;
      padding: 0 30px 20px;
      letter-spacing: 0.2em;
      margin: 0 auto;
    }
    .other_send{
      max-width: 600px;
      margin: 0 auto;
      padding: 0 20px 10px;
      hr{
        border-top: 1px solid #ccc;
        width: 100%;
        margin: 0 auto;
      }
      h3{
        text-align: center;
      }
      .choose_example_text{
        font-size: 14px;
        display: inline-block;
        color: #FF6C52;
        text-decoration: underline;
        margin-bottom: 6px;
      }
      textarea{
        display: block;
        width: 100%;
        max-width: unset;
        height: 320px;
      }
      a.btn{
        width: 100%;
        margin: 30px auto 50px;
        background: #FF9E97;
      }
    }
  }
  .choose_example_text_modal{
    .close_icon{
      text-align: right;
    }
    h2{
      margin: 20px auto;
      font-size: 16px;
    }
    label{
      margin: 20px auto;
      display: block;
      max-width: 400px;
      text-align: left;
    }
    a.choice_example_text{
      max-width: 130px;
      background: #fff;
      border: 2px solid #F2674E;
      color: #F2674E;
      border-radius: 20px;
      line-height: unset;
      height: unset;
      padding: 10px 0;
    }
    hr{
      border-top: 1px solid #ccc;
      width: 100%;
      margin: 40px auto;
    }
  }

  .notes {
    margin: 2rem;
    padding: 1rem;
    background: #f7f7f7;
    text-align: center;

    h3 {
      margin: 1rem !important;
      font-weight: bold !important;
    }
    p {
      max-width: $form-max-width;
      margin: 0 auto;
    }
  }
}