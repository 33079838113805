@charset "utf-8";

@import "common/variables";
@import "common/mixins";
@import "common/extends";

@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP:300,400&display=swap);

$sp: 460px;
@mixin sp {
  @media (max-width: ($sp)) {
    @content;
  }
}

main div.with_photo {
  font-family: 'Noto Sans JP', sans-serif;

  .content{
    padding-top: 50px;
  }
  #main_visual{
    height: 480px;
    background:url("/images/weddingday_photo/top.jpg") center center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 30px;
    @include sp{
      height: 500px;
    }

    .main_visual_content{
      text-align: center;
      background-color: rgba(255,255,255,0.9);
      width: 100%;
      max-width: 500px;
      padding: 10px;
      position: relative;

      img.priceless{
        position: absolute;
        width: 80px;
        top: -50px;
        right: -20px;
      }
      h2{
        font-size: 14px;
        font-weight: lighter;
        color: #002556;
        letter-spacing: 0.3em;
        margin-top: 20px;
        @include sp{
          font-size: 12px;
        }
      }
      img.logo{
        width: 80%;
        max-width: 330px;
        margin-top: 20px;
      }
      h3{
        font-size: 15px;
        color: #002556;
        letter-spacing: 0.3em;
        margin: 20px 0 10px;
      }
      p{
        font-size: 12px;
        color: #002556;
        font-weight: bold;
        margin-bottom: 10px;
      }
      h3.day_limit{
        margin: 20px 0 0;
        position: relative;
        display: inline-block;
        font-weight: lighter;
        &:before {
          content: "";
          position: absolute;
          display: block;
          width: 20px;
          top: 10px;
          left: -20px;
          border-bottom: #002556 solid 1px;
          transform: rotate(60deg);
        }
        &:after {
          content: "";
          position: absolute;
          display: block;
          width: 20px;
          top: 10px;
          right: -20px;
          border-bottom: #002556 solid 1px;
          transform: rotate(120deg);
        }
      }
    }
  }

  #about{
    padding: 30px 20px;
    text-align: center;

    h2{
      color: #494949;
      font-size: 16px;
      font-weight: lighter;
      letter-spacing: 0.3em;
      margin-top: 20px;
    }
    p{
      color: #494949;
      font-size: 12px;
      letter-spacing: 0.1em;
      line-height: 2em;
      max-width: 470px;
      margin: 30px auto;
    }
  }

  #reason{
    padding: 30px 20px;
    background: url("/images/weddingday_photo/reason_back.jpg") center center;

    h2{
      color: #dc7b79;
      font-size: 16px;
      font-weight: lighter;
      letter-spacing: 0.1em;
      text-align: center;
    }

    .separate{
      max-width: 550px;
      margin: 20px auto;
      display: flex;

      .left{
        width: 50px;
        min-width: 50px;
        margin-right: 5px;
        text-align: right;

        img{
          width: 50px;
          min-width: 20px;
        }
      }
      .right{
        margin-left: 5px;

        h3{
          margin-top: 20px;
          color: #dc7b79;
          font-weight: lighter;
          font-size: 16px;
          letter-spacing: 0.1em;
        }
        p{
          color: #494949;
          font-size: 12px;
          letter-spacing: 0.1em;
          line-height: 1.7em;
          margin-top: 5px;
        }
      }
    }
  }

  #flow {
    padding: 30px 20px;
    background: url("/images/weddingday_photo/flow_back.jpg") center center;
    background-size: cover;

    .flow_content{
      max-width: 650px;
      background-color: rgba(255,255,255,0.9);
      width: 100%;
      padding: 10px;
      box-shadow: 0 0 10px rgba(0,0,0,0.4);
      margin: 0 auto;
      text-align: center;

      h2{
        color: #002556;
        font-size: 16px;
        font-weight: lighter;
        letter-spacing: 0.1em;
        text-align: center;
        margin: 20px 0 30px;
      }
      .flow_arrow{
        width: 70px;
      }
      .separate {
        max-width: 550px;
        margin: 20px auto;
        display: flex;

        .left {
          width: 120px;
          min-width: 20px;
          margin-right: 5px;
          text-align: right;

          img {
            width: 100%;
            min-width: 20px;
          }
        }

        .right {
          margin-left: 5px;
          width: 100%;

          p {
            text-align: left;
            color: #002556;
            font-size: 12px;
            letter-spacing: 0.1em;
            line-height: 1.7em;
            margin-top: 5px;
          }
          p.note{
            font-size: 9px;
            line-height: 1.5em;
          }
        }
      }
    }
  }
  #attention{
    padding: 30px 20px;

    h2{
      color: #dc7b79;
      font-size: 16px;
      font-weight: lighter;
      letter-spacing: 0.1em;
      text-align: center;
      margin: 20px 0 0;
    }
    h3.sub_title{
      color: #dc7b79;
      font-size: 11px;
      font-weight: lighter;
      letter-spacing: 0.1em;
      text-align: center;
      margin-top: 5px;
    }
    hr{
      border: #dc7b79 1px solid;
      width: 20px;
      display: block;
      margin: 20px auto;
    }
    p{
      max-width: 550px;
      margin: 10px auto;
    }
    p.note{
      font-size: 10px;
      text-align: center;
    }
    h3.area{
      color: #dc7b79;
      font-size: 15px;
      letter-spacing: 0.1em;
      text-align: center;
      margin: 20px auto 10px;
    }
    p.place{
      text-align: center;
      color: #dc7b79;
      font-size: 12px;
      font-weight: bold;
      margin: 0 auto;
    }
    p.limit{
      text-align: center;
      color: #dc7b79;
      font-size: 12px;
      margin: 5px auto;
    }
  }
  #form{
    padding:40px 20px 60px;
    background: #f2f2f2;

    h2{
      color: #494949;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0.1em;
      text-align: center;
      margin: 20px 0 0;
    }
    h2.subscribed{
      color: #dc7b79;
      font-size: 12px;
    }
    p{
      text-align: left;
      margin-top: 8px;
      font-size: 11px;
    }

    .form_wrapper{
      max-width: 400px;
      margin: 0 auto;

      input#bride_name_form{
        margin-top: 20px;
      }

      button{
        margin: 30px auto 0;
        display: block;
        padding: 20px;
        width: 100%;
        max-width: 300px;
        background: #dc7b79;
        border-radius: 30px;
        color: #fff;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        cursor: pointer;

        i{
          margin-left: 10px;
          font-weight: bold;
        }
      }
    }
  }
  #sponsor{
    background: #f7f7f7;
    padding: 10px 20px;
    text-align: right;
    
    img{
      margin: 10px 0 0 5px;
      display: inline-block;
      max-width: 80px;
    }
  }
}