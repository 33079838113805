body.partner header.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  height: 50px;
  background: #666;
  border-bottom: 1px solid #e5e5e5; }
  body.partner header.header * {
    color: #fff; }
  body.partner header.header .header_content_wrapper {
    display: flex;
    justify-content: center; }
  body.partner header.header .header_right_content {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    height: 50px;
    line-height: 50px;
    margin-right: 1em; }
  body.partner header.header .dropdown-item {
    color: #4c4c4c;
    font-size: 13px;
    line-height: 2; }
