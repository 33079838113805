@charset "utf-8";

@import "common/variables";
@import "common/mixins";
@import "common/extends";


$pc: 1024px; // PC
$tab: 768px; // タブレット
$sp: 500px;  // スマホ

@mixin pc {
  @media (max-width: ($pc)) {
    @content;
  }
}
@mixin tab {
  @media (max-width: ($tab)) {
    @content;
  }
}
@mixin sp {
  @media (max-width: ($sp)) {
    @content;
  }
}


main div.with_yui {
  div.sub_header h2 img{
    height: 40px;
  }
  .content {
    padding-top: $subheader-height;
    background:url("/images/yui/sheet.gif");
    background-size: 50px 50px;

    section#example{
      padding: 20px;
      img.campaign_ribbon{
        margin: 20px auto;
        display: block;
        width: 100%;
        max-width: 300px;
      }
      h3{
        color: #222222;
        text-align: center;
        font-size: 24px;
        span{
          color: #f01f00;
        }
        @include sp{
          font-size: $font-ll;
        }
      }
      p{
        margin: 20px auto;
        color: #222222;
        text-align: center;
        font-size: 20px;
        line-height: 1.6em;
        span{
          color: #f01f00;
        }
        @include sp{
          font-size: $font-m;
        }
      }
      img.table{
        margin: 10px auto 0;
        display: block;
        width: 100%;
      }
      p.memo{
        margin: 0 auto;
        text-align: right;
        font-size: $font-s;
      }
    }

    section.coupon{
      padding: 50px 20px 20px;
      .coupon_content{
        border: 2px solid #f01f00;
        position: relative;
        background: #fff;
        max-width: 580px;
        margin: 0 auto;
        padding-top: 20px;

        h3{
          position: absolute;
          right: 0;
          left: 0;
          top: -10px;
          text-align: center;
          span{
            background: #fff;
            padding: 0 5px;
            color: #f01f00;
            font-size: 20px;
            @include sp{
              font-size: $font-l;
            }
          }
        }
        p.coupon_description {
          color: #222222;
          font-size: $font-l;
          padding: 10px 20px;
          line-height: 1.6em;
          text-align: center;
          @include sp{
            padding: 20px 10px;
          }
        }
        .coupon_flex{
          padding: 20px 20px;
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          @include sp{
            align-items: flex-end;
            padding: 20px 10px;
          }

          .coupon_code{
            background: #e2e0e0;
            text-align: center;
            padding: 20px 10px;
            margin: 0 20px 20px 0;
            position: relative;
            z-index: 1;
            &:after{
              content: "";
              position: absolute;
              width: 50px;
              height: 50px;
              background: #e2e0e0;
              right: -15px;
              top: 0;
              bottom: 0;
              margin: auto;
              transform: rotate(45deg);
              z-index: -1;
            }
            @include sp{
              margin: 0 0 0 0;
            }
            p{
              color: #222222;
              font-size: $font-m;
              margin-bottom: 10px;
            }
            p.code{
              color: #f01f00;
              font-weight: bold;
              font-size: 25px;
            }
          }
          .coupon_left{
            width: 50%;
            margin-right: 5px;

          }
          .coupon_right{
            width: 50%;
            flex: 1 1 ;
            margin-left: 5px;
            max-width: 300px;
          }
        }
        img.mizuhiki{
          position: absolute;
          width: 40px;
          margin: 0 auto;
          bottom: -18px;
          left: 0;
          right: 0;
        }
      }
      p.attention {
        text-align: center;
        max-width: 580px;
        margin: 20px auto;
      }
      a.detail_button{
        display: block;
        position: relative;
        margin: 50px auto;
        width: 100%;
        max-width: 300px;
        padding: 20px 10px;
        text-align: center;
        color: #fff;
        background: #f01f00;
        text-decoration: none;
        font-weight: bold;
        font-size: $font-ll;
        border-radius: 20px;
        cursor: pointer;
        i{
          position: absolute;
          font-size: 25px;
          top: 17px;
          right: 20px;
        }
      }
    }

    section#about{
      padding: 0 0 20px;
      .about_visual{
        height: 300px;
        background:url("/images/yui/picture.jpg");
        background-size: cover;
        background-position: center center;
        @include sp{
          height: 200px;
        }
      }
      .about_flex{
        display: flex;
        padding: 0 20px;
        width: 100%;
        max-width: 580px;
        margin: -30px auto 0;

        .left{
          width: 30%;
        }
        .right{
          padding: 50px 0 0 10px;
          width: 70%;
          h3{
            text-align: center;
            font-size: 24px;
            line-height: 1.6em;
            font-weight: normal;
            margin-top: 20px;
            @include sp{
              margin-top: 0;
              font-size: $font-ll;
            }
            span{
              color: #f01f00;
            }
          }
          p{
            text-align: center;
            line-height: 1.6em;
            max-width: 300px;
            margin: 10px auto;
          }
        }
      }
    }
    
    section#convenient{
      background: #e6f0f2;
      padding: 30px 20px;
      h3{
        text-align: center;
        color: #f01f00;
        font-size: 24px;
        line-height: 1.6em;
        margin-top: 20px;
        @include sp{
          margin-top: 0;
          font-size: $font-ll;
        }
      }
      .convenient_flex{
        display: flex;
        flex-grow: 1;
        flex-wrap:wrap;

        .convenient_item{
          padding: 10px;
          width: 50%;
          @include sp{
            width: 100%;
          }
          h4{
            text-align: center;
            color: #f01f00;
            font-size: 20px;
            line-height: 1.6em;
            margin-top: 20px;
            @include sp{
              font-size: $font-ll;
            }
          }
          p{
            font-size: 15px;
            line-height: 1.6em;
            margin: 10px 0 20px;
            @include sp{
              font-size: $font-m;
            }
          }
        }
      }
    }
  }
}