@charset "utf-8";

@import "common/variables";
@import "common/mixins";
@import "common/extends";

.manage_mail_certification {
  h3{
    margin: 30px 0;
    text-align: center;
  }
  p.confirm_text{
    padding: 10px 20px 30px 20px;
    max-width: 480px;
    margin: 0 auto;
  }
  main {
    padding-top: $subheader-height;
    #content{
      padding: 1px 0 50px;
      .manage_certification_mail{
        padding: 25px 20px;
        p{
          margin: 20px 0 40px 0;
        }
        .flex{
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
        }
        @media screen and (max-width: 400px) {
          .flex {
            display: block;
            .right{
              text-align: center;
            }
            button{
              display: block;
              width: 100%;
              max-width: 200px;
              margin: 20px auto;
            }
          }
        }
        .new_mail_title{
          margin: 40px 0 0 0;
        }
        .left{
          flex: 2;
        }
        .right{
          flex: 1;
          text-align: right;
        }
        div.title{
          font-weight: bold;
          font-size: 14px;
        }
        div.now_mail{
          margin-top: 10px;
        }
        .error_message{
          width: 100%;
        }
        button{
          font-size: 14px;
          max-width: 200px;
          padding: 10px 20px;
          border-radius: 10px;
          background: #FF695D;
          color: #fff;
        }
        input{
          transition: all 0.5s;
          display: block;
          width: 100%;
          min-width: 200px;
          margin-top: 10px;
          padding: 10px 5px;
          border-bottom: 1px solid #ddd;
          &:hover {
            border-bottom: 1px solid #000;
          }
        }
      }
    }
  }
}

