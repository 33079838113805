@charset "utf-8";

@import "common/variables";
@import "common/mixins";
@import "common/extends";

body.with {
  #content.with_confirm{
    padding-top:$header-height+$subheader-height;
    h3{
      margin: 20px 0;
      text-align: center;
    }
    p{
      display: block;
      width: 100%;
      max-width: 580px;
      margin: 20px auto 60px;
      padding: 0 20px;
    }
    .manage_buttona{
      padding-bottom: 50px;
    }
  }
}