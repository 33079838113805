body.partner .btn {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  padding: 16px 26px;
  border-radius: 4px;
  color: #fff;
  background: linear-gradient(45deg, #75AAAA, #B5C5B9);
  cursor: pointer;
  width: auto;
  height: auto;
  max-width: 100%;
  line-height: 1;
  margin: auto; }
  body.partner .btn.btn_large {
    padding: 16px; }

body.partner .btn_block {
  display: block;
  width: 100%; }
  body.partner .btn_block + .btn_block {
    margin-top: 8px; }
