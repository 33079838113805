@charset "utf-8";

@import "common/variables";
@import "common/mixins";
@import "common/extends";

.verify {
  main {
    padding-top: $subheader-height;
  }
}