@charset "utf-8";

@import "common/variables";
@import "common/mixins";
@import "common/extends";

.manage_list {
  main {
    padding-top: $subheader-height;
    padding-bottom: 80px;
    background: #fff;
    h3 {
      background-color: $title-background-color;
      color: #fff;
      margin-top: 1em;
      padding: 0.5em 1em;
    }
    .fix_banner{
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      max-width: 500px;
      margin: 0 auto;
      position: fixed;
      padding-bottom: 15%;
      height: 0;
      overflow: hidden;
      iframe {
        position: absolute;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        border: 0;
      }
    }
    @media screen and (min-width: (500px)) {
      .fix_banner {
        padding-bottom: 75px;
      }
    }
  }
  .font_attend, .font_absent {
    font-size: $font-xl;
    font-style: normal;
    padding: 0 5px;
  }
  .font_attend {
    color: #22c064;
  }
  .font_absent {
    color: #ff6c52;
  }
  .font_all{
    color: #999999;
  }
  .font_ticket_payments_summary{
    font-size: $font-s;
  }
  .manage_list_count{
    padding-top: 1em;
    padding-bottom: 0.5em;
    background-color: $background-color;
    border-bottom: 1px solid #e5e5e5;
    & > div:nth-child(1){
      float: left;
    }
    & > div:nth-child(2){
      float: right;
    }
    .select{
      padding-top: 1.5em;
      background-color: transparent;
      &:before{
        content:none;
      }
      i{
        padding-right: 0.3em;
        font-size: $font-ll;
        vertical-align: text-top;
      }
      select{
        display: inline-block;
        width: auto;
        padding: 0;
        background-color: transparent;
        border: none;
        color: $accent-color;
        font-size: $font-l;
      }
    }
  }
  ul.entry_container {
    margin:0 0 1em 0;

    li {
      border-bottom: 1px solid $border-color;
      list-style: none;

      &[data-entry=absent] {
        background-color: $background-color;
      }

      a {
        display: block;
        padding: 0.8em 0;
        position: relative;

        i{

        }

        // 出/欠
        i:first-child {
          font-size: 14px;
          display: inline-block;
          text-align: center;
          padding: 0.5em 0.5em;
          color: #fff;
          line-height: 1;
          border-radius: 50%;
          vertical-align: middle;
          text-align: center;
          font-style: normal;
        }

        .font_attend {
          background-color: #22c064;
        }
        .font_absent {
          background-color: #e0483e;
        }
        .font_online {
          background-color: #22c064;
        }

        .right {
          width: 180px;
          padding-right: 3em;
          display: inline-block;
          float: right;
          font-size: $font-s;
          font-weight: bold;
          color: #999999;
          line-height: 2.5;

          .ticket_payments {
            width: 30%;
            padding: 1%;
            float: right;
            text-align: right;
          }

          .date {
            width: 40%;
            padding: 1%;
            float: right;
            text-align: right;
          }

          .time {
            width: 30%;
            padding: 1%;
            float: right;
          }
        }

        // 矢印
        i.arrow {
          position: absolute;
          right: 0em;
          color: #999999;
          line-height: 2;
        }
      }
      a.cancel_payment {
        display: inline;
        text-decoration: underline;
      }

      // 招待客の詳細
      .entry_detail {
        display: none;
        padding:0.5em;

        &.active {
          display: block;
          word-break: break-all;
        }
        [data-edit-mode="true"] {
          display: none;
        }
        &.is_edit_mode {
          [data-edit-mode="true"] {
            display: block;
          }
          [data-edit-mode="false"] {
            display: none;
          }
        }
        div {
          padding-left: 1.5em;
          position: relative;

          i {
            position: absolute;
            left: 0;
            text-align: center;
            width: 1em;
          }
        }

        .payment,.payment_the_day{
          margin-top:1em;

          .payment_frame {
            margin-top: 0.5em;
            border-radius: 5px;
            background: #e5e9ee;
            padding:0.5em;
          }
        }

        .entry_actions {
          padding-left: 0;

          a {
            display: inline-block;
            text-decoration: underline;
          }
        }
      }
    }
  }

  .list-btn {
    position: relative;
    display: block;
    width: 100%;
    max-width: 300px;
    color: $accent-color;
    background-color: #fff;
    border: 1px solid $accent-color;

    i {
      position: absolute;
      top: 50%;
      left: 24px;
      transform: translateY(-50%);
      font-size: 24px;
    }
  }

  .utf_download {
    font-size: $font-s;
    color: #999999;

    a{
      text-decoration: underline;
      color: #999999;
    }
  }

  .entry_form{
    span {
      vertical-align: bottom;
    }
    .entry_form_inner {
      margin: 0 auto 2em auto;
      width: 100%;
      text-align: left;
      .entry_form_radio01{
        margin-bottom:1em;
      }
      .entry_form_checkbox,
      .entry_form_radio02{
        margin:1em 0;
      }
    }
    input[type="text"], input[type="tel"]{
      max-width: 100%;
      width: 100%;
    }

    input[type="checkbox"]:not(.switch_content) {
      display: none;
      &+label{
        margin-left: 0em;
        vertical-align: bottom;
      }
      &+:before{
        margin-right: 0.2em;
        content: "\f372";
        font-family: "Ionicons";
        font-size: 20px;
      }
      &:checked+:before{
        content: "\f373";
      }
    }
    textarea{
      width: 100%;
      max-width: 100%;
    }
    label {
      cursor: pointer;
    }
  }
  .lead_premium {
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    border: none;
    text-align: center;
    span{
      padding-bottom: 0.5em;
      color: $accent-color;
      font-size: $font-m;
    }
    a{
      margin-top: 0.5em;
      text-decoration: none;
      color: #fff;
      font-weight: bold;

    }
  }
  [data-show-if-send-mail], [data-show-if-has-modal-error] {
    display: none;
  }
  .split_input {
    display: flex;
    > input {
      width: 50% !important;
      margin: 0 8px;
    }
  }

  .login-link {
    display: block;
    margin-top: 13px;
    color: $primary-color;
    text-decoration: underline;
  }

  .form_title {
    margin-top: 13px;
    font-weight: bold;
  }

  .entry_form_radio01 label,
  .entry_form_radio02 label {
      margin-right: 13px;
  }

}
