@charset "utf-8";

@import "common/variables";
@import "common/mixins";
@import "common/extends";

body.manage_bulk_mail {
  main{
    padding-top: $subheader_height;
  }
  var{
    display:none;
  }
  .attention{
    margin:1em;
    background: #f7f7f7;
    font-size: 11px;
    padding: 1em;
  }
  .field {
    padding-top: 1em;

    .field_content {
      margin-left: 1em;
      textarea{
        margin-top: 10px;
      }
    }
  }
  .message_pattern{
    width: 100%;
    a{
      display: inline-block;
      color: $primary-color;
      text-decoration: underline;
      margin: 6px 0 4px 6px;
    }
  }

  .message-sample-modal{
    .remodal-body{
      padding: 0 0 40px;
      .sample_message{
        h2{
          margin: 20px auto 0;
          font-size: 16px;
        }
        p{
          margin: 20px 40px;
          display: inline-block;
          text-align: left;
          white-space: pre-wrap;
        }
        a{
          max-width: 130px;
          background: #fff;
          border: 2px solid $primary-color;
          color: $primary-color;
          border-radius: 20px;
          line-height: unset;
          height: unset;
          padding: 10px 0;
        }
        hr{
          border-top: 1px solid #ccc;
          width: 100%;
          margin: 40px auto;
        }
      }
    }
  }
  .fix_content{
    background-color: rgba(255,255,255,0.8);
    padding: 5px 2px;
    max-width: 760px;
    width: 100%;
    .manage_button {
      padding: 0!important;
    }
  }
  .btn_preview {
    background-color: $sub-color;
  }
  .remodal-header {
    border-bottom: 0px;
  }
  .preview_modal {
    p{
      margin: 20px;
      text-align: left;
      white-space: pre-wrap;
    }
    hr{
    margin: 0 20px;
    border-top: 1px solid #000;
  }
  }
}
