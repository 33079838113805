@charset "utf-8";

@import "common/variables";
@import "common/mixins";
@import "common/extends";


.user.user__signup_partners_user {
  h1{
    text-align: center;
  }
  h1.logo img{
    width: 160px;
  }
  h1.logo.tg_logo img{
    width: 214px;
  }
  h2.title{
    margin: 32px 0;
  }
  .alert{
    border: none;
    background: none;
    ul{
      list-style: none;
    }
  }
  .manage_button button{
    max-width: 320px;
    background: linear-gradient(90deg, #B4D2C9, #82B2B1);
  }
  .signup_alert{
    margin: 0 auto;
  }
  #create_form .input_section{
    text-align: left;
    display: block;
    margin-bottom: 16px;
    @media (min-width: 600px) {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      flex-wrap: wrap;
    }
    label{
      text-align: left;
      @media (min-width: 600px) {
        width: 40%;
        text-align: left;
      }
    }
    .input_names{
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      @media (min-width: 600px) {
        width: 60%;
      }
      input{
        width: 49%;
        margin: 8px 0 0;
      }

    }
    .input_area{
      text-align: left;
      @media (min-width: 600px) {
        width: 60%;
      }
      input{
        margin-top:8px;
        border-radius: 0;
      }
      ul{
        list-style: none;
      }
    }
    .input_area.tg_place{
      position: relative;
      ul{
        padding: 8px 0;
        max-height: 200px;
        overflow: scroll;
        position: absolute;
        background: white;
        max-width: 100%;
        left: 0;
        right: 0;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        li{
          padding: 8px;
          cursor: pointer;
          &:hover{
            background: #F4F4F4;
          }
        }
      }
    }
  }
  .use-web-table-radio {
    + div {
      margin-top: 16px;
      border: none !important;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      display: inline-block;
    }
    + :before {
      content: '';
    }
    &[value="0"] {
      + div {
        width: 90px;
        height: 100px;
        background-image: url('/images/partners/use_web_table_1_inactive.jpg');
      }
      &:checked + div {
        background-image: url('/images/partners/use_web_table_1_active.jpg');
      }
    }
    &[value="1"] {
      + div {
        width: 180px;
        height: 100px;
        background-image: url('/images/partners/use_web_table_2_inactive.jpg');
      }
      &:checked + div {
        background-image: url('/images/partners/use_web_table_2_active.jpg');
      }
    }
  }
}