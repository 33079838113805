@charset "utf-8";

@import "common/variables";
@import "common/mixins";
@import "common/extends";


main div.with_chaplin {
  background-color: #fff;

  .title_back_img{
    width: 100%;
    height: 300px;
  }
  h2 img{
    height: 42px;
  }
  .content{
    background: #f9ebe8;
    padding-top: $subheader-height;
    img{
      width: 100%;
    }
    .coupon{
      padding: 10px 0 50px 0;
      margin: 30px auto;
      width: 93%;
      .coupon_title{
        padding: 20px 0;
        border-radius: 20px 20px 0 0;
        background-color: #356589;
        color: #fff;
        text-align: center;
        font-weight: bold;
        font-size: 17px;
      }
      .coupon_text{
        padding: 30px 0;
        border-radius:0 0 20px 20px;
        background-color: #fff;
        color: #356589;
        text-align: center;
        font-weight: bold;
        font-size: 15px;
        span{
          font-size: 17px;
          color: #ff6c52;
        }
      }
      .coupon_button{
        position: relative;
        min-width: 300px;
        margin: 40px auto 0 auto;
        display: block;
        border-radius: 30px;
        background-color: #ff6c52;
        text-align: center;
        letter-spacing: 0.3em;
        width: 80%;
        padding: 20px 0;
        font-size: 18px;
        color: #fff;
        i{
          position: absolute;
          right: 20px;
          display: inline-block;
          width: 30px;
          text-align: right;
        }
      }
    }
  }
}
