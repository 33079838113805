.btn_base {
  background-color: #ff6c52;
  border-radius: 7px;
  color: #fff;
  display: inline-block;
  text-align: center; }
  .btn_base.btn_disabled {
    background-color: #ccc;
    color: #fff;
    cursor: not-allowed; }

body.partner_login main {
  background-image: url("/images/login_background_pc.jpg");
  background-position: center center;
  background-size: cover;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 20px; }

body.partner_login .errors, body.partner_login .message {
  background: transparent;
  margin: 0 auto;
  border: none;
  max-width: 340px;
  padding: 1em;
  text-align: center;
  border-radius: 6px;
  color: #e56473; }

body.partner_login .partner_login_content {
  max-width: 800px;
  width: 100%;
  margin: 50px auto;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  display: flex;
  align-items: center;
  justify-content: space-between; }
  body.partner_login .partner_login_content .login_left {
    width: calc(100% - 320px);
    padding: 1em 2em; }
  body.partner_login .partner_login_content .login_right img {
    width: 340px;
    border-radius: 20px; }
  body.partner_login .partner_login_content h1 {
    text-align: center; }
    body.partner_login .partner_login_content h1 img {
      height: auto;
      width: 160px; }
  body.partner_login .partner_login_content h2 {
    color: #333;
    margin: 24px auto;
    text-align: center; }
  body.partner_login .partner_login_content form input {
    font-weight: normal;
    border: 1px solid #CCCCCC;
    color: #333;
    font-size: 13px !important;
    margin: 16px auto 0;
    background: #fff;
    max-width: 340px; }
    body.partner_login .partner_login_content form input::placeholder {
      color: #ccc; }
    body.partner_login .partner_login_content form input:focus {
      outline: 0; }
    body.partner_login .partner_login_content form input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px white inset;
      background-color: white; }
  body.partner_login .partner_login_content form a.login_btn {
    margin: 24px auto 16px;
    border-radius: 5px;
    max-width: 340px; }
  body.partner_login .partner_login_content .forget_password {
    margin-top: 0;
    color: #333;
    display: block;
    text-align: center; }

@media (max-width: 680px) {
  body.partner_login main {
    background-image: url("/images/login_background_partners.jpg"); }
    body.partner_login main .partner_login_content {
      padding: 64px 2em 54px; }
      body.partner_login main .partner_login_content .login_right {
        display: none; }
      body.partner_login main .partner_login_content .login_left {
        width: 100%; } }
