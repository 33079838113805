@charset "utf-8";

@import "common/variables";
@import "common/mixins";
@import "common/extends";

.manage_form {
  main {
    padding-top:$subheader-height;
    .area{
      max-width: 500px;
      margin: 0 auto;
      padding: 1em;
    }
    p.description{
      max-width: 500px;
      margin: 0 auto;
      padding: 1em 0;
    }
    p.no_entry_description{
      max-width: 500px;
      margin: 1em auto ;
      width: 100%;
      border: 1px solid #E56473;
      border-radius: 6px;
      padding: 13px;
    }
    h3.title.reply {
      padding: 1em 0 !important;
      span{
        display: block;
        max-width: 500px;
        margin: 0 auto;
        padding: 0 1em;
      }
    }
    ul.new_design{
      margin: 0 auto;
      max-width: 500px;
      list-style: none;
      li{
        margin: 6.5px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 46px;
        div.left{
          min-width: 145px;
          height: 100%;
          display: flex;
          align-items: center;

          .sort_area {
            padding: 0.8em;
            width: 28px;
            display: inline-block;
          }
        }
        div.right {
          margin-left: auto;
          padding: 0.8em;
        }
        label.inline_radio_label{
          font-size: 12px;
        }
      }
      li.free_content {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        height: auto;
        button.delete_free_form{
          padding: 0.5em 0.5em;
          border: 1px solid #4a4a4a;
          border-radius: 10px;
          margin: 0 0 0 5px;
          cursor: pointer;
          transition: 0.3s background;
          &:hover{
            background: #eee;
          }
        }
        div.free_content_wrap{
          width: 100%;
          display: flex;
          align-items: center;
          margin-bottom: 5px;
          div.left {
            margin-left: auto;
            margin: 0.8em 0;
          }
          div.right {
            padding: 0.8em;
          }
        }
      }
      div.free_option {
        display: flex;
        align-items: center;
        padding-left: 28px;
        margin-bottom: 5px;
        i {
          padding: 0 5px;
        }
        span.radio_icon::before{
          font-family: "Ionicons";
          font-size: 1em;
          content: "\f3a6";
        }
        span.check_icon::before{
          font-family: "Ionicons";
          font-size: 1em;
          content: "\f372";
        }
      }
      div.free_option_add_trigger {
        display: flex;
        align-items: center;
        padding-left: 30px;
        margin-top: 5px;
        width: fit-content;
        button {
          padding: 0.5em 0.5em;
          border: 1px solid #4a4a4a;
          border-radius: 10px;
          margin: 0 0 0 5px;
          cursor: pointer;
          transition: 0.3s background;
          &:hover{
            background: #eee;
          }
        }
      }
      li.free_add{
        div.free_add_button{
          cursor: pointer;
          border: 1px solid #4a4a4a;
          padding: 8px 14px;
          border-radius: 5px;
          margin: 5px auto;
          i{
            font-size: $font-l;
          }
        }
      }
      li.notice{
        flex-wrap: wrap;
        div.notice_box{
          width: 100%;
          text-align: center;
        }
        textarea{
          width: 24em;
          text-align: center;
          margin: 1em auto;
          border: solid 1px #ddd;
          border-radius: 5px;
          padding: 0.9em;
        }
        textarea:focus{
          border-color: #e5e5e5;
          border-radius: 5px;
        }
        // iPhoneサイズ
        @media screen and (max-width: 350px) {
          textarea{
            font-size: 11px;
          }
        }
      }
      li.not_sort {
        box-sizing: border-box;
        border: 1px solid #ddd;
        border-radius: 7px;
        height: auto;
      }
      div.free_form {
        width: 100%;
        input{
          padding: 5px;
          margin-left: 28px;
          margin-right: 0.8em;
          margin-bottom: 8px;
          border-radius: 5px;
          border: 1px solid #ddd;
        }
      }

      div.free_option_wrap {
        input{
          padding: 5px;
          border-radius: 5px;
          border: 1px solid #ddd;
          margin: 0 5px;
        }
      }

      div.free_selector{
        white-space: nowrap;
        margin-left: auto;
      }
    }
    ul#form_list li:not(.free_add){
      box-sizing: border-box;
      border: 1px solid #ddd;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.16);
      border-radius: 7px;
    }
    .guest_mail{
      .mail_disable{
        display: none;
      }
      &.hidden_mail{
        padding: 0 1em 4em;
        .mail_disable{
          display: inline;
        }
        .mail_enable{
          color: #CCCCCC;
        }
        h3{
          color: #ccc;
        }
        textarea{
          background: #fff;
          color: #ccc;
        }
      }
      &.no_entry{
        padding: 0 1em 4em;
        .mail_enable{
          color: #CCCCCC;
        }
        .no_entry{
          display: none;
        }

        h3{
         color: #ccc;
        }
        textarea{
          background: #fff;
          color: #ccc;
        }
      }
    }
    ul.old_design{
      list-style: none;

      li {
        padding: 0.7em 0;

        label {
          margin-left: 1em;
        }

        .req {
          background-color: $emphasis-color;
          color: #fff;
          font-size: $font-s;
          padding: 0 0.5em;
        }

        input[type="checkbox"] {
          @extend %common_checkbox;
        }
        div.free_form{
          padding: 5px 0 0 15px;
        }

        textarea {
          margin-top: 8px;
          &.off {
           display: none;
          }
        }
      }
    }

    .btn_preview {
      background-color: $sub-color;
    }
    .button_wrapper{
      position: sticky; // 下付きにする
      bottom: 0;
      margin-top: 32px;
    }
    .fix_content{
      background-color: rgba(255,255,255,0.8);
      padding: 5px 2px;
      max-width: 760px;
      width: 100%;
      .manage_button {
        padding: 0!important;
      }
    }
    .fix_content.active{
      position: fixed;
      bottom: 0;
    }
  }
}