@charset "utf-8";

@import "common/variables";
@import "common/mixins";
@import "common/extends";

.photo {
  main {
    padding-top: $subheader-height;

    #content {
      padding-top: $subheader-height;

      .description {
        text-align: center;
      }

      .limit_date {
        color: #f00;
        font-size: $font-ll;
        font-weight: bold;
        padding-bottom: 1em;
        text-align: center;
      }

      .photo_sample {
        a {
          background-color: #ff9e97;
          width: 100%;
        }
      }

      .photo_buy {
        padding: 1em 0;

        input[type="button"] {
          background-color: #ff7568;
          background-image: none !important;
          border-radius: 7px;
          border: none !important;
          color: #fff;
          display: block;
          height: 50px;
          line-height: 50px;
          margin: 0 auto;
          max-width: 480px;
          padding: 0;
          text-align: center;
          width: 100%;
        }
      }

      .photo_production {
        padding: 1em 0;
      }

      .caution {
        margin: 0 auto;
        max-width: 480px;
        padding-top: 1em;
        padding-bottom: 4em;

        ul {
          margin-left: 2em;
        }
      }
    }
  }
}