@charset "utf-8";

@import "common/variables";
@import "common/mixins";
@import "common/extends";

.unsubscribe {
  main {
    padding-top: $subheader-height;
  }
}
