@charset "utf-8";

@import "common/variables";
@import "common/mixins";
@import "common/extends";

.about {
  main{
    padding-top: $subheader_height;
  }
  ul.about_list {
    li {
      border-bottom: 1px solid #ccc;
      list-style: none;

      a {
        display: block;
        padding: 1em;
        position: relative;

        i {
          position: absolute;
          right: 1em;
        }

      }
    }
  }
  .manage_header a.view_invitation {
    display:none;
  }
}