body.partner.partner_no_auth {
  background: #999; }

body.partner a.link {
  color: #0F3986;
  text-decoration: underline; }

body.partner .bg {
  background-color: #f7f7f7; }

body.partner .vex {
  z-index: 1060; }

body.partner .remodal-overlay {
  z-index: 1040; }

body.partner .remodal-wrapper {
  z-index: 1050; }
