@charset "utf-8";

@import "common/variables";
@import "common/mixins";
@import "common/extends";

.manage_design, .thanks_letter_design {
  -webkit-tap-highlight-color: transparent;
  main {
    padding-top: $subheader-height;
  }
  section.design_selected {
    background-color: #F7F7F7;
    width: 100%;
    .selected_content {
      width: 100%;
      .selected_body {
        display: flex;
        justify-content: space-between;
        .selected_left {
          width: 100%;
          .selected_design {
            box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.2);
            width: 50%;
            padding-top: 80%;
            margin: 10% auto;
            background-size: cover;
            @media screen and (max-width: 468px) {
              margin: 20px auto;
              width: 80%;
              padding-top: 130%;
            }
          }
        }
        .selected_right {
          width: 100%;
          padding: 60px 20px 0 20px;
          text-align: center;
          position: relative;
          .selected_title {
            display: inline;
            color: #333;
            font-weight: bold;
            font-size: 10px;
            padding: 7px;
            border-top: #333 solid 2px;
            border-bottom: #333 solid 2px;
            line-height: 1.4;
          }
          .selected_design_name {
            margin-top: 20%;
            display: block;
            font-size: 23px;
            padding-bottom: 10px;
            @media screen and (max-width: 768px) {
              font-size: 16px;
              overflow: hidden;
              padding-bottom: 5px;
            }
          }
          .selected_preview_button {
            background-color: $primary-color;
            margin: 20% auto 20px auto;
            color: #fff;
            display: inline-block;
            width: 80%;
            height: 40px;
            line-height: 40px;
            border-radius: 12px;
            font-size: 12px;
          }
        }
      }
    }
  }

  // デザイン一覧
  section.design-catalog {
    padding: 48px 0 36px;
    text-align: center;

    .design-catalog__title {
      font-size: 22px;
      line-height: 1;
    }
    .design-catalog__sub-title {
      margin: 6px 0 28px;
      font-size: 15px;
      line-height: 1;
    }
    .design-container {
      display: flex;
      flex-wrap: wrap;
      max-width: 540px;
      margin: 0 auto;
      padding: 0 1rem 2rem;
    }
    + hr {
      margin: 0 8px;
      border-width: 1px;
      border-color: #ccc;
    }

    .design {
      width: 33.333%;
      padding: 1rem;
      @media screen and (max-width: 468px) {
        width: 50%;
      }

      > a {
        position: relative;
        display: block;
        background-color: #fff;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.4);
      }
      .design__thumbnail {
        padding-top: 160%;
        background-size: cover;
      }
      .design__info {
        text-align: left;
      }
      .design__title {
        margin: 10px 0 4px;
        font-size: 14px;
        font-weight: bold;
      }
      .design__description {
        font-size: 10px;
        color: $color-gray;
      }
      .design__tags {
        display: flex;
        font-size: 10px;
        color: #00376b;
        flex-wrap: wrap;

        *:not(:last-child) {
          margin-right: 0.25rem;
        }
      }
    }
  }

  // ウィザード時に表示するスペース領域
  .wizard_space{
    height: 100px;
  }

  .design_modal {
    height: 96%;
    padding: 0;
    overflow-y: auto;

    form {
      height: 100%;

      iframe {
        height: 100%;
        width: 100%;
      }
      .button_bottom {
        background-color: #fff;
        border-top: 1px solid $border-color;
        padding: 5px;
        position: absolute;
        bottom: 0;
        width: 100%;
      }
    }
  }

  // モーダル内のスピナー
  .spinner-wrapper {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background: #f1f1f1;

    .spinner {
      margin: -40px 0 0;
    }
  }

  .iframe-parent-wrapper {
    width: 100%;
    height: 100%;
    padding-bottom: 60px;
    overflow: hidden;
    .iframe-parent {
      width: 100%;
      height: 100%;
      overflow: auto;
      -webkit-overflow-scrolling: touch;

      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }

  .arrow-wrapper {
    position: absolute;
    top: 50%;
    width: 100%;

    .arrow {
      position: absolute;
      width: 40px;
      height: 40px;
      font-size: 40px;
      background-color: rgba(255,255,255,0.8);
      color: #aaa;

      &.left {
        left: 16px;
      }
      &.right {
        right: 18px;
      }
    }
  }

  .vex.vex-theme-os {
    z-index: 10000;
  }

  .remodal {
    .mailad_confirm {
      color: $color-gray;
      font-size: $font-s;
      margin-top: 1em;
    }
  }

  .sup {
    background-color: $background-color;
    margin: 1em 1em 0;
    padding: 1em;
  }
}
