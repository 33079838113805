@charset "utf-8";

@import "common/variables";
@import "common/mixins";
@import "common/extends";
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap');

$sp: 460px;
@mixin sp {
  @media (max-width: ($sp)) {
    @content;
  }
}
main div.with_lovegraph {
  background-color: #fff;


  .title_back_img{
    width: 100%;
    height: 300px;
  }
  h2 img{
    height: 42px;
  }
  .content{
    color: #4C4C4C;
    font-family: 'Noto Sans JP', sans-serif;
    font-style: normal;
    background: #F3EEEA;
    padding-top: $subheader-height;
    img{
      width: 100%;
    }
    .plan{
      background: #F3EEEA;
      padding: 30px 20px;
      img{
        border-radius: 20px;
        max-width: 480px;
        margin: 0 auto;
        display: block;
      }
      .coupon{
        max-width: 480px;
        background: #fff;
        padding: 30px 20px;
        margin: 20px auto;
        border-radius: 20px;
        text-align: center;
        h3{
          font-weight: 700;
          font-size: 18px;
          line-height: 26px;
          text-align: center;
          position: relative;
        }
        p{
          margin-top: 10px;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }
        .code{
          font-weight: 700;
          font-size: 28px;
          line-height: 24px;
          margin: 20px 0;
          color: #FF6C52;
          @include sp{
            font-size: 18px;
          }
        }
        button{
          border-radius: 40px;
          border: 2px solid #FF6C52;
          font-weight: 500;
          font-size: 22px;
          line-height: 20px;
          color: #FF6C52;
          padding: 14px 40px;
          cursor: pointer;
          transition: all 0.3s;
          @include sp{
            padding: 10px 40px;
            font-size: 16px;
          }
          &:hover{
            background: #FF6C52;
            color: #fff;
          }
        }
      }
      a{
        background: #FF6C52;
        color: #fff;
        font-weight: 700;
        font-size: 20px;
        display: block;
        width: 100%;
        padding: 18px 0;
        border-radius:7px;
        text-align: center;
        position: relative;
        max-width: 420px;
        margin: 30px auto;
        &:after{
          content: '';
          border-top: 2px solid #fff;
          border-right: 2px solid #fff;
          transform: rotate(45deg);
          height: 14px;
          width: 14px;
          top: 20px;
          right: 20px;
          position: absolute;
        }
        @include sp{
          font-size: 16px;
        }
      }
    }
    .about{
      background: #fff;
      padding: 60px 20px;
      h3{
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
      }
      p{
        margin: 10px auto;
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
        max-width: 480px;
      }
      ul{
        list-style: none;
        li{
          max-width: 480px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 20px auto;
          .list_image{
            width: 30%;
          }
          .texts{
            width: 70%;
            padding: 0 0 0 20px;
            h4{
              font-weight: 700;
              font-size: 16px;
            }
          }
        }
      }
      .images{
        margin-top: 60px;
        width: 100%;
        img{
          margin: 10px 0;
          @include sp{
            margin: 5px 0;
          }
        }
        .column{
          display: flex;
          img{
            width: calc(50% - 10px);
            @include sp{
              width: calc(50% - 5px);
            }
          }
          img:first-child{
            margin-right: 10px;
            @include sp{
              margin-right: 5px;
            }
          }
          img:last-child{
            margin-left: 10px;
            @include sp{
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
}
