body.partner .alert-wrapper {
  margin-bottom: 1em; }

body.partner .alert {
  padding: 0.5em;
  background: #fff;
  border: 2px solid #e56473;
  border-radius: 0.25em;
  color: #e56473; }
  body.partner .alert ul {
    margin-left: 1.5em; }
