body.partner .table {
  width: 100%;
  margin-bottom: 16px;
  background-color: #fff;
  text-align: center; }
  body.partner .table th,
  body.partner .table td {
    padding: 8px;
    vertical-align: center; }
  body.partner .table td {
    border-bottom: 1px solid #CECECE; }
  body.partner .table thead {
    position: sticky;
    z-index: 1;
    top: 0; }
    body.partner .table thead tr {
      background-color: #75AAAA; }
      body.partner .table thead tr * {
        color: #fff; }
    body.partner .table thead th {
      border-top: 1px solid #fff; }
      body.partner .table thead th.sub_1 {
        background-color: #7AB9CB; }
      body.partner .table thead th.sub_2 {
        background-color: #5472A8; }
      body.partner .table thead th.sub_3 {
        background-color: #A5A6AC; }
