@charset "utf-8";

@import "common/variables";
@import "common/mixins";
@import "common/extends";

@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP:300,400&display=swap);

$sp: 460px;
@mixin sp {
  @media (max-width: ($sp)) {
    @content;
  }
}
main div.with_new_year_card {
  font-family: 'Noto Sans JP', sans-serif;
  background-color: #fff;
  padding-top: $header-height;
  .sub_header{
    img{
      height: 20px;
    }
  }
  .content {
    padding: $subheader-height 0 0;

    .main_visual{
      img{
        display: block;
        width: 100%;
        margin-bottom: 2em;
      }
    }
    img.discount{
      display: block;
      width: 95%;
      margin: 0 auto 50px;
    }

    .coupon_area {
      padding: 10px;
      .coupon {
        margin: 0 auto;
        max-width: 480px;
        width: 100%;
        .flex {
          border: 2px #bf2b25 solid;
          background: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          .flex_left {
            background: #bf2b25;
            width: 100%;
            p {
              padding: 15px 10px;
              color: #fff;
              text-align: center;
              font-size: 14px;
              span {
                font-size: 12px;
              }
            }
          }
          .flex_right {
            width: 100%;
            text-align: center;
            height: auto;
            p {
              font-size: 18px;
            }
          }
        }
        p.memo {
          font-size: 11px;
          padding: 3px 0;
        }
      }
    }
    .address_print{
      padding: 0 10px;
      display: flex;
      align-items: flex-start;
      max-width: 480px;
      width: 100%;
      margin: 50px auto;
      .flex_left{
        width: 70%;
        h3{
          color: #d42d26;
          font-size: 24px;
          line-height: 1.2em;
          font-weight: normal;
          padding-top: 30px;
          @include sp{
            padding-top: 0;
            font-size: 18px;
          }
        }
        p{
          padding-top: 10px;
          font-size: 16px;
          letter-spacing: 0.1em;
          line-height: 1.5em;
          @include sp{
            font-size: 14px;
          }
          a{
            color: #cf9e17;
            font-weight: bold;
          }
        }
      }
      .flex_right{
        width: 30%;
      }
    }
    section{
      padding: 0 0 10px;
      h3{
        background: #c7341e;
        font-weight: normal;
        color: #fff;
        text-align: center;
        padding: 20px 0;
        font-size: 24px;
        letter-spacing: 0.1em;
        @include sp{
          font-size: 18px;
        }
      }
      h4{
        padding: 20px 10px 0;
        max-width: 480px;
        width: 100%;
        margin: 0 auto;
      }
      p{
        padding: 20px 10px;
        max-width: 480px;
        width: 100%;
        margin: 0 auto;
        span{
          color: #bf2b25;
        }
      }
      p.date{
        color: #bf2b25;
        padding: 5px 10px;
        font-weight: bold;
      }
      img{
        display: block;
        width: 90%;
        max-width: 460px;
        margin: 0 auto;
      }
      hr{
        margin: 30px auto;
        max-width: 600px;
        width: 90%;
        border-bottom: 4px dotted #bf2b25;
      }
      ul{
        padding: 10px 10px 20px 30px;
        max-width: 480px;
        width: 100%;
        margin: 0 auto;
        font-size: 10px;
      }
    }

    section.flow{
      background: #f4e8e8;
    }
    section.contact{
      background: #c7341e;
      font-weight: normal;
      color: #fff;
      text-align: center;
      font-size: 12px;
      letter-spacing: 0.1em;
      p{
        padding: 5px 10px 0;
      }
      a{
        display: block;
        margin: 5px auto;
        width: 90%;
        max-width: 350px;
        text-align: center;
        padding: 5px 20px;
        color: #fff;
        border-radius: 5px;
        font-size: 12px;
        position: relative;
        border: none;
        &:after {
          font-family: ionicons;
          content: "\f3d3";
          position: absolute;
          display: block;
          font-size: 16px;
          top: 6px;
          right: 6px;
        }
      }
    }


    a.button{
      display: block;
      margin: 30px auto;
      width: 90%;
      max-width: 380px;
      text-align: center;
      padding: 15px 20px;
      color: #fff;
      border-radius: 10px;
      font-size: 20px;
      position: relative;
      border: 3px solid #fff;
      &:after {
        font-family: ionicons;
        content: "\f3d3";
        position: absolute;
        display: block;
        font-size: 23px;
        top: 15px;
        right: 11px;
      }
    }
    a.btn_yellow{
      background: #cf9e17;
    }
    a.btn_red{
      background: #bf2b25;
    }
  }
}
