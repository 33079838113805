@charset "utf-8";

@import "common/variables";
@import "common/mixins";
@import "common/extends";

.manage_notification {
  main{
    padding-top: $subheader-height;
  }
  ul.notification_list {
    li {
      border-bottom: 1px solid $border-color;
      list-style: none;

      a {
        display: block;
        padding: 1em 2em 1em 1em ;
        position: relative;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;

        i.ion-ios-arrow-forward {
          position: absolute;
          right: 1em;
        }
      }
    }
  }
  .manage_header a.view_invitation {
    display:none;
  }
}