@charset "utf-8";

@import "common/variables";
@import "common/mixins";
@import "common/extends";

.manage_payment {
  padding-top:$subheader-height;
  background-color: #fff;
  .manage_payment_wrapper{
    margin-top:3em;
    text-align: center;
    .manage_payment_blk{
      margin:0 auto 5em auto;
      max-width: 500px;
      width: 100%;
      h3{
        margin-bottom: 2em;
        color: #002556;
        text-align: center;
        font-size: 1.5em;
        line-height: 1;
        @media screen and (max-width: 700px) {
          line-height: 2;
          font-size: 1em;
        }
        span{
          padding: 0 0.3em 0.3em 0.3em;
          background: url("/images/easy_payment05.png") bottom left repeat-x;
          background-size: 17px auto;
        }
      }
      img{
        max-width: 590px;
        width: 100%;
      }
      p{
        padding: 2em 0 0 0;
      }
      .manage_payment_l{
        float: left;
        width: 25%;
        margin-right: 5%;
        margin-bottom: 3em;
      }
      .manage_payment_r{
        margin-bottom: 3em;
        float: left;
        width: 70%;
        text-align: left;
        &:nth-last-child(3){
          margin-bottom: 2em;
        }
        h4{
          padding-left: 0.5em;
          color: #ff675b;
          font-weight: bold;
          font-size: 1.3em;
          border-left:7px solid #ff675b ;
          @media screen and (max-width: 700px) {
            font-size: 1em;
          }
        }
        p{
          padding: 0.5em 0 0 0;
        }
      }
      .alert_payment {
        padding: 2em 0 0 0;
        font-size: $font-s;
        a {
          text-decoration: underline;
        }
      }
      .campaign_box .campaign_img {
        display: block;
        margin: 0 auto;
        width: 300px;
        @media screen and (max-width: 700px) {
          &{
            margin: 0 auto;
            width: 70%;
          }
        }
      }
      .campaign_box p {
        font-weight: bold;
        padding: 1em 0 0.5em;
        text-align: center;
      }
      .credit_message{
        font-size: 11px;
        margin: 1em 0;
        padding: 1em;
        background-color:#FFF5EE;
        text-align: left;
        span{
          font-weight: bold;
        }
        ul{
          margin: 1em;
        }
      }
    }

    .manage_payment_btn{
      position: fixed;
      padding: 10px 4%;
      width: 100%;
      bottom: 0;
      left: 0;
      background-color: rgba( 255, 255, 255, 0.7 );
      z-index: 20;
      a{
        margin:0 auto;
        font-size: 1em;
        font-weight: bold;
        position: relative;
        i{
          position: absolute;
          right: 1em;
          font-size: 20px;
        }
      }
    }
    .manage_payment_menu{
      font-size: 13px;
      ul{
        list-style: none;
        li{
          width: 48%;
          float: left;
          border: 1px solid #e5e5e5;
          border-radius: 7px;
          box-shadow: 0 3px 6px -3px #e5e5e5;
          &:nth-child(2){
            float: right;
          }
          a{
            display: block;
            padding: 1.5em 0;
            color: #002556;
            i{
              color: #ff7568;
              display: block;
              font-size: 60px;
              margin: 0 auto 10px auto;
            }
          }
        }
      }
    }
  }
  .manage_payment_wizard_link{
    padding: 3em 0;
    text-align: center;
    a{
      font-size: 15px;
      color: #ff7568;
      i{
        padding-right: 5px;
        font-size: 20px;
      }
    }
  }
}