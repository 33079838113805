@charset "utf-8";

// --------------------
// mixinのスタイル定義
//
// ※ mixin以外のスタイルは書かないこと！
// --------------------
@import "variables";

@mixin clearfix() {
  &:after {
    display: table;
    clear: both;
    content: '';
  }
}
