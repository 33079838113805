@charset "utf-8";

@import "common/variables";
@import "common/mixins";
@import "common/extends";

.user__verify {
  #container{
    max-width: $area-max-width !important;
  }
  main {
    padding-top:$subheader-height;
  }
}