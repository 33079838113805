@charset "utf-8";

@import "common/variables";
@import "common/mixins";
@import "common/extends";


$pc: 1024px; // PC
$tab: 768px; // タブレット
$sp: 500px;  // スマホ

@mixin pc {
  @media (max-width: ($pc)) {
    @content;
  }
}
@mixin tab {
  @media (max-width: ($tab)) {
    @content;
  }
}
@mixin sp {
  @media (max-width: ($sp)) {
    @content;
  }
}

main div.partners_andtricot {
  background-color: #fff;
  padding-top: $header-height;

  .title_back_img{
    width: 100%;
    height: 300px;
  }

  section.main_visual{
    background:url("/images/andtricot/andtricot_img_1.jpeg") center center;
    background-size: cover;
    height:600px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    h2{
      font-family: 'Sawarabi Mincho', sans-serif;
      color:#fff;
      text-align: center;
      font-size:40px;
      letter-spacing: .1em;
      line-height: 1.2em;
      @include sp{
        font-size:35px;
      }
    }
  }

  section.concept{
    h1{
      font-family: 'Sawarabi Mincho', sans-serif;
      font-size: 48px;
      color:#000;
      letter-spacing: .1em;
      line-height: 1.4em;
      margin:-260px 0 0 40px;
      @include sp{
        font-size: 30px;
      }
    }
    p{
      font-family: 'Sawarabi Mincho', sans-serif;
      color:#000;
      font-weight: 500;
      line-height: 2.5;
      margin:40px 40px 80px 40px;
      font-size:$font-l;

    }
  }

  section.feature{
    font-family: 'Sawarabi Mincho', sans-serif;
    color:#000;
    font-weight: 500;
    line-height: 2.5;
    font-size:$font-l;
    img{
      width: 100%;
      max-height: 620px;
    }
    h2{
      font-family: 'Sawarabi Mincho', sans-serif;
      font-size: 48px;
      color:#000;
      letter-spacing: .1em;
      line-height: 1.4em;
      margin:-260px 0 0 40px;
      @include sp{
        font-size: 30px;
      }
    }
    p{
      margin:20px 40px 10px 40px;
    }
    h4{
      margin:40px 40px 10px 40px;
      font-weight: normal;
    }
    h3{
      font-family: serif;
      text-align: center;
      margin: 20px 0 5px 0;
      font-size: 40px;
      @include sp{
        font-size: 30px;
      }
    }
    .products{
      display: grid;
      grid-template-columns: 1fr 1fr;
      @include sp{
        grid-template-columns: 1fr;
      }

      div.product_img{
        height: 600px;
        background-size: cover;
        background-position: center center;
        grid-column: span 1;
        grid-row: span 1;
      }
      div.product_img_1 {
        background-image: url("/images/andtricot/andtricot_img_3.jpeg");
        order: 1;
      }
      div.product_description_1{
        order: 2;
      }
      div.product_img_2 {
        background-image: url("/images/andtricot/andtricot_img_4.jpeg");
        order: 4;
        @include sp{
          order:3;
        }
      }
      div.product_description_2{
        order: 3;
        @include sp{
          order:4;
        }
      }
      div.product_img_3 {
        background-image: url("/images/andtricot/andtricot_img_5.jpeg");
        order: 4;
      }
      div.product_description_3{
        order: 6;
      }

      div.product_description{
        grid-column: span 1;
        grid-row: span 1;
        display: flex;
        justify-content: center;
        flex-direction: column;
        .description_wrapper{
          @include sp{
            padding: 40px 0;
          }

          h3{
            text-align: left;
            color: #212121;
            font-size: 24px;
            font-weight: 500;
            line-height: 1.4;
            letter-spacing: 0em;
            margin: 0 40px 10px 40px;
          }
          p{
            line-height: 1.5;
            color: #424242;
            letter-spacing: 0em;
            @include tab{
              font-size:$font-m;
            }
            @include sp{
              font-size:$font-l;
            }
          }
        }
      }
    }
  }


  section.flow{
    h2{
      font-family: 'Sawarabi Mincho', sans-serif;
      text-align: center;
      color: #212121;
      font-size: 36px;
      margin: 40px 0 60px 0;
    }
    .flows{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      margin: 0 5px;
      @include tab{
        grid-template-columns: 1fr 1fr;
      }
      @include sp{
        grid-template-columns: 1fr;
      }

      .flow_item{
        grid-column: span 1;
        grid-row: span 1;
        padding: 5px ;
        div{
          height: 220px;
          max-width: 190px;
          margin: 10px auto;
          background-size: cover;
          background-position: center center;
        }
        .flow_img_1{
          background-image:url("/images/andtricot/andtricot_img_6.png");
        }
        .flow_img_2{
          background-image:url("/images/andtricot/andtricot_img_7.png");
        }
        .flow_img_3{
          background-image:url("/images/andtricot/andtricot_img_8.jpeg");
        }
        .flow_img_4{
          background-image:url("/images/andtricot/andtricot_img_9.jpeg");
        }
        p{
          font-size:$font-l;
          text-align: center;
          font-weight: 700;
          line-height: 1.4;
          margin: 0 0 5px 0;
          font-family: sans-serif;
        }
      }
    }
  }

  section.fee{
    padding: 40px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    @include tab{
      grid-template-columns: 1fr;
    }
    .fee_img{
      background:url("/images/andtricot/andtricot_img_10.png") center center;
      height: 320px;
      background-size: cover;
      grid-column: span 1;
      grid-row: span 1;
    }
    .fee_description{
      grid-column: span 1;
      grid-row: span 1;
      display: flex;
      justify-content: center;
      flex-direction: column;
      .description_wrapper{
        padding: 0 40px;
        @include tab{
          padding: 40px 30px;
        }
        h2{
          font-size: 24px;
          font-weight: 700;
          margin-bottom: 15px;
          line-height: 1.2;
          font-family: sans-serif;
        }
        p{
          line-height: 1.2;
          font-size:$font-l;
        }
      }
    }
  }

  section.contact{
    h2{
      font-family: 'Sawarabi Mincho', sans-serif;
      font-size: 48px;
      color:#000;
      letter-spacing: .1em;
      line-height: 1.4em;
      margin:-240px 0 0 40px;
      @include sp{
        font-size: 30px;
      }
    }
    div.form_wrapper {
      margin: -130px auto 0px auto;
      padding: 0 20px 100px 20px;
      width: 100%;
      max-width: 580px;
    }
  }
}
