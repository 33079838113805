@charset "utf-8";

@import "common/variables";
@import "common/mixins";
@import "common/extends";

body.account {
  main {
    padding-top:$subheader-height;

    .manage_menu {
      max-width: 760px;
      width: 100%;
      ul {
        li.link{
          padding: 0;
          a{
            padding: 1em;
          }
        }
        li {
          border-bottom: 1px solid $border-color;
          list-style: none;
          padding: 1em;
          position: relative;

          input[type="text"],
          input[type="password"] {
            margin: 1em 0;
          }

          &:first-of-type {
            line-height: 2;
            padding-top: 1em;
            padding-bottom: 1em;
          }

          .menu_account-title {
            font-size: $font-l;
            font-weight: bold;
          }

          .menu_account_mail {
            margin-right: 30px;

            a {
              text-decoration: underline;
            }
          }

          a{
            display: block;
          }

          .right_edge {
            display: inline;
            position: absolute;
            right: 10px;
          }

          .remove_line_connect {
            display: inline;
            text-decoration: underline;
          }

          .btn {
            display: block;
            height: 40px;
            line-height: 40px;
            max-width: 480px;
            width: 50%;
            margin: 10px;
            background-color: #06C755;
          }
        }
      }

      .target_invitation{
        a{
          display: block;
          padding: 1em 1.5em;
          color: $accent-color;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          i{
            padding-right: 10px;
            font-size: $font-l;
          }
        }
        border-bottom: 1px solid $border-color;
      }
    }
  }
}