body.partner .m_3 {
  margin: 16px; }

body.partner .p_3 {
  padding: 16px; }

body.partner .p_4 {
  padding: 24px; }

body.partner .mb_1 {
  margin-bottom: 4px; }

body.partner .mb_2 {
  margin-bottom: 8px; }

body.partner .mb_3 {
  margin-bottom: 16px; }

body.partner .mt_1 {
  margin-top: 4px; }

body.partner .mt_3 {
  margin-top: 16px; }

body.partner .mt_4 {
  margin-top: 24px; }

body.partner .ml_1 {
  margin-left: 4px; }

body.partner .mx_3 {
  margin-left: 16px;
  margin-right: 16px; }

body.partner .my_3 {
  margin-top: 16px;
  margin-bottom: 16px; }

body.partner .f_center_wrapper {
  display: flex;
  align-items: center;
  justify-content: center; }
