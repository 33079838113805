.btn_base {
  background-color: #ff6c52;
  border-radius: 7px;
  color: #fff;
  display: inline-block;
  text-align: center; }
  .btn_base.btn_disabled {
    background-color: #ccc;
    color: #fff;
    cursor: not-allowed; }

body.partner .form input[type=checkbox] {
  display: none;
  vertical-align: middle; }
  body.partner .form input[type=checkbox] + label {
    margin-left: 0em;
    vertical-align: bottom; }
  body.partner .form input[type=checkbox] + :before {
    margin-right: 0.7em;
    padding-left: 0.8em;
    content: "\f372";
    font-family: "Ionicons";
    font-size: 20px; }
  body.partner .form input[type=checkbox]:checked + :before {
    content: "\f373"; }

body.partner .form {
  text-align: start; }
  body.partner .form input {
    display: block; }
  body.partner .form .form_row + .form_row {
    margin-top: 8px; }
  body.partner .form .required {
    margin-left: 4px;
    font-size: 13px;
    color: #e62520; }
  body.partner .form ul.checkbox_container {
    list-style-type: none; }
    body.partner .form ul.checkbox_container li {
      margin-top: 4px; }
  body.partner .form .form_sub_header {
    margin: 8px 0 4px; }
  body.partner .form .input_large {
    padding: 12px 8px; }

body.partner .form-input-checkbox-wrapper, body.partner .form-input-radio-wrapper {
  position: relative;
  display: inline-block;
  cursor: pointer; }

body.partner .form-input-checkbox-wrapper + body.partner .form-input-checkbox-wrapper {
  margin-left: 0.5rem; }

body.partner .form-input-radio-wrapper + body.partner .form-input-radio-wrapper {
  margin-left: 0.5rem; }

body.partner .l-form__group-container--v .l-form__group.form-input-checkbox-wrapper, body.partner .l-form__group-container--v .l-form__group.form-input-radio-wrapper {
  margin-top: 0; }

body.partner .form-input-checkbox, body.partner .form-input-radio {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
  cursor: inherit; }
  body.partner .form-input-checkbox + span, body.partner .form-input-radio + span {
    display: block;
    height: 32px;
    line-height: 32px;
    padding-left: calc(18px + 0.5em); }
    body.partner .form-input-checkbox + span:before, body.partner .form-input-checkbox + span:after, body.partner .form-input-radio + span:before, body.partner .form-input-radio + span:after {
      content: '';
      position: absolute;
      top: 7px;
      left: 0;
      display: block; }
  body.partner .form-input-checkbox + div, body.partner .form-input-radio + div {
    display: block;
    border: 2px solid #e5e5e5; }
  body.partner .form-input-checkbox:checked + div, body.partner .form-input-radio:checked + div {
    border-color: #75aaaa; }

body.partner .form-input-checkbox + span:before {
  width: 18px;
  height: 18px;
  border: 1px #e5e5e5 solid;
  border-radius: 5px; }

body.partner .form-input-checkbox + span:after {
  margin: 2px 6px;
  width: 6px;
  height: 11px; }

body.partner .form-input-checkbox:checked + span:before {
  border-color: #75aaaa;
  background-color: #75aaaa; }

body.partner .form-input-checkbox:checked + span:after {
  border-width: 0 2px 2px 0;
  border-style: solid;
  border-color: #fff;
  transform: rotate(45deg); }

body.partner .form-input-radio + span:before {
  width: 18px;
  height: 18px;
  border: 1px #e5e5e5 solid;
  border-radius: 50%; }

body.partner .form-input-radio + span:after {
  width: 18px;
  height: 18px;
  transform: scale(0.5);
  border-radius: 50%; }

body.partner .form-input-radio:checked + span:before {
  border-color: #75aaaa; }

body.partner .form-input-radio:checked + span:after {
  background-color: #75aaaa; }

body.partner .form-input-checkbox:disabled + span, body.partner .form-input-radio:disabled + span {
  opacity: 0.5; }
