@charset "UTF-8";
.btn_base {
  background-color: #ff6c52;
  border-radius: 7px;
  color: #fff;
  display: inline-block;
  text-align: center; }
  .btn_base.btn_disabled {
    background-color: #ccc;
    color: #fff;
    cursor: not-allowed; }

.manage__index [data-remodal-id="create_modal"] {
  padding: 0; }

#container {
  box-shadow: 0 0 15px;
  margin: 0 auto;
  max-width: 760px;
  width: 100%;
  position: relative;
  height: auto !important;
  height: 100%;
  min-height: 100%; }

/* キャンペーンバナー */
.campaign a {
  display: block;
  padding: 3px;
  text-align: center; }
  .campaign a img {
    max-height: 44px; }

/* ヘッダー */
.manage_header.up {
  transform: translateY(-50px); }

.manage_header {
  -webkit-transition: transform 0.1s;
  -moz-transition: transform 0.1s;
  -ms-transition: transform 0.1s;
  -o-transition: transform 0.1s;
  transition: transform 0.1s;
  background-color: #fff;
  border-bottom: 1px solid #e5e5e5;
  height: 50px;
  position: fixed;
  top: 0;
  text-align: center;
  max-width: 760px;
  width: 100%;
  z-index: 100; }
  .manage_header .drawer_button {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    height: 50px;
    line-height: 50px;
    width: 50px; }
    .manage_header .drawer_button span {
      background-color: #002556;
      border-radius: 10px;
      display: block;
      height: 2px;
      position: absolute;
      left: 15px;
      transition: all 0.3s ease 0s; }
      .manage_header .drawer_button span.bar1 {
        top: 15px;
        width: 25px; }
      .manage_header .drawer_button span.bar2 {
        top: 23px;
        width: 15px;
        opacity: 1; }
      .manage_header .drawer_button span.bar3 {
        top: 31px;
        width: 25px; }
  .manage_header h1 {
    height: 50px;
    line-height: 50px; }
    .manage_header h1 img {
      height: auto;
      width: 135px; }
  .manage_header a.view_invitation {
    color: #ff6c52;
    display: block;
    font-size: 11px;
    font-weight: bold;
    height: 50px;
    line-height: 50px;
    position: absolute;
    top: 0;
    right: 0;
    padding-right: 10px; }
  .manage_header ul.wizard {
    text-align: center; }
    .manage_header ul.wizard li {
      color: #ccc;
      display: inline-block;
      font-size: 24px;
      padding: 0.5em 0;
      vertical-align: middle; }
      .manage_header ul.wizard li img {
        width: 25px; }
      .manage_header ul.wizard li i.ion-chevron-right {
        font-size: 13px;
        padding: 0 1em; }

/* フッター */
.manage_footer footer {
  background-color: #f7f7f7;
  padding: 2em 1em 120px 1em;
  width: 100%; }
  .manage_footer footer img {
    display: block;
    width: 100%;
    max-width: 300px;
    margin: 10px auto; }
  .manage_footer footer ul {
    list-style: none;
    text-align: center; }
    .manage_footer footer ul li {
      padding-top: 20px; }
      .manage_footer footer ul li i {
        display: none; }
  .manage_footer footer div.copyright {
    text-align: center; }
  .manage_footer footer div.logout {
    padding-top: 20px;
    text-align: center; }

#content {
  background-color: #fff; }
  #content .sub_header {
    transition: transform 0.1s;
    width: 100%;
    max-width: 760px;
    height: 50px;
    background-color: #fff;
    position: fixed;
    top: 0;
    z-index: 100; }
    #content .sub_header h2.title {
      border-bottom: 1px solid #e5e5e5;
      color: #002556;
      font-size: 15px;
      height: 50px;
      line-height: 50px;
      text-align: center;
      position: relative;
      font-weight: bold; }
      #content .sub_header h2.title a {
        position: absolute;
        left: 0%;
        color: #002556;
        font-size: 30px;
        padding: 0 2%; }
        @media screen and (max-width: 700px) {
          #content .sub_header h2.title a {
            padding: 0 4%; } }
  #content .sub_header.down {
    transform: translateY(50px); }
  #content h3.title {
    background-color: #f7e4e3;
    color: #002556;
    font-size: 13px;
    font-weight: bold;
    padding: 1em; }
  #content div.manage_button {
    padding: 2em 0;
    z-index: 100; }
  #content div.manage_button::after {
    content: "";
    clear: both;
    display: block; }
  #content div.fixed_space {
    height: 150px; }
  #content div.manage_button.wizard {
    position: fixed;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    max-width: 760px;
    width: 100%;
    padding: 1em; }
  #content div.manage_button.wizard .btn_back {
    position: relative;
    width: 30%; }
  #content div.manage_button.wizard .btn_back::before {
    position: absolute;
    content: "\f3d2";
    font-family: ionicons;
    left: 15px;
    font-size: 20px; }
  #content div.manage_button.wizard .btn_next {
    position: relative;
    width: 65%;
    font-weight: bold;
    float: right; }
  #content div.manage_button.wizard .btn_next::after {
    position: absolute;
    content: "\f3d1";
    font-family: ionicons;
    right: 15px;
    font-size: 20px; }
  #content div.manage_button.wizard .btn_finish {
    width: 65%;
    font-weight: bold;
    float: right; }
  #content div.manage_button.wizard .btn_skip {
    display: inline-block;
    color: #fff;
    margin-top: 1em;
    text-decoration: underline;
    font-weight: 100;
    padding: 0; }
  #content .description {
    padding: 2em 0 1em 0; }
  #content.is-wizard {
    padding-top: 50px; }

/* オススメマーク */
.mark_recommend {
  position: relative;
  overflow: hidden; }
  .mark_recommend i.mark {
    background-color: #005f9b;
    color: #fff !important;
    display: inline-block !important;
    font-size: 8px !important;
    line-height: 160%;
    padding: 1px 20px 0 20px;
    position: absolute;
    top: 9px;
    left: -20px;
    transform: rotate(-45deg); }

/* Vimeoのプレーヤー */
.vimeo_player_wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding: 0 !important;
  padding-bottom: 75% !important; }

iframe.vimeo_player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
