@charset "utf-8";

@import "common/variables";
@import "common/mixins";
@import "common/extends";

.manage_invitation, .manage_thanks_letter {
  .information {
    a {
      background-color: $background-color;
      border-bottom: 1px solid #ccc;
      display: block;
      padding: 1em 2em 1em 1em ;
      position: relative;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;

      i.ion-ios-arrow-forward {
        position: absolute;
        right: 1em;
      }
    }
    a.alert {
      color: #e56473;
    }
  }

  .menu_recommend_upgrade {
    background-color: #F7F7F7;
    font-size: $font-m;
    font-weight: 500;
    padding: 3em 1em 0;
    text-align: center;

    div.postponed {
      font-size: $font-s;
      font-weight: 600;
      border: 1px solid $primary-color;
      border-radius: 5px;
      padding: 16px;
      margin: 16px 0;
      p{
        font-weight: 400;
        color: #333;
        text-align: left;
      }
    }

    a {
      display: inline-block;
      font-size: $font-m;
      margin-top: 1em;
      color: $primary-color;
      text-decoration: underline;
    }
  }

  .this_is_standard {
    background-color: $background-color;
    border-bottom: 1px solid #ccc;
    padding: 1em;
    text-align: center;
    width: 100%;
  }

  .header-tab {
    display: flex;
    overflow-x: auto;
    height: 48px;
    margin-bottom: 2px;
    &::-webkit-scrollbar {
      display:none;
    }
  }
  .header-tab-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-width: 150px;
    padding: 8px;
    line-height: 1.2em;
    text-align: center;
    border: 0;
    border-bottom: 1px solid $border-color;
    font-size: 12px;

    @media screen and (max-width: 768px) {
      min-width: 30.7%;
    }

    label {
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      cursor: pointer;
    }

    &.active {
      border-width: 2px;
      border-color: $primary-color;
      font-weight: bold;
    }

    .cheers {
      font-size: 10px;
      color: #f90081;
      &:before, &:after {
        content: '|';
        display: inline-block;
        font-size: $font-m;
      }
      &:before {
        margin-right: 3px;
        transform: rotate(-25deg);
      }
      &:after {
        margin-left: 3px;
        transform: rotate(25deg);
      }
    }
  }

  .eyecatch {
    background-size: cover;
    background-position: center center;
    height: 200px;

    .eyecatch_content{
      height: 200px;
      width: 100%;
      background: rgba(0,0,0,0.35);
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      padding: 16px;
      position: relative;
      .eyecatch_texts{
        width: 100%;
      }
      .invitation_title {
        max-width: 280px;
        margin: 0 auto;
        color: #fff;
        font-size: 24px;
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding: 0.5em;
        width: 100%;
        letter-spacing: 0.1em;
      }
      .thanks_letter_title {
        max-width: 280px;
        margin: 53px auto 0;
        color: #fff;
        font-size: 24px;
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        padding: 0.5em;
        width: 100%;
        letter-spacing: 0.1em;
      }
      .invitation_date {
        text-align: center;
        color: #fff;
        letter-spacing: 0.1em;
        span{
          font-size: 24px;
        }
      }
      .countdown_link{
        color: #fff;
        border: 1px solid #fff;
        border-radius: 30px;
        padding: 5px 30px;
        display: block;
        margin: 15px auto 0;
        max-width: 200px;
        position: relative;
        transition:all 0.2s;
        backdrop-filter: blur(30px);
        background: rgba(255,255,255,0.2);
        i{
          position: absolute;
          top: 20%;
          right: 10px;
        }
        &:hover{
          background: #fff;
          color: #333;
        }
      }
    }
  }
  .main_buttons{
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    a{
      font-size: 14px;
      background-color: $primary-color;
      border-radius: 5px;
      color: #fff;
      width: 100%;
      padding: 10px;
      margin: 0 8px;
      display: block;
      text-align: center;
      border: 1px solid rgba(255,255,255,0.6);
    }
  }

  .menu_list {
    font-size: $font-m;
    padding: 7px 16px 24px;
    max-width: 600px;
    margin: 0 auto;

    ul {
      @include clearfix;
      display: flex;
      flex-wrap: wrap;
      list-style: none;

      li {
        display: block;
        width: 25%;

        p{
          text-align: center;
          padding: 2.8em 0;
          position: relative;
        }

        a {
          color: #333;
          display: block;
          padding: 12px 0 13px;
          position: relative;
          font-size: $font-s;
          text-align: center;
          overflow: hidden;
          line-height: 1.4;

          img{
            display: block;
            width: 40px;
            margin: 4px auto 0;
          }

          .badge {
            background-color: #005f9b;
            border-radius: 50%;
            color: #fff;
            display: inline-block;
            font-size: $font-s;
            height: 1.5em;
            line-height: 1.5em;
            text-align: center;
            vertical-align: text-top;
            width: 1.5em;
            position: absolute;
            top: 16px;
            right: 12%;
          }
        }
      }
    }
    .space{
      height: 17px;
      border-top: 1px solid #E5E5E5;
    }
  }
  .menu_list.thanks_letter {
    padding-bottom: 60px;
    ul>li {
      width: 33%;
    }
  }
  #banner_area{
    padding: 0 20px 10px;
  }
  #banner_area a{
    display: block;
    width: 100%;
    margin: 0 auto 10px;
    max-width: 500px;
  }
  #banner_area a img{
    display: block;
    width: 100%;
    margin: 0 auto;
    max-width: 500px;
  }

  .menu_bottom_banner .fix_banner{
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    position: fixed;
    padding-bottom: 15%;
    height: 0;
    overflow: hidden;
    iframe {
      position: absolute;
      margin: 0 auto;
      width: 100%;
      height: 100%;
      border: 0;
    }
   }
  @media screen and (min-width: (500px)) {
    .menu_bottom_banner .fix_banner {
      padding-bottom: 75px;
    }
  }
  .fix-manage-footer{
    height: 74px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    max-width: 760px;
    margin: 0 auto;
    box-shadow: 0px -3px 10px 0px rgba(0,0,0,0.1);
    transition: bottom 0.3s ease-out;
    ul{
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      list-style: none;
      max-width: 600px;
      margin: auto;
      li{
        width: 25%;
        a{
          color: #afafaf;
          height: 100%;
          display: flex;
          flex-flow: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          span{
            display: block;
            text-align: center;
          }
          span.footer-icon{
            width: 24px;
            height: 24px;
            &.invitation-icon{
              background-image: url("/images/footer_menu/invitation_gray.svg");
            }
            &.seatinglist-icon{
              background-image: url("/images/footer_menu/seatinglist_gray.svg");
            }
            &.thanks-letter-icon{
              background-image: url("/images/footer_menu/thankyou_gray.svg");
            }
            &.account-icon{
              background-image: url("/images/footer_menu/account_gray.svg");
            }
          }
          span.footer-text{
            font-size: 11px;
            line-height: 1.2;
            margin-top: 2px;
          }
        }
        &.active{
          a{
            color: $primary-color;
            span.footer-icon{
              &.invitation-icon{
                background-image: url("/images/footer_menu/invitation_primary.svg");
              }
              &.thanks-letter-icon{
                background-image: url("/images/footer_menu/thankyou_primary.svg");
              }
            }
          }
        }
      }
    }
  }
  .payment_banner {
    text-align: center;
    padding-top: 2em;
    img {
      width: 500px;
    }
  }
  .invitation-action-modal {
    .remodal-body {
      padding: 0 40px;
    }
    .remodal-footer .btn-container {
      padding: 40px;
    }
  }
  .invitation-modal-btn {
    display: block;
    margin: 2rem auto 0;
    width: 100%;
    max-width: 300px;
    color: $accent-color;
    background-color: #fff;
    border: 1px solid $accent-color;
  }
  .invitation-action {
    display: flex;
    border-bottom: 1px solid $border-color;
    padding: 1rem 0;
    &:first-child {
      padding-top: 0;
    }
    &:last-child {
      padding-bottom: 0;
      border-bottom: 0;
    }
  }
  .invitation-action__image-wrapper {
    position: relative;
    width: 40%;

    &:after {
      content: "";
      display: block;
      padding-bottom: 60%;
    }
  }
  .invitation-action__image {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.7;
    background-size: cover;
    background-position: center;
  }
  .invitation-action__content {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    padding-top: 0.25rem;
    padding-left: 8px;
    text-align: left;
    font-size: $font-l;

    label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc(100% - 24px);
      display: inline-block;
      vertical-align: bottom;
    }
    a i {
      margin-left: 4px;
      color: $primary-color;
    }
    small {
      font-size: $font-s;
    }
    .btn-container {
      display: flex;
      .button {
        width: 50%;
        height: 32px;
        line-height: 32px;
        font-size: $font-m;
        border-radius: 5px;
        + .button {
          margin-left: 0.5rem;
        }
      }
      .delete-btn {
        color: #333333;
        background: #EBEBEB;
      }
    }
  }

  // vuxのoverride
  .vex.vex-theme-os {
    display: flex;
    align-items: center;
    .vex-content {
      border: none;
      border-radius: 1rem;
      background: #fff;
      padding: 40px 24px;
      max-width: 400px;
      font-family: 'Noto Sans JP', sans-serif;;
      .vex-dialog-form {
        .change-invitation-name {
          font-weight: 600;
          font-size: 16px;
        }
        .vex-dialog-message {
          text-align: left;
          margin-bottom: 0;
          font-size: 14px;
          span.error_msg {
            font-size: 12px;
            margin: 12px auto;
            display: inline-block;
          }
        }
        .vex-dialog-input input {
          font-size: 16px;
          border: 1px solid #afafaf;
          border-radius: 3px;
          margin: 0;
          height: 48px;
          &:focus {
            outline: 1px solid $primary-color;
            box-shadow: none;
          }
        }
      }
      .vex-dialog-buttons {
        display: flex;
        flex-direction: row-reverse;
        margin-top: 24px;
        .vex-dialog-button {
          font-size: 14px;
          width: 100%;
          flex-grow: 1;
          height: 48px;
          padding: 0;
        }
        .vex-dialog-button-primary {
          background: #FF6C52;
          margin-left: 8px;
        }
        .vex-dialog-button-table-primary {
          color: #fff;
          background: #4C5870;
          margin-left: 8px;
          animation: none;
        }
        .vex-dialog-button-secondary {
          color: #333;
          background-color: #EBEBEB;
        }
        .vex-dialog-button-inactive {
          background-color: #CCCCCC;
          margin-left: 8px;
          pointer-events: none;
          color: #fff;
        }
      }
    }
  }

  .thanks-letter-created-modal {
    padding: 0;
    overflow-y: scroll;
    .remodal-body {
      padding: 0 24px;
      overflow-y: visible;
    }
    h2 {
      margin-bottom: 24px;
      font-size: $font-xl;
      font-weight: 500;
    }
    .remodal-footer .btn-container {
      padding: 40px;
    }
  }
  .thanks-letter-created-modal{
    h2{
      font-size: 19px;
      margin-bottom: 24px;
      font-weight: 500;
    }
    img{
      display: block;
      width: 100%;
      max-width: 280px;
      margin: 24px auto;
    }
  }
  .postponed_modal{
    .postponed_modal_buttons{
      display: flex;
      justify-content: space-around;
      a.btn{
        margin: 24px 5px 10px;
      }
      a.cancel{
        color: #333333;
        background: #EBEBEB;
      }
    }
  }
  .remodal.web-table-modal {
    border-radius: 0;

    h2 {
      margin-bottom: 12px;
      font-size: 18px;
      font-weight: 700;
      line-height: 1.5;
    }
    p {
      font-size: 14px;
    }

    .remodal-body {
      padding: 0 16px 64px;
    }

    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      margin: 0 8px;
      background: #ccc;
      opacity: 1;
    }
    .swiper-pagination-bullet-active {
      background: #d1aea5;
    }
    .price-section {
      margin-top: -8px;
      padding: 36px 24px 24px;
      box-shadow: 0 0 10px rgba(0,0,0,0.3);

      .button {
        width: 100%;
        margin-top: 24px;
        color: #fff;
        background-color: #4C5870;
        border-color: #4C5870;
      }
      .notice {
        margin-top: 12px;
        font-size: 11px;
      }
    }
    .price {
      display: flex;
      align-items: center;
      justify-content: center;
      strong {
        display: flex;
        align-items: flex-end;
        margin-right: 4px;
        line-height: 1;
        small {
          font-size: 18px;
        }
        p {
          margin-left: 4px;
          font-size: 27px;
        }
      }
    }
    .cancel-link {
      display: block;
      margin-top: 12px;
      font-size: 14px;
    }
  }
  .web-table-modal-swiper {
    margin: 12px 0 8px;
    padding-bottom: 40px;
  }
  .btn-container{
    a.button.close{
      border: 2px solid $primary-color;
      background: #fff;
      color: $primary-color;
    }
    p{
      margin-top: 16px;
      text-align: center;
    }
  }
}

.manage_thanks_letter {
  .remodal-overlay {
    background: rgba(0,0,0,0.2);
  }
  .remodal-wrapper {
    display: flex;
    align-items: center;
    padding: 16px;
  }
}
