@charset "utf-8";

// --------------------
// 継承元のスタイル定義
//
// ※ %記法以外のスタイルは書かないこと！
// --------------------
@import "variables";
@import "mixins";

// ボタン
.btn_base {
  background-color: $primary-color;
  border-radius: 7px;
  color: #fff;
  display: inline-block;
  text-align: center;

  &:hover{

  }

  &.btn_disabled {
    background-color: $disabled-color;
    color: #fff;
    cursor: not-allowed;
  }
}

%common_checkbox {
  display: none;
  vertical-align: middle;
  &+label{
    margin-left: 0em;
    vertical-align: bottom;
  }
  &+:before{
    margin-right: 0.7em;
    padding-left: 0.8em;
    content: "\f372";
    font-family: "Ionicons";
    font-size:20px;
  }
  &:checked+:before{
    content: "\f373";
  }
}
