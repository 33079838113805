@charset "utf-8";

@import "common/variables";
@import "common/mixins";
@import "common/extends";

body.manage_cancel_payment {
  .manage_cancel_payment_content{
    padding: 6em 1em;
    h3{
      text-align: center;
      font-size: $font-l;
      margin: 0 auto 1em;
    }
    ul{
      background: #F7F7F7;
      padding: 1em 1em 1em 2em;
      margin: 1em auto;
    }
    ul>li{
      color: #002556;
      font-size: $font-s;
    }
    .fee{
      font-weight: 600;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #999999;
      margin: 2em 0 1em;
      padding: 1em 1em 0.5em;
      font-size: $font-l;
    }
    p.attention{
      border: 2px solid #E56473;
      border-radius: 5px;
      background: #FFF5EE;
      padding: 1em;
      color: #E56473;
      font-size: $font-s;
    }

    form{

      margin: 2em 0;
      label.list-label{
        padding: 2em 0 .5em;
        display: block;
        font-size: $font-l;
        font-weight: 600;
      }
      .card-list{
        margin-bottom: 0.5em;
        padding-left: 1em;
        img{
          height: 20px;
        }
      }
      .limit-date-container select{
        max-width: 100px;
      }
      .security_code_wrapper input{
        max-width: 100px;
      }
      .validate_error{
        color: #E56473;
        font-size: $font-s;
        font-weight: 600;
      }
      .error_message{
        border: 1px solid #E56473;
        border-radius: 6px;
        background: #fff;
        text-align: center;
        padding: 1em;
        color: #E56473;
        font-size: $font-s;
        margin: 2em auto;
      }
      button.submit{
        width: 90%;
        margin: 0 auto;
        display: block;
        height: 50px;
        background-color: #ff7568;
        border-radius: 7px;
        color: #fff;
        text-align: center;
      }
      button.submit.disable{
        background-color: #CCCCCC;
      }
    }
  }
  .manage_cancel_payment_finish{
    padding: 6em 1em;
    h3{
      text-align: center;
      font-size: $font-l;
      margin: 0 auto 1em;
    }
    p{
      text-align: center;
      margin: 0 auto 2em;
    }
  }
}
