@import "./common/variables";

// --------------------
// Remodal
// --------------------

// --------------------
// Component Variables
// --------------------
$remodal-header-height: 40px !default;
$remodal-header-title-font-size: $font-l !default;;
$remodal-header-close-button-font-size: 16px !default;;

// --------------------
// Styles
// --------------------

// Remodalのオーバーライド用レイアウト
.l-remodal {
  &.remodal {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    padding: 0;
  }
}

// 画面にフィットしたサイズのRemodal
.remodal-screen-fit {
  &.remodal-wrapper {
    padding: 1em 10px;
    overflow: hidden;
  }
  &.remodal {
    max-height: 100%;
  }
  .remodal-body {
    flex-grow: 1;
    overflow-y: auto;
  }
}

// 全画面サイズのremodal
// jsのmodifierオプションで付与すること（.remodal, .remodal-overlay, .remodal-bg, .remodal-wrapper全てにmodifierのクラスが付与される）
.remodal-full-height {
  &.remodal-overlay {
    z-index: 999;
  }
  &.remodal-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    z-index: 1000;
  }
  &.remodal {
    // スマホの場合アドレスバーによって100vhがずれるバグがあるため、下記対応とする
    // height: 100vh;
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: $area-max-width;
    margin: 0;
    padding: 0;
    border-radius: 0;
  }
  .remodal-body {
    padding: ($global-space * 1.5);
  }

  // ヘッダー付きのボディ
  .remodal-header + .remodal-body {
    height: calc(100% - #{$remodal-header-height});
    overflow-y: auto;
  }
}

// Remodal内のヘッダー
.remodal-header {
  position: relative;
  width: 100%;
  height: $remodal-header-height;
  border-bottom: 1px solid $border-color;

  // ヘッダー内の閉じるボタン
  .remodal-header-close-button {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: $remodal-header-height;
    height: $remodal-header-height;
    min-height: 0;
    border: none;
    outline: none;
    background-color: transparent;
    text-decoration: none;
    cursor: pointer;
    font-size: $remodal-header-close-button-font-size;
    color: inherit;
  }

  // ヘッダー内のタイトル
  .remodal-header-title {
    height: $remodal-header-height;
    line-height: $remodal-header-height;
    text-align: center;
    font-weight: bold;
    font-size: $remodal-header-title-font-size;
    color: $accent-color;
  }
}

// Remodal内のフッター
.remodal-footer {
  .button-container {
    display: flex;
    padding: 0.5em;

    .btn + .btn {
      margin-left: 0.5em;
    }
  }
}

// 新しいremodalのベースとなるクラス
.remodal-base {
  &.remodal-overlay {
    background: rgba(0, 0, 0, 0.2);
  }
  &.remodal-wrapper {
    display: flex;
    align-items: center;
    padding: 20px;
  }
  &.remodal {
    max-width: 400px;
    min-height: 480px;
    max-height: 100%;
    border-radius: 1rem;
    box-shadow: 0px 1px 10px 5px rgba(0,0,0,0.125);
  }
  .remodal-header {
    flex-shrink: 0;
    border: 0;
  }
  .remodal-body {
    flex-grow: 1;
    overflow-y: auto;
  }
  .remodal-footer {
    flex-shrink: 0;

    .btn-container {
      display: flex;
      flex-direction: column;
      padding: 1.5rem;

      .modal-btn + .modal-btn {
        margin-top: 0.5rem;
      }
    }
  }
}
.modal-btn {
  font-size: $font-m;

  &.--accept {
    color: $primary-color;
    border: 2px solid $primary-color;
  }
}
.remodal-close {
  right: 0;
  left: auto;
}
