body.partner .partner_container {
  display: flex; }
  body.partner .partner_container .sidebar {
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto; }
  body.partner .partner_container .main_content {
    flex-grow: 1;
    height: 100vh;
    overflow: auto;
    position: relative; }
