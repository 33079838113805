body.partner_contact .main_content {
  background: #F5F5F5; }
  body.partner_contact .main_content .content {
    padding: 60px;
    max-width: 840px;
    margin: 50px auto;
    text-align: center; }
    body.partner_contact .main_content .content h2 {
      font-size: 20px;
      text-align: center;
      margin-bottom: 50px; }
