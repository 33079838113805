@charset "utf-8";

@import "common/variables";
@import "common/mixins";
@import "common/extends";

.admin_common {
  // --------------------
  // ヘッダー
  // --------------------
  .manage_header {
    max-width:100%;
  }

  #container {
    max-width:100%;
  }

  #content {
    h2.title {
      margin-bottom: 0em;
    }
  }

  main {

    h3 {
      background-color: $title-background-color;
      color: #fff;
      padding: 0.5em 1em;
    }

    // --------------------
    // 検索条件
    // --------------------
    .search_conditions {
      border-bottom: 1px solid $border-color;
      padding: 10px;

      table {
        width:100%;
        tbody {
          tr {
            height:45px;
            th {
              width:10%;
              padding:4px;
              border: 1px solid #e0e0e0;
              background-color: $background-color;
              text-align:right;
            }
            td {
              width:23%;
              padding:4px;
              border: 1px solid #e0e0e0;
            }
          }
        }
      }
      .btn-search {
        @extend .btn_base;
        height:40px;
        width:80px;
        line-height: 40px;
        margin:5px 5px 0px 0px;
      }
    }

    // --------------------
    // 一覧用テーブル
    // --------------------
    .common_list {
      padding: 0 1em;

      table {
        margin: 1em 0;
        thead {
          tr {
            th {
              background-color: $background-color;
              border: 1px solid #e0e0e0;
            }
          }
        }
        tbody {
          tr {
            height:45px;
            td {
              padding:4px;
              border: 1px solid #e0e0e0;
            }
          }
          tr.cursor_pointer {
            cursor: pointer;
          }
        }
      }
      // ページャー
      .pager {
        margin:5px;
        .btn-pager {
          @extend .btn_base;
          height:40px;
          width:40px;
          line-height: 40px;
        }
      }
    }

    // --------------------
    // 詳細用テーブル
    // --------------------
    .detail {
      border-bottom: 1px solid $border-color;
      padding: 10px;

      table {
        width:100%;
        tbody {
          tr {
            height:45px;
            th {
              width:10%;
              padding:4px;
              border: 1px solid #e0e0e0;
              background-color: $background-color;
              text-align:right;
            }
            td {
              width:23%;
              padding:4px;
              border: 1px solid #e0e0e0;
            }
          }
        }
      }
      .btn {
        @extend .btn_base;
        height:40px;
        width:80px;
        line-height: 40px;
        margin:5px 5px 0px 0px;
      }
    }
  }
}
