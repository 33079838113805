body.partner .card {
  background: #fff;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
  border-radius: 4px; }

body.partner .card--flat {
  box-shadow: none;
  border: 1px solid #e5e5e5; }

body.partner .card_logo img {
  height: auto;
  width: 160px; }

body.partner .card_title {
  margin: 16px 0; }

body.partner .card_header {
  padding: 8px 12px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #e5e5e5; }

body.partner .card_body {
  padding: 16px; }
