@charset "utf-8";

@import "common/variables";
@import "common/mixins";
@import "common/extends";

.manage_share_detail {
  main {
    padding-top: $subheader-height;
  }

  .content.show_qr{
    img{
      display: block;
      width: 100%;
      max-width: 250px;
      margin: 20px auto 0;
    }
    p{
      text-align: center;
      padding-bottom: 50px;
    }
  }

  .content.edit_line,
  .content.edit_mail{
    padding: 1px 0;
    img{
      display: block;
      width: 80%;
      max-width: 450px;
      margin: 20px auto 10px;
    }
    p{
      font-size: 12px;
      width: 80%;
      max-width: 450px;
      padding-bottom: 50px;
      margin: auto;
    }
    p.attention{
      font-size: 10px;
      width: 100%;
      padding-bottom: 0;
      margin: auto;
    }
    p.error{
      text-align: center;
      font-weight: bold;
      color: red;
      margin-bottom: 20px;
      padding-bottom: 0;
    }
    h3{
      background: #FBE3E3;
      padding: 10px 10%;
    }
    .message_pattern{
      select{
        display: block;
        width: 80%;
        margin: 30px auto 20px;
        max-width: 680px;
      }
      textarea.message_textarea{
        display: block;
        width: 80%;
        margin: 10px auto;
        max-width: 680px;
      }
      label{
        display: none;
      }
    }
    .share_message_wrap{
      max-width: 600px;
      margin: 0 auto;
      padding: 30px 20px 10px;
      hr{
        border-top: 1px solid #ccc;
        width: 100%;
        margin: 0 auto;
      }
      h3{
        text-align: center;
      }
      .choose_example_text{
        font-size: 14px;
        display: inline-block;
        color: #FF6C52;
        text-decoration: underline;
        margin-bottom: 6px;
      }
      textarea{
        display: block;
        width: 100%;
        max-width: unset;
        height: 320px;
      }
    }
    button{
      color: #fff;
      display: block;
      padding: 15px 20px;
      background: #FF9E97;
      width: 100%;
      margin: 30px auto 50px;
      border-radius: 10px;
    }
  }
}