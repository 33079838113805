@charset "utf-8";

@import "common/variables";
@import "common/mixins";
@import "common/extends";

@import url(https://fonts.googleapis.com/css?family=Noto+Sans+JP:300,400&display=swap);

.with_novic_snap {
  font-family: "Noto Sans JP", sans-serif;
  .content{
    padding-top: $subheader-height;
  }
  #main_visual{
    width: 100%;
    min-height: 400px;
    background:url("/images/novic_snap/main_visual_background.jpg") center center;
    background-size: cover;
    padding: 1em;
    display: flex;
    justify-content: center;
    align-items: center;
    .main_visual_content{
      border: 2px solid #fff;
      color: #fff;
      text-align: center;
      max-width: 600px;
      margin: 2em auto;
      padding: 60px 2em;
      .main_visual_p{
        font-size: 14px;
        margin-bottom: 1em;
      }
      h1{
        font-size: 25px;
        font-weight: bold;
        line-height: 1.4em;
        letter-spacing: 0.1em;
      }
      .main_visual_produce{
        margin-top: 1em;
        font-size: 13px;
      }
    }
  }
  #about{
    width: 100%;
    text-align: center;
    padding: 20px;
    line-height: 1.8em;
    h3{
      font-weight: normal;
      font-size: 18px;
      color: #DC7B79;
      margin: 20px auto 20px;
    }
    p{
      font-size: 14px;
    }
    .presentedby{
      margin: 10px 0;
      font-size: 12px;
    }
  }
  #price{
    width: 100%;
    text-align: center;
    padding: 20px;
    line-height: 1.8em;
    .price_content{
      border: 1px solid #D5D5D5;
      padding: 20px 20px 50px;
      max-width: 500px;
      margin: 0 auto;
    }
    h3{
      font-weight: normal;
      font-size: 24px;
      color: #DC7B79;
      margin: 20px auto 10px;
      letter-spacing: 0.1em;

    }
    .price_sub{
      font-size: 14px;
      color: #DC7B79;
      margin: 10px auto;
      position: relative;
      span{
        position: absolute;
        z-index: 1;
        background: #fff;
        width: 100px;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
      &:before{
        width: 140px;
        z-index: 0;
        content: '';
        position: absolute;
        height: 1px;
        background: #DC7B79;
        top: 12px;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }
    .snap_price{
      margin: 50px auto 0;
      font-size: 20px;
      span{
        font-size: 14px;
      }
    }
    img{
      max-width: 250px;
      margin-top: 20px;
    }
  }
  #flow{
    background: #FCF7F7;
    .flow_visual{
      margin-top: 50px;
      background: url("/images/novic_snap/flow_image.jpg") center center;
      height: 180px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      img{
        height: 80px;
        width: 100%;
      }
    }

    h3{
      font-weight: normal;
      text-align: center;
      font-size: 24px;
      color: #DC7B79;
      margin: 20px auto 10px;
    }
    .flow_sub{
      font-size: 14px;
      color: #DC7B79;
      margin: 10px auto;
      position: relative;
      span{
        text-align: center;
        position: absolute;
        z-index: 1;
        background: #FCF7F7;
        width: 100px;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
      &:before{
        width: 140px;
        z-index: 0;
        content: '';
        position: absolute;
        height: 1px;
        background: #DC7B79;
        top: 8px;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }
    .flow_list{
      padding: 2em;
      list-style: none;
      li{
        padding: 2em;
        background: #fff;
        box-shadow: 0 0 10px #bbb;
        margin: 20px auto;
        max-width: 500px;
      }
      .flex{
        display: flex;
        align-items: center;
        img{
          width: 42px;
        }
        h4{
          font-size: 16px;
          font-weight: bold;
          margin-left: 10px;
        }
      }
      p{
        margin: 1em 0;
        font-size: 14px;
        line-height: 1.8em;
      }
    }
  }
  #gallery{
    background: #ffffff;
    padding: 3em 1em;
    h3{
      font-weight: normal;
      margin-top: 30px;
      text-align: center;
      font-size: 22px;
      color: #DC7B79;
    }
    .photo_list{
      margin: 30px auto;
      .photo{
        display: inline-block;
        width: 49%;
        padding-top: 50%;
        background-position: center center;
        background-size: cover;
        cursor: pointer;
      }
      .hidden{
        display: none;
      }
    }
    button.gallery_more {
      margin: 20px auto;
      display: block;
      width: 100%;
      max-width: 120px;
      text-align: center;
      border-radius: 50px;
      background: #DC7B79;
      color: #fff;
      padding: 14px 30px;
      font-size: 16px;
    }
  }
  #form{
    padding: 3em 2em;
    background: #F5F4F4;
    .form_content{
      padding: 2em;
      background: #fff;
      h3{
        margin-top: 30px;
        text-align: center;
        font-size: 18px;
        color: #DC7B79;
      }
      .form_sub{
        text-align: center;
        font-size: 13px;
        color: #DC7B79;
        margin: 10px;
      }
      .form_wrapper{
        max-width: 460px;
        margin: 0 auto;
      }
      input#bride_name_form{
        margin-top: 20px;
      }

      button{
        margin: 20px auto;
        display: block;
        width: 100%;
        max-width: 240px;
        text-align: center;
        border-radius: 50px;
        background: #DC7B79;
        color: #fff;
        padding: 14px 30px;
        font-size: 16px;
      }
      p{
        max-width: 400px;
        margin: 0 auto;
      }
    }
  }
}

//モーダルスタイル
body.with__novic_snap{
  .remodal{
    margin-top: 50px;
    padding:0;
  }
  .remodal-close{
    left: unset;
    right: 0;
    top: -45px;
    color: #fff;
  }
}