@charset "utf-8";

@import "common/variables";
@import "common/mixins";
@import "common/extends";

.manage__index {
  [data-remodal-id="create_modal"] {
    padding: 0;
  }
}

// --------------------
// 全体共通
// --------------------


#container {
  box-shadow: 0 0 15px;
  margin: 0 auto;
  max-width: $area-max-width;
  width: 100%;
  position: relative;
  height: auto !important;
  height: 100%;
  min-height: 100%;
}

/* キャンペーンバナー */
.campaign a{
  display: block;
  padding: 3px;
  text-align: center;
  img{
    max-height: 44px;
  }
}

/* ヘッダー */
.manage_header.up{
  transform: translateY(-$header-height);
}
.manage_header {
  -webkit-transition: transform 0.1s;
  -moz-transition: transform 0.1s;
  -ms-transition: transform 0.1s;
  -o-transition: transform 0.1s;
  transition: transform 0.1s;
  background-color: #fff;
  border-bottom: 1px solid $border-color;
  height: $header-height;
  position: fixed;
  top: 0;
  text-align: center;
  max-width: $area-max-width;
  width: 100%;
  z-index: 100;

  .drawer_button {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    height: $header-height;
    line-height: $header-height;
    width: $header-height;

    span {
      background-color: $accent-color;
      border-radius: 10px;
      display: block;
      height: 2px;
      position: absolute;
      left: 15px;
      transition: all 0.3s ease 0s;

      &.bar1 {
        top: 15px;
        width: 25px;
      }
      &.bar2 {
        top: 23px;
        width: 15px;
        opacity: 1;
      }
      &.bar3 {
        top: 31px;
        width: 25px;
      }
    }
  }

  h1 {
    height: $header-height;
    line-height: $header-height;

    img {
      height: auto;
      width: 135px;
    }
  }

  a.view_invitation {
    color: $primary-color;
    display: block;
    font-size: 11px;
    font-weight: bold;
    height: $header-height;
    line-height: $header-height;
    position: absolute;
    top: 0;
    right: 0;
    padding-right: 10px;
  }

  // ウィザード時
  ul.wizard {
    text-align: center;

    li {
      color: #ccc;
      display: inline-block;
      font-size: 24px;
      padding: 0.5em 0;
      vertical-align: middle;

      img{
        width: 25px;
      }

      i.ion-chevron-right {
        font-size: $font-m;
        padding: 0 1em;
      }
    }
  }
}

/* フッター */
.manage_footer {
  footer {
    background-color: $background-color;
    padding: 2em 1em 120px 1em;
    width: 100%;
    img {
      display: block;
      width: 100%;
      max-width: 300px;
      margin: 10px auto;
    }
    ul{
      list-style: none;
      text-align: center;
      li{
        padding-top: 20px;
        i{
          display: none;
        }
      }
    }
    div.copyright {
      text-align: center;
    }
    div.logout {
      padding-top: 20px;
      text-align: center;
    }
  }
}

// --------------------
// コンテンツ部共通
// --------------------
#content {
  background-color: #fff;
  .sub_header{
    transition: transform 0.1s;
    width: 100%;
    max-width: $area-max-width;
    height: $header-height;
    background-color:#fff;
    position: fixed;
    top: 0;
    z-index:100;
    h2.title {
      border-bottom: 1px solid $border-color;
      color: $accent-color;
      font-size: $font-l;
      height: $header-height;
      line-height: $header-height;
      text-align: center;
      position: relative;
      font-weight: bold;
      a{
        position: absolute;
        left: 0%;
        color: $accent-color;
        font-size: 30px;
        padding: 0 2%;
        @media screen and (max-width: 700px) {
          padding: 0 4%;
        }
      }
    }
  }
  .sub_header.down{
    transform: translateY($header-height);
  }

  h3.title {
    background-color: $sub-background-color;
    color: $accent-color;
    font-size: $font-m;
    font-weight: bold;
    padding: 1em;
  }

  div.manage_button {
    padding: 2em 0;
    z-index: 100;
  }

  div.manage_button::after {
    content: "";
    clear: both;
    display: block;
  }
  div.fixed_space{
    height: 150px;
  }
  div.manage_button.wizard{
    position: fixed;
    bottom: 0;
    background-color: rgba(0,0,0,0.4);
    max-width: 760px;
    width: 100%;
    padding: 1em;
  }
  div.manage_button.wizard .btn_back{
    position: relative;
    width: 30%;
  }
  div.manage_button.wizard .btn_back::before{
    position: absolute;
    content: "\f3d2";
    font-family: ionicons;
    left: 15px;
    font-size: 20px;
  }
  div.manage_button.wizard .btn_next{
    position: relative;
    width: 65%;
    font-weight: bold;
    float: right;
  }
  div.manage_button.wizard .btn_next::after{
    position: absolute;
    content: "\f3d1";
    font-family: ionicons;
    right: 15px;
    font-size: 20px;
  }

  div.manage_button.wizard .btn_finish{
    width: 65%;
    font-weight: bold;
    float: right;
  }

  div.manage_button.wizard .btn_skip {
    display: inline-block;
    color: #fff;
    margin-top: 1em;
    text-decoration: underline;
    font-weight: 100;
    padding: 0;
  }

  .description {
    padding: 2em 0 1em 0;
  }
  &.is-wizard{
    padding-top: $header-height;
  }
}

/* オススメマーク */
.mark_recommend {
  position: relative;
  overflow: hidden;

  i.mark {
    background-color: #005f9b;
    color: #fff !important;
    display: inline-block !important;
    font-size: 8px !important;
    line-height: 160%;
    padding: 1px 20px 0 20px;
    position: absolute;
    top: 9px;
    left: -20px;
    transform: rotate(-45deg);
  }
}

/* Vimeoのプレーヤー */
.vimeo_player_wrapper {
  position: relative;
  width: 100%;
  height: 0;
  padding: 0 !important;
  padding-bottom: 75% !important;
}
iframe.vimeo_player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
