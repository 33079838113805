@charset "UTF-8";
.btn_base {
  background-color: #ff6c52;
  border-radius: 7px;
  color: #fff;
  display: inline-block;
  text-align: center; }
  .btn_base.btn_disabled {
    background-color: #ccc;
    color: #fff;
    cursor: not-allowed; }

.manage_content main .manage_form_wrapper input[type="checkbox"]:not(.switch_content) {
  display: none;
  vertical-align: middle; }
  .manage_content main .manage_form_wrapper input[type="checkbox"]:not(.switch_content) + label {
    margin-left: 0em;
    vertical-align: bottom; }
  .manage_content main .manage_form_wrapper input[type="checkbox"]:not(.switch_content) + :before {
    margin-right: 0.7em;
    padding-left: 0.8em;
    content: "\f372";
    font-family: "Ionicons";
    font-size: 20px; }
  .manage_content main .manage_form_wrapper input[type="checkbox"]:checked:not(.switch_content) + :before {
    content: "\f373"; }

.manage_content .sup, .thanks_letter_content .sup {
  background-color: #f7f7f7;
  font-size: 11px;
  margin-top: 1em;
  margin-bottom: 2em;
  padding: 1em; }
  .manage_content .sup li, .thanks_letter_content .sup li {
    margin-left: 1em; }
  .manage_content .sup a, .thanks_letter_content .sup a {
    text-decoration: underline;
    color: #f00;
    cursor: pointer; }
  .manage_content .sup ol, .thanks_letter_content .sup ol {
    margin: 16px 0; }
    .manage_content .sup ol li, .thanks_letter_content .sup ol li {
      list-style-type: none;
      margin-left: 0; }
  .manage_content .sup .instruction, .thanks_letter_content .sup .instruction {
    margin-bottom: 16px; }

.manage_content .checkbox_wrapper, .thanks_letter_content .checkbox_wrapper {
  margin-bottom: 1em; }
  .manage_content .checkbox_wrapper input[type=checkbox] + :before, .thanks_letter_content .checkbox_wrapper input[type=checkbox] + :before {
    padding-left: 0 !important; }

.manage_content .button_wrapper, .thanks_letter_content .button_wrapper {
  position: sticky;
  bottom: 0;
  margin-top: 32px; }

.manage_content .fix_content, .thanks_letter_content .fix_content {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 5px 2px;
  max-width: 760px;
  width: 100%; }
  .manage_content .fix_content .manage_button, .thanks_letter_content .fix_content .manage_button {
    padding: 0 !important; }

.manage_content .fix_content.active, .thanks_letter_content .fix_content.active {
  position: fixed;
  bottom: 0; }

.manage_content .btn_message, .thanks_letter_content .btn_message {
  display: inline-block;
  margin-top: .5em;
  padding: .5em 1em;
  border: none;
  border-radius: 7px;
  background-color: rgba(51, 51, 51, 0.7);
  color: #fff;
  font-size: 11px;
  cursor: pointer; }

.manage_content .message_pattern, .thanks_letter_content .message_pattern {
  width: 100%; }
  .manage_content .message_pattern a, .thanks_letter_content .message_pattern a {
    display: inline-block;
    color: #ff6c52;
    text-decoration: underline;
    margin: 6px 0 4px 6px; }

.manage_content .photo_size, .thanks_letter_content .photo_size {
  padding-bottom: 1em; }

.manage_content .photo_size a, .thanks_letter_content .photo_size a {
  font-size: 11px;
  color: #999; }

.manage_content .inline_radio_label, .thanks_letter_content .inline_radio_label {
  vertical-align: middle; }

.manage_content .image__default-btn, .manage_content .select-recommend-btn, .thanks_letter_content .image__default-btn, .thanks_letter_content .select-recommend-btn {
  display: block;
  padding: .5em 1em;
  border: none;
  border-radius: 7px;
  background-color: rgba(51, 51, 51, 0.7);
  color: #fff;
  font-size: 11px;
  cursor: pointer; }

.manage_content .image__default-btn, .thanks_letter_content .image__default-btn {
  position: absolute;
  top: .5em;
  right: .5em; }

.manage_content .select-recommend-btn, .thanks_letter_content .select-recommend-btn {
  position: absolute;
  bottom: 1em;
  right: 1em;
  font-size: 13px;
  width: 80px;
  height: 45px;
  line-height: 30px; }

.manage_content .select-photo-modal, .thanks_letter_content .select-photo-modal {
  min-height: auto !important; }
  .manage_content .select-photo-modal .select-photo-btn-container, .thanks_letter_content .select-photo-modal .select-photo-btn-container {
    display: flex;
    flex-direction: column;
    padding: 0 40px;
    overflow: hidden; }
  .manage_content .select-photo-modal .select-photo-btn, .thanks_letter_content .select-photo-modal .select-photo-btn {
    padding: 0 1rem; }
    .manage_content .select-photo-modal .select-photo-btn + .select-photo-btn, .thanks_letter_content .select-photo-modal .select-photo-btn + .select-photo-btn {
      margin-top: 24px; }
    .manage_content .select-photo-modal .select-photo-btn.--upload, .thanks_letter_content .select-photo-modal .select-photo-btn.--upload {
      color: #fff;
      background: #ff6c52; }
    .manage_content .select-photo-modal .select-photo-btn.--recommend, .thanks_letter_content .select-photo-modal .select-photo-btn.--recommend {
      color: #ff6c52;
      border: 2px solid #ff6c52; }
  .manage_content .select-photo-modal .remodal-footer, .thanks_letter_content .select-photo-modal .remodal-footer {
    height: 40px; }

.manage_content .multi_place button, .thanks_letter_content .multi_place button {
  width: 100%;
  padding: 12px;
  cursor: pointer;
  margin: 0 0 20px; }
  .manage_content .multi_place button.add_place, .thanks_letter_content .multi_place button.add_place {
    border: #CCCCCC 1px dashed; }
  .manage_content .multi_place button span, .thanks_letter_content .multi_place button span {
    display: block;
    font-size: 11px; }

.manage_content .multi_place_hidden, .thanks_letter_content .multi_place_hidden {
  display: none; }

.manage_content .remodal-overlay, .thanks_letter_content .remodal-overlay {
  background-color: white; }

.manage_content .remodal-wrapper, .thanks_letter_content .remodal-wrapper {
  padding: 0; }

.manage_content .recommend-photo-modal .remodal-header a.btn_close, .thanks_letter_content .recommend-photo-modal .remodal-header a.btn_close {
  position: absolute;
  top: 0px;
  left: 0px;
  font-size: 40px;
  height: 50px;
  width: 50px;
  line-height: 50px; }

.manage_content .recommend-photo-modal .remodal-header .title, .thanks_letter_content .recommend-photo-modal .remodal-header .title {
  font-size: 15px;
  color: #002556;
  height: 50px;
  line-height: 50px;
  text-align: center; }

.manage_content .recommend-photo-modal .search_result, .thanks_letter_content .recommend-photo-modal .search_result {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 2rem; }
  .manage_content .recommend-photo-modal .search_result .search-result__item, .thanks_letter_content .recommend-photo-modal .search_result .search-result__item {
    position: relative;
    width: 50%;
    padding: 0.25rem; }
    @media screen and (max-width: 768px) {
      .manage_content .recommend-photo-modal .search_result .search-result__item, .thanks_letter_content .recommend-photo-modal .search_result .search-result__item {
        width: 100%; } }
  @media screen and (max-width: 768px) {
    .manage_content .recommend-photo-modal .search_result.--v3[data-position="1"] .search-result__item, .thanks_letter_content .recommend-photo-modal .search_result.--v3[data-position="1"] .search-result__item {
      width: 50%; } }

.manage_content .credit-description-modal h3, .thanks_letter_content .credit-description-modal h3 {
  font-weight: bold;
  font-size: 16px;
  line-height: 23px;
  color: #002556; }

.manage_content .credit-description-modal .about, .thanks_letter_content .credit-description-modal .about {
  padding: 0 20px 20px; }

.manage_content .credit-description-modal p, .thanks_letter_content .credit-description-modal p {
  text-align: left; }

.manage_content .credit-description-modal img, .thanks_letter_content .credit-description-modal img {
  max-width: 250px;
  display: block;
  margin: 20px auto; }

.manage_content .credit-description-modal .merits, .thanks_letter_content .credit-description-modal .merits {
  background: #F7F7F7;
  padding: 24px 16px; }
  .manage_content .credit-description-modal .merits .copy, .thanks_letter_content .credit-description-modal .merits .copy {
    font-weight: bold;
    font-size: 12px;
    line-height: 24px;
    color: #FF6C52; }
  .manage_content .credit-description-modal .merits h3, .thanks_letter_content .credit-description-modal .merits h3 {
    color: #4C4C4C;
    font-weight: bold;
    padding-bottom: 8px; }
  .manage_content .credit-description-modal .merits .merit, .thanks_letter_content .credit-description-modal .merits .merit {
    background: #fff;
    padding: 0 16px 16px 16px;
    margin-top: 46px;
    position: relative; }
    .manage_content .credit-description-modal .merits .merit .num, .thanks_letter_content .credit-description-modal .merits .merit .num {
      background: #fff;
      width: 60px;
      height: 60px;
      position: absolute;
      border-radius: 50%;
      left: 0;
      right: 0;
      margin: -30px auto;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 32px;
      line-height: 24px;
      color: #FF6C52; }
    .manage_content .credit-description-modal .merits .merit h4, .thanks_letter_content .credit-description-modal .merits .merit h4 {
      padding: 32px 0 0;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px; }
    .manage_content .credit-description-modal .merits .merit img, .thanks_letter_content .credit-description-modal .merits .merit img {
      height: 60px;
      margin: 16px auto; }

.manage_content .credit-description-modal .security, .thanks_letter_content .credit-description-modal .security {
  padding: 0 32px 24px;
  position: relative; }
  .manage_content .credit-description-modal .security h3, .thanks_letter_content .credit-description-modal .security h3 {
    padding-top: 42px;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #4C4C4C; }
  .manage_content .credit-description-modal .security p, .thanks_letter_content .credit-description-modal .security p {
    display: inline-block;
    margin: 16px auto 8px; }
  .manage_content .credit-description-modal .security p.attention, .thanks_letter_content .credit-description-modal .security p.attention {
    font-size: 12px;
    line-height: 20px; }
  .manage_content .credit-description-modal .security:before, .thanks_letter_content .credit-description-modal .security:before {
    content: '';
    width: 48px;
    height: 24px;
    background: url("/images/credit_flow.svg");
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0; }
  .manage_content .credit-description-modal .security img, .thanks_letter_content .credit-description-modal .security img {
    max-width: 160px; }

.manage_content .credit-description-modal .flow, .thanks_letter_content .credit-description-modal .flow {
  padding: 0 32px 24px;
  position: relative; }
  .manage_content .credit-description-modal .flow h3, .thanks_letter_content .credit-description-modal .flow h3 {
    border-top: 1px solid #E5E5E5;
    padding-top: 42px;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #4C4C4C; }
  .manage_content .credit-description-modal .flow p, .thanks_letter_content .credit-description-modal .flow p {
    display: inline-block;
    margin: 16px auto 8px;
    line-height: 30px;
    font-size: 14px; }
  .manage_content .credit-description-modal .flow p.attention, .thanks_letter_content .credit-description-modal .flow p.attention {
    font-size: 12px;
    line-height: 20px; }

.manage_content .credit-description-modal .credit_list, .thanks_letter_content .credit-description-modal .credit_list {
  padding: 0 32px 24px; }
  .manage_content .credit-description-modal .credit_list h3, .thanks_letter_content .credit-description-modal .credit_list h3 {
    border-top: 1px solid #E5E5E5;
    padding: 43px 0 13px;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: #4C4C4C; }
  .manage_content .credit-description-modal .credit_list button, .thanks_letter_content .credit-description-modal .credit_list button {
    margin: 24px auto;
    border: 2px solid #FF6C52;
    border-radius: 5px;
    padding: 12px;
    max-width: 250px;
    width: 100%;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: #FF6C52; }

.manage_content .message-sample-modal .remodal-body, .thanks_letter_content .message-sample-modal .remodal-body {
  padding: 0 0 40px; }
  .manage_content .message-sample-modal .remodal-body .sample_message h2, .thanks_letter_content .message-sample-modal .remodal-body .sample_message h2 {
    margin: 20px auto 0;
    font-size: 16px; }
  .manage_content .message-sample-modal .remodal-body .sample_message p, .thanks_letter_content .message-sample-modal .remodal-body .sample_message p {
    margin: 20px auto;
    display: inline-block;
    text-align: left;
    white-space: pre; }
  .manage_content .message-sample-modal .remodal-body .sample_message a, .thanks_letter_content .message-sample-modal .remodal-body .sample_message a {
    max-width: 130px;
    background: #fff;
    border: 2px solid #ff6c52;
    color: #ff6c52;
    border-radius: 20px;
    line-height: unset;
    height: unset;
    padding: 10px 0; }
  .manage_content .message-sample-modal .remodal-body .sample_message hr, .thanks_letter_content .message-sample-modal .remodal-body .sample_message hr {
    border-top: 1px solid #ccc;
    width: 100%;
    margin: 40px auto; }

.manage_content .bottom-btn-container, .thanks_letter_content .bottom-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem; }
  .manage_content .bottom-btn-container .button, .thanks_letter_content .bottom-btn-container .button {
    width: 100%;
    max-width: 480px; }

.manage_content .image-tab-content, .thanks_letter_content .image-tab-content {
  padding-bottom: 1rem; }

.manage_content .image-container, .thanks_letter_content .image-container {
  display: flex;
  flex-wrap: wrap;
  margin-left: -0.25rem;
  margin-right: -0.25rem; }

.manage_content .image-wrapper, .thanks_letter_content .image-wrapper {
  width: 50%;
  padding: 0.25rem; }
  .manage_content .image-wrapper.--top, .thanks_letter_content .image-wrapper.--top {
    width: 100%;
    max-width: 480px; }
  .manage_content .image-wrapper .image-surface, .thanks_letter_content .image-wrapper .image-surface {
    position: relative;
    background: #fff;
    border: 1px solid #e5e5e5; }
    .manage_content .image-wrapper .image-surface:after, .thanks_letter_content .image-wrapper .image-surface:after {
      content: "";
      display: block;
      padding-bottom: 100%; }
    .manage_content .image-wrapper .image-surface.add-area, .thanks_letter_content .image-wrapper .image-surface.add-area {
      border: 2px dashed #e5e5e5; }
  .manage_content .image-wrapper .image, .thanks_letter_content .image-wrapper .image {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #000;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain; }
  .manage_content .image-wrapper .image-surface.add-area .image, .thanks_letter_content .image-wrapper .image-surface.add-area .image {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    font-size: 11px; }
  .manage_content .image-wrapper.--top .image, .thanks_letter_content .image-wrapper.--top .image {
    background-size: contain; }

.manage_content .image__change-btn, .thanks_letter_content .image__change-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  height: 40px;
  line-height: 40px;
  padding: 0 2rem;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 100rem;
  background: rgba(0, 0, 0, 0.25);
  outline: none;
  white-space: nowrap; }

.manage_content .image__remove-icon, .thanks_letter_content .image__remove-icon {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  height: 32px;
  width: 32px;
  line-height: 30px;
  font-size: 22px;
  background: #fff;
  text-align: center;
  border-radius: 50%; }

.manage_content .image__add-icon, .thanks_letter_content .image__add-icon {
  width: 40px; }

.manage_content .video-player-wrapper, .thanks_letter_content .video-player-wrapper {
  position: relative;
  width: 100%;
  max-width: 480px;
  margin-bottom: 1rem; }
  .manage_content .video-player-wrapper:after, .thanks_letter_content .video-player-wrapper:after {
    content: '';
    display: block;
    padding-bottom: 100%; }
  .manage_content .video-player-wrapper iframe, .thanks_letter_content .video-player-wrapper iframe {
    position: absolute;
    width: 100%;
    height: 100%; }

.manage_content .time-content, .thanks_letter_content .time-content {
  margin-bottom: 0.5em;
  display: block; }
  .manage_content .time-content label, .thanks_letter_content .time-content label {
    margin-right: 6px;
    display: inline-block;
    padding: 0.5em 0;
    min-height: 36px;
    vertical-align: bottom; }
  .manage_content .time-content .time-reset, .thanks_letter_content .time-content .time-reset {
    font-size: 16px;
    margin: 0 0 0 -34px;
    position: relative;
    height: 33px;
    width: 30px;
    cursor: pointer; }
  .manage_content .time-content .time-reset:hover, .thanks_letter_content .time-content .time-reset:hover {
    color: #ff6c52; }

.manage_content #crop_box, .thanks_letter_content #crop_box {
  display: flex;
  flex-direction: column;
  width: 92%;
  max-width: 470px;
  margin: auto;
  padding: 1% 0; }
  .manage_content #crop_box .crop_img, .thanks_letter_content #crop_box .crop_img {
    width: 100%;
    height: auto; }
  .manage_content #crop_box .crop-btn-container, .thanks_letter_content #crop_box .crop-btn-container {
    display: flex;
    margin-top: 0.5rem; }
    .manage_content #crop_box .crop-btn-container .btn, .thanks_letter_content #crop_box .crop-btn-container .btn {
      width: 100%; }
      .manage_content #crop_box .crop-btn-container .btn + .btn, .thanks_letter_content #crop_box .crop-btn-container .btn + .btn {
        margin-left: 0.5rem; }
  .manage_content #crop_box .cropper-bg, .thanks_letter_content #crop_box .cropper-bg {
    background: #000; }

.manage_content #system_fee_rate, .thanks_letter_content #system_fee_rate {
  display: none; }

.manage_content .ticket_input, .thanks_letter_content .ticket_input {
  max-width: 480px; }
  .manage_content .ticket_input input[name*=title], .thanks_letter_content .ticket_input input[name*=title] {
    margin-right: 5%;
    width: 40%; }
  .manage_content .ticket_input input[name*=price], .thanks_letter_content .ticket_input input[name*=price] {
    width: 30%; }
  .manage_content .ticket_input .delete-ticket-action, .thanks_letter_content .ticket_input .delete-ticket-action {
    cursor: pointer;
    display: inline-block;
    font-size: 17px;
    max-width: 45px;
    margin: 0px 0px 0px 10px;
    padding: 5px 0;
    text-align: center;
    width: 10%; }
  .manage_content .ticket_input .draw_request_fee, .thanks_letter_content .ticket_input .draw_request_fee {
    color: #a9a9a9;
    font-size: 11px;
    text-align: right;
    margin-right: 22%; }

.manage_content .add-ticket-action, .thanks_letter_content .add-ticket-action {
  border: dashed 1px #ccc;
  cursor: pointer;
  padding: 5px 0;
  text-align: center;
  margin: 1em 0; }
  .manage_content .add-ticket-action i, .thanks_letter_content .add-ticket-action i {
    font-size: 15px; }

.manage_content .std-nodisplay, .thanks_letter_content .std-nodisplay {
  display: none !important; }

.manage_content main {
  padding-top: 50px; }
  .manage_content main .manage_form_wrapper {
    padding-top: 2em;
    /* ご祝儀・会費のお支払い画面に表示する説明 */
    /* 事前クレカ払いの注意 */ }
    .manage_content main .manage_form_wrapper #update_form {
      margin-bottom: 20px;
      padding-bottom: 20px; }
    .manage_content main .manage_form_wrapper h3 {
      background-color: #fff;
      color: #fff;
      margin-bottom: 1em;
      margin-top: 2em;
      padding: 0.5em 1em; }
      .manage_content main .manage_form_wrapper h3 .fee_checkbox {
        float: right;
        font-weight: normal; }
      .manage_content main .manage_form_wrapper h3 .option_arrow {
        float: right;
        font-size: 20px;
        font-weight: bold;
        cursor: pointer; }
        .manage_content main .manage_form_wrapper h3 .option_arrow label {
          cursor: pointer; }
    .manage_content main .manage_form_wrapper h3.entry_title label {
      float: right;
      font-weight: normal;
      vertical-align: sub !important; }
    .manage_content main .manage_form_wrapper h3.option_title {
      cursor: pointer; }
    .manage_content main .manage_form_wrapper .hidden_entry_form_content {
      display: none; }
    .manage_content main .manage_form_wrapper section {
      margin-bottom: 1em; }
      .manage_content main .manage_form_wrapper section .no_entry_form_text {
        color: #FF7568;
        font-size: 11px; }
      .manage_content main .manage_form_wrapper section .credit_text {
        background: #fff;
        border: 2px solid #F7E4E3;
        border-radius: 4px; }
        .manage_content main .manage_form_wrapper section .credit_text img.credit_about_image {
          display: block;
          margin: 10px auto;
          max-width: 253px; }
        .manage_content main .manage_form_wrapper section .credit_text p {
          text-align: center; }
        .manage_content main .manage_form_wrapper section .credit_text a {
          font-weight: bold;
          color: #FF6C52;
          font-size: 13px;
          font-weight: 400; }
      .manage_content main .manage_form_wrapper section .entry_accept_yn,
      .manage_content main .manage_form_wrapper section .password_message {
        padding-top: 1em; }
      .manage_content main .manage_form_wrapper section .entry_accept_message,
      .manage_content main .manage_form_wrapper section .password_yn {
        padding-top: 0.5em; }
      .manage_content main .manage_form_wrapper section .privacy_radio {
        display: inline-block;
        margin-right: 1em; }
      .manage_content main .manage_form_wrapper section .url_cant_edit {
        color: #ff6c52;
        font-size: 11px; }
    .manage_content main .manage_form_wrapper textarea.message {
      font-size: 11px;
      height: 290px;
      letter-spacing: 0;
      margin-top: 8px;
      padding: 0.5em;
      width: 28em;
      max-width: 100%; }
    .manage_content main .manage_form_wrapper .btn_preview {
      background-color: #ff9e97; }
    .manage_content main .manage_form_wrapper .disabled_text {
      color: #ccc; }
    .manage_content main .manage_form_wrapper .place_map_checkbox label {
      display: inline-block;
      margin-top: 8px;
      margin-left: -8px !important; }
    .manage_content main .manage_form_wrapper .place_map_checkbox .gmap {
      margin-top: 5px; }
      .manage_content main .manage_form_wrapper .place_map_checkbox .gmap iframe {
        height: 250px;
        max-width: 500px;
        width: 100%; }
    .manage_content main .manage_form_wrapper .data_fee_remarks_pattern .select {
      max-width: 480px;
      width: 100%; }
      .manage_content main .manage_form_wrapper .data_fee_remarks_pattern .select select {
        width: 100%; }
    .manage_content main .manage_form_wrapper .pay_message_sample {
      display: none; }
    .manage_content main .manage_form_wrapper .pay_attention {
      font-size: 11px;
      margin: 1em 0;
      padding: 1em; }
      .manage_content main .manage_form_wrapper .pay_attention .title {
        font-weight: 700;
        margin-bottom: 10px;
        display: block; }
      .manage_content main .manage_form_wrapper .pay_attention ul {
        margin: 1em; }
        .manage_content main .manage_form_wrapper .pay_attention ul span {
          color: #ff0000; }
      .manage_content main .manage_form_wrapper .pay_attention img {
        width: 270px; }

.thanks_letter_content main {
  padding-top: 50px; }

.thanks_letter_content .content-main {
  padding: 2rem 1rem; }
