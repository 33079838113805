@charset "utf-8";

@import "common/variables";
@import "common/mixins";
@import "common/extends";

// 招待状とお礼状の内容編集画面
.manage_content, .thanks_letter_content {
  .sup {
    background-color: $background-color;
    font-size: $font-s;
    margin-top: 1em;
    margin-bottom: 2em;
    padding: 1em;

    li {
      margin-left: 1em
    }

    a {
      text-decoration: underline;
      color: #f00;
      cursor: pointer;
    }

    ol {
      margin: 16px 0;
      li {
        list-style-type: none;
        margin-left: 0;
      }
    }
    .instruction {
      margin-bottom: 16px;
    }
  }

  .checkbox_wrapper {
    margin-bottom: 1em;

    input[type=checkbox]+:before{
      padding-left: 0 !important;
    }
  }
  .button_wrapper{
    position: sticky; // 下付きにする
    bottom: 0;
    margin-top: 32px;
  }
  .fix_content{
    background-color: rgba(255,255,255,0.8);
    padding: 5px 2px;
    max-width: 760px;
    width: 100%;
    .manage_button {
      padding: 0!important;
    }
  }
  .fix_content.active{
    position: fixed;
    bottom: 0;
  }

  .btn_message{
    display: inline-block;
    margin-top: .5em;
    padding: .5em 1em;
    border: none;
    border-radius: 7px;
    background-color: rgba(51, 51, 51, 0.7);
    color: #fff;
    font-size: $font-s;
    cursor: pointer;
  }
  .message_pattern{
    width: 100%;
    a{
      display: inline-block;
      color: $primary-color;
      text-decoration: underline;
      margin: 6px 0 4px 6px;
    }
  }
  .photo_size {
    padding-bottom: 1em;
  }
  .photo_size a {
    font-size: 11px;
    color: #999;
  }
  .inline_radio_label {
    vertical-align: middle;
  }

  .image__default-btn, .select-recommend-btn {
    display: block;
    padding: .5em 1em;
    border: none;
    border-radius: 7px;
    background-color: rgba(51, 51, 51, 0.7);
    color: #fff;
    font-size: $font-s;
    cursor: pointer;
  }
  .image__default-btn {
    position: absolute;
    top: .5em;
    right: .5em;
  }
  .select-recommend-btn {
    position: absolute;
    bottom: 1em;
    right: 1em;
    font-size: $font-m;
    width: 80px;
    height: 45px;
    line-height: 30px;
  }

  .select-photo-modal {
    min-height: auto !important;

    .select-photo-btn-container {
      display: flex;
      flex-direction: column;
      padding: 0 40px;
      overflow: hidden;
    }
    .select-photo-btn {
      padding: 0 1rem;
      + .select-photo-btn {
        margin-top: 24px;
      }
      &.--upload {
        color: #fff;
        background: $primary-color;
      }
      &.--recommend {
        color: $primary-color;
        border: 2px solid $primary-color;
      }
    }
    .remodal-footer {
      height: 40px;
    }
  }
  .multi_place button{
    width: 100%;
    padding: 12px;
    cursor: pointer;
    margin: 0 0 20px;
    &.add_place{
      border: #CCCCCC 1px dashed;
    }
    span{
      display: block;
      font-size: $font-s;
    }
  }
  .multi_place_hidden{
    display: none;
  }

  // おすすめ写真リモーダル
  .remodal-overlay {
    background-color: white;
  }
  .remodal-wrapper {
    padding:0;
  }
  .recommend-photo-modal {
    .remodal-header {
      a.btn_close {
        position: absolute;
        top: 0px;
        left: 0px;
        font-size: 40px;
        height: $header-height;
        width: $header-height;
        line-height: $header-height;
      }
      .title {
        font-size: $font-l;
        color: $accent-color;
        height: $header-height;
        line-height: $header-height;
        text-align: center;
      }
    }
    .search_result {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: 2rem;

      .search-result__item {
        position: relative;
        width: 50%;
        padding: 0.25rem;
        @media screen and (max-width: 768px) {
          width: 100%;
        }
      }
      // v3デザイン
      &.--v3 {
        // 写真1はモバイル幅でも50%（2列）表示
        &[data-position="1"] {
          .search-result__item {
            @media screen and (max-width: 768px) {
              width: 50%;
            }
          }
        }
      }
    }
  }
  .credit-description-modal{
    h3{
      font-weight: bold;
      font-size: 16px;
      line-height: 23px;
      color: #002556;
    }
    .about{
      padding: 0 20px 20px;
    }
    p{
      text-align: left;
    }
    img{
      max-width: 250px;
      display: block;
      margin: 20px auto;
    }
    .merits{
      background: #F7F7F7;
      padding: 24px 16px;
      .copy{
        font-weight: bold;
        font-size: 12px;
        line-height: 24px;
        color: #FF6C52;
      }
      h3{
        color: #4C4C4C;
        font-weight: bold;
        padding-bottom: 8px;
      }
      .merit{
        background: #fff;
        padding: 0 16px 16px 16px;
        margin-top: 46px;
        position: relative;
        .num{
          background: #fff;
          width: 60px;
          height: 60px;
          position: absolute;
          border-radius: 50%;
          left: 0;
          right: 0;
          margin: -30px auto;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 32px;
          line-height: 24px;
          color: #FF6C52;
        }
        h4{
          padding: 32px 0 0;
          font-weight: bold;
          font-size: 16px;
          line-height: 24px;
        }
        img{
          height: 60px;
          margin: 16px auto;
        }
      }
    }
    .security{
      padding: 0 32px 24px;
      position: relative;
      h3{
        padding-top: 42px;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #4C4C4C;
      }
      p{
        display: inline-block;
        margin: 16px auto 8px;
      }
      p.attention{
        font-size: 12px;
        line-height: 20px;
      }
      &:before{
        content: '';
        width: 48px;
        height: 24px;
        background: url("/images/credit_flow.svg");
        position: absolute;
        margin: 0 auto;
        left: 0;
        right: 0;
      }
      img {
        max-width: 160px;
      }
    }
    .flow{
      padding: 0 32px 24px;
      position: relative;
      h3{
        border-top: 1px solid #E5E5E5;
        padding-top: 42px;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #4C4C4C;
      }
      p{
        display: inline-block;
        margin: 16px auto 8px;
        line-height: 30px;
        font-size: 14px;
      }
      p.attention{
        font-size: 12px;
        line-height: 20px;
      }
    }
    .credit_list{
      padding: 0 32px 24px;
      h3{
        border-top: 1px solid #E5E5E5;
        padding: 43px 0 13px;
        font-weight: bold;
        font-size: 16px;
        line-height: 24px;
        color: #4C4C4C;
      }
      button{
        margin: 24px auto;
        border: 2px solid #FF6C52;
        border-radius: 5px;
        padding: 12px;
        max-width: 250px;
        width: 100%;
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        color: #FF6C52;
      }
    }
  }

  .message-sample-modal{
    .remodal-body{
      padding: 0 0 40px;
      .sample_message{
        h2{
          margin: 20px auto 0;
          font-size: 16px;
        }
        p{
          margin: 20px auto;
          display: inline-block;
          text-align: left;
          white-space: pre;
        }
        a{
          max-width: 130px;
          background: #fff;
          border: 2px solid $primary-color;
          color: $primary-color;
          border-radius: 20px;
          line-height: unset;
          height: unset;
          padding: 10px 0;
        }
        hr{
          border-top: 1px solid #ccc;
          width: 100%;
          margin: 40px auto;
        }
      }
    }
  }
  .bottom-btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
    .button {
      width: 100%;
      max-width: 480px;
    }
  }

  .image-tab-content {
    padding-bottom: 1rem;
  }
  .image-container {
    display: flex;
    flex-wrap: wrap;
    margin-left: -0.25rem;
    margin-right: -0.25rem;
  }
  .image-wrapper {
    width: 50%;
    padding: 0.25rem;
    &.--top {
      width: 100%;
      max-width: 480px;
    }
    .image-surface {
      position: relative;
      background: #fff;
      border: 1px solid $border-color;
      &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
      }
      &.add-area {
        border: 2px dashed $border-color;
      }
    }
    .image {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #000;
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
    .image-surface.add-area .image {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      font-size: $font-s;
    }
    &.--top .image {
      background-size: contain;
    }
  }
  .image__change-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    height: 40px;
    line-height: 40px;
    padding: 0 2rem;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 100rem;
    background: rgba(0,0,0,0.25);
    outline: none;
    white-space: nowrap;
  }
  .image__remove-icon {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    height: 32px;
    width: 32px;
    line-height: 30px;
    font-size: 22px;
    background: #fff;
    text-align: center;
    border-radius: 50%;
  }
  .image__add-icon {
    width: 40px;
  }
  .video-player-wrapper {
    position: relative;
    width: 100%;
    max-width: 480px;
    margin-bottom: 1rem;
    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }
    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
  .time-content {
    margin-bottom: 0.5em;
    display: block;
    label {
      margin-right: 6px;
      display: inline-block;
      padding: 0.5em 0;
      min-height: 36px;
      vertical-align: bottom;
    }
    .time-reset{
      font-size: 16px;
      margin: 0 0 0 -34px;
      position: relative;
      height: 33px;
      width: 30px;
      cursor: pointer;
    }
    .time-reset:hover{
      color: $primary-color;
    }

  }
  #crop_box {
    display: flex;
    flex-direction: column;
    width: 92%;
    max-width: 470px;
    margin: auto;
    padding: 1% 0;
    .crop_img {
      width: 100%;
      height: auto;
    }
    .crop-btn-container {
      display: flex;
      margin-top: 0.5rem;
      .btn {
        width: 100%;
        + .btn {
          margin-left: 0.5rem;
        }
      }
    }
    .cropper-bg {
      background: #000;
    }
  }

  // 決済会費情報
  #system_fee_rate {
    display: none;
  }
  .ticket_input {
    max-width: 480px;
    input[name*=title] {
      margin-right: 5%;
      width: 40%;
    }
    input[name*=price] {
      width: 30%;
    }

    // 会費の入力欄削除ボタン
    .delete-ticket-action {
      cursor: pointer;
      display:inline-block;
      font-size: $font-ll;
      max-width:45px;
      margin:0px 0px 0px 10px;
      padding: 5px 0;
      text-align: center;
      width:10%;
    }

    .draw_request_fee {
      color: #a9a9a9;
      font-size: 11px;
      text-align: right;
      margin-right: 22%;
    }
  }
  // 会費の入力欄追加ボタン
  .add-ticket-action {
    border: dashed 1px #ccc;
    cursor: pointer;
    padding: 5px 0;
    text-align: center;
    margin: 1em 0;
    i {
      font-size: $font-l;
    }
  }

  // 標準招待状で非表示にする項目
  .std-nodisplay {
    display: none!important;
  }
}

// 招待状の内容編集画面
.manage_content {
  main {
    padding-top:$subheader-height;
    .manage_form_wrapper {
      padding-top: 2em;

      #update_form {
        margin-bottom: 20px;
        padding-bottom: 20px;
      }

      h3 {
        background-color: $title-background-color;
        color: #fff;
        margin-bottom: 1em;
        margin-top: 2em;
        padding: 0.5em 1em;

        .fee_checkbox {
          float: right;
          font-weight: normal;
        }
        .option_arrow {
          float: right;
          font-size: 20px;
          font-weight: bold;
          cursor: pointer;
          label{
            cursor: pointer;
          }
        }
      }
      h3.entry_title{
        label{
          float: right;
          font-weight: normal;
          vertical-align: sub !important;
        }
      }
      h3.option_title{
        cursor: pointer;
      }
      .hidden_entry_form_content {
        display: none;
      }

      section {
        margin-bottom: 1em;

        .no_entry_form_text{
          color: #FF7568;
          font-size: $font-s;
        }
        .credit_text{
          background: #fff;
          border: 2px solid #F7E4E3;
          border-radius: 4px;
          img.credit_about_image{
            display: block;
            margin: 10px auto;
            max-width: 253px;
          }
          p{
            text-align: center;
          }
          a{
            font-weight: bold;
            color: #FF6C52;
            font-size: 13px;
            font-weight: 400;
          }
        }

        .entry_accept_yn,
        .password_message {
          padding-top: 1em;
        }
        .entry_accept_message,
        .password_yn {
          padding-top: 0.5em;
        }

        .privacy_radio{
          display: inline-block;
          margin-right: 1em;
        }

        .url_cant_edit {
          color: $primary-color;
          font-size: $font-s;
        }
      }

      textarea.message {
        font-size: 11px;
        height: 290px;
        letter-spacing: 0;
        margin-top: 8px;
        padding: 0.5em;
        width: 28em;
        max-width: 100%;
      }

      .btn_preview {
        background-color: $sub-color;
      }

      .disabled_text {
        color: #ccc;
      }

      input[type="checkbox"]:not(.switch_content) {
        @extend %common_checkbox;
      }

      .place_map_checkbox {
        label {
          display: inline-block;
          margin-top: 8px;
          margin-left: -8px!important;
        }

        .gmap {
          margin-top: 5px;

          iframe {
            height: 250px;
            max-width: 500px;
            width: 100%;
          }
        }
      }

      /* ご祝儀・会費のお支払い画面に表示する説明 */
      .data_fee_remarks_pattern {
        .select {
          max-width: 480px;
          width: 100%;

          select {
            width: 100%;
          }
        }
      }
      .pay_message_sample {
        display: none;
      }

      /* 事前クレカ払いの注意 */
      .pay_attention{
        font-size: 11px;
        margin: 1em 0;
        padding: 1em;

        .title {
          font-weight: 700;
          margin-bottom: 10px;
          display: block;
        }
        ul {
          margin: 1em;
          span{
            color: #ff0000;
          }
        }
        img{
          width: 270px;
        }
      }
    }
  }
}

// お礼状の内容編集画面
.thanks_letter_content {
  main {
    padding-top: 50px;
  }
  .content-main {
    padding: 2rem 1rem;
  }
}
