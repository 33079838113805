body.partner_user .input_modal {
  max-width: 480px; }
  body.partner_user .input_modal.input_modal--lg {
    max-width: 1080px; }
  body.partner_user .input_modal input {
    margin: 0; }

body.partner_user .time-fields-container {
  display: flex;
  flex-wrap: wrap; }
  body.partner_user .time-fields-container p {
    margin-bottom: 10px; }
  body.partner_user .time-fields-container .time-fields {
    flex-grow: 1;
    max-width: 300px;
    min-width: 300px;
    background-color: #fff;
    margin: 0 4px 20px;
    padding: 8px;
    border-radius: 2px; }
    body.partner_user .time-fields-container .time-fields legend {
      margin-left: 8px;
      padding: 0 4px; }
    body.partner_user .time-fields-container .time-fields .form_row {
      display: flex;
      justify-content: space-between;
      align-items: center; }

body.partner_user .input_modal--lg .time-fields {
  max-width: 33.3%; }

body.partner_user .sortable_header {
  position: relative;
  display: block; }

body.partner_user .sortable_header_icon {
  position: absolute;
  top: 2px;
  right: 0;
  font-size: 16px; }
  body.partner_user .sortable_header_icon.active {
    color: #ff9e97; }

body.partner_user .active_tr {
  background-color: rgba(117, 170, 170, 0.15); }

body.partner_user .actions {
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 16px; }
  body.partner_user .actions a {
    text-decoration: none;
    margin: 3px; }
  body.partner_user .actions button:disabled {
    color: #CCCCCC;
    background-color: #fff;
    cursor: default; }
  body.partner_user .actions .action {
    border: #CFD4D9 solid 1px;
    background-color: #FAFAFA;
    padding: 8px;
    border-radius: 4px;
    white-space: nowrap;
    color: #4C4C4C;
    cursor: pointer; }
  body.partner_user .actions .request_info {
    text-align: left;
    margin: 2px;
    font-size: 12px; }
    body.partner_user .actions .request_info i {
      color: #E62520;
      margin: 2px; }
  body.partner_user .actions .request {
    border: #E62520 solid 1px;
    background-color: #FAFAFA;
    padding: 8px;
    border-radius: 4px;
    white-space: nowrap;
    color: #E62520;
    cursor: pointer; }
  body.partner_user .actions .action_set {
    display: flex;
    flex-flow: column; }
    body.partner_user .actions .action_set .action {
      border: #CFD4D9 solid 1px;
      padding: 2px 8px;
      border-radius: 4px;
      white-space: nowrap; }
  body.partner_user .actions .table_action {
    border: #CFD4D9 solid 1px;
    background-color: #0F3986;
    padding: 8px;
    border-radius: 4px;
    white-space: nowrap;
    color: #FFFFFF;
    cursor: pointer; }

body.partner_user a.table_link div {
  background: #0F3986;
  color: #fff;
  padding: 8px 16px 8px 6px;
  border-radius: 4px;
  margin: 3px;
  position: relative; }
  body.partner_user a.table_link div i {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 6px;
    font-size: 20px;
    line-height: 54px; }

body.partner_user a.table_link.disable {
  cursor: default; }
  body.partner_user a.table_link.disable div {
    background: #CCCCCC; }

body.partner_user .menu {
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px; }
  body.partner_user .menu .partner_name {
    text-align: right;
    color: #75AAAA;
    font-weight: bold;
    font-size: 16px;
    padding: 5px; }
  body.partner_user .menu .menu_bars {
    text-align: left;
    display: flex;
    align-items: center; }
    body.partner_user .menu .menu_bars i {
      font-size: 32px;
      margin-right: 20px;
      cursor: pointer; }
    body.partner_user .menu .menu_bars button.add_btn {
      transition: all 0.3s;
      display: block;
      text-align: center;
      vertical-align: middle;
      padding: 14px 16px;
      border: 2px solid #75AAAA;
      color: #75AAAA;
      background-color: transparent;
      border-radius: 4px;
      cursor: pointer;
      font-size: 15px; }
      body.partner_user .menu .menu_bars button.add_btn:hover {
        background-color: rgba(0, 0, 0, 0.2); }
      body.partner_user .menu .menu_bars button.add_btn:focus {
        outline: 0; }

body.partner_user .dropdown-menu .dropdown-item {
  font-size: 15px;
  margin: 10px 0px;
  text-align: left; }

body.partner_user .dropdown-menu .icon {
  width: 30px; }

body.partner_user .dropdown-menu div {
  text-align: center;
  font-size: 12px;
  margin: 10px 0px; }
  body.partner_user .dropdown-menu div p {
    padding: 0 20px 10px 20px;
    width: 220px;
    white-space: pre-wrap;
    word-break: break-all; }

body.partner_user .dropdown-menu .account-info {
  border-bottom: 1px solid #CECECE;
  padding-bottom: 8px; }

body.partner_user .add_invitation {
  transition: 0.3s all;
  left: -100%;
  width: 100%;
  height: 100%;
  overflow: auto;
  position: absolute;
  background-color: #f7f7f7;
  z-index: 3; }
  body.partner_user .add_invitation a.close_btn {
    position: absolute;
    top: 10px;
    right: 10px;
    display: block;
    text-align: right;
    font-size: 36px;
    padding: 10px; }

body.partner_user .add_invitation.active {
  left: 0; }

body.partner_user .add_invitation_content {
  padding: 40px 20px;
  margin: 0 auto; }
  body.partner_user .add_invitation_content h1 {
    font-size: 22px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px; }
  body.partner_user .add_invitation_content .flex {
    display: flex;
    justify-content: center; }
  body.partner_user .add_invitation_content .flex_left {
    white-space: nowrap;
    padding-right: 20px;
    border-right: 1px solid #ddd; }
  body.partner_user .add_invitation_content .flex_right {
    padding-left: 20px;
    width: 100%;
    max-width: 640px; }
    body.partner_user .add_invitation_content .flex_right input[type=text] {
      margin: 3px 0 20px;
      max-width: none; }
    body.partner_user .add_invitation_content .flex_right select {
      border: 1px solid #e5e5e5; }
    body.partner_user .add_invitation_content .flex_right .add_btn {
      margin: 100px 0 0 0 !important;
      display: block; }

body.partner_user .use-web-table-radio + div {
  border: none !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

body.partner_user .use-web-table-radio + :before {
  content: ''; }

body.partner_user .use-web-table-radio[value="0"] + div {
  width: 90px;
  height: 100px;
  background-image: url("/images/partners/use_web_table_1_inactive.jpg"); }

body.partner_user .use-web-table-radio[value="0"]:checked + div {
  background-image: url("/images/partners/use_web_table_1_active.jpg"); }

body.partner_user .use-web-table-radio[value="1"] + div {
  width: 180px;
  height: 100px;
  background-image: url("/images/partners/use_web_table_2_inactive.jpg"); }

body.partner_user .use-web-table-radio[value="1"]:checked + div {
  background-image: url("/images/partners/use_web_table_2_active.jpg"); }

body.partner_user .new_information {
  background: #0F3986;
  color: #fff;
  text-align: center;
  padding: 20px; }
  body.partner_user .new_information a {
    border: 1px solid #fff;
    padding: 10px 20px;
    margin: 5px;
    border-radius: 5px;
    color: #fff; }

body.partner_user .show-qr-block .qr-img {
  margin-top: 0.5em; }

body.partner_user .show-qr-block .qr-notes {
  margin: 0.5em 0 1em; }

body.partner_user .show-qr-block .qr-url-block {
  display: flex;
  align-items: center; }
  body.partner_user .show-qr-block .qr-url-block .btn {
    flex-shrink: 0;
    margin-left: 0.5em;
    padding: 10px 14px; }

body.partner_user .use_web_table_wrapper {
  text-align: center;
  margin: 20px auto; }
