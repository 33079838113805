form.form_validate label.error {
  display: inline-block;
  font-size: 1rem;
  line-height: 1.5;
  margin: 0px 0 5px 0;
  color: #d9534f;
  font-weight: bold; }

form.form_validate label.form_title {
  display: block;
  font-size: 1rem;
  line-height: 1.5;
  margin: 20px 0 5px 0; }
  form.form_validate label.form_title span {
    display: inline-block;
    margin-left: 3px;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: bold;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem; }
  form.form_validate label.form_title span.required {
    background-color: #d9534f; }
  form.form_validate label.form_title span.any {
    background-color: #78909C; }

form.form_validate input[type=email], form.form_validate input[type=tel], form.form_validate input[type=text], form.form_validate input[type=date], form.form_validate input[type=time], form.form_validate select {
  border: solid #ddd 1px;
  width: 100%;
  min-height: 40px;
  display: block;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  color: #546E7A;
  border-radius: 0.25rem;
  margin: 0 0 0 0;
  background-color: #fff;
  -webkit-appearance: none; }

form.form_validate input[type=submit] {
  display: block;
  background-color: #ff7568;
  color: #fff;
  height: 50px;
  width: 100%;
  max-width: 300px;
  margin: 40px auto;
  border-radius: 0.25rem;
  font-size: 18px;
  font-weight: 700;
  cursor: pointer; }
  form.form_validate input[type=submit]:hover {
    background-color: #e4665a; }
